import React from 'react'
import { Box } from '@mui/material'
import styles from './ProgressBar.module.css'

const ProgressBar = ({ totalSteps, currentStep, setAnchorEl }) => {
  const highlightWidth =
    currentStep > 1 ? `${((currentStep - 1) / (totalSteps - 1)) * 100}%` : '0%'

  return (
    <Box className={styles.progressBar}>
      <Box
        className={styles.highlight}
        style={{ width: highlightWidth }}
        data-testid='highlight'
      />
      {Array.from({ length: totalSteps }, (_, i) => (
        <Box
          key={i}
          className={`${styles.step} ${
            i + 1 === currentStep ? styles.active : ''
          }`}
          sx={currentStep > i ? { color: 'white !important' } : {}}
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          {i + 1}
        </Box>
      ))}
    </Box>
  )
}

export default ProgressBar
