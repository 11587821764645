import {
  getBudget,
  sendMessage as sendEstimatorMessage,
  updateProject
} from '../api.js'

const DEFAULT_ORIGIN = window.location.origin
const TOKEN_KEY = '_aui_authToken'

function init (event, ref, project) {
  const { origin } = event || {}
  const message = {
    messageType: 'PS',
    messageId: 'SetToken',
    data: localStorage.getItem(TOKEN_KEY) || 'test-token'
  }

  postMessage(ref, message, origin)
}

function handlePSMessages ({
  event, ref, project, threadId, setThreadId
}) {
  if (event.data.messageType === 'PS' &&
  event.data.messageId === 'MessageFromUE') return

  const { data: eventType } = event.data

  switch (eventType) {
    case 'WaitingForToken':
      return init(event, ref, project)

    case 'Presenting': {
      const isInitialLayoutSent = localStorage.getItem(`initial-layout_${project?._id}`)
      if (!isInitialLayoutSent) {
        updateEstimator(event, project, threadId, setThreadId)
        localStorage.setItem(`initial-layout_${project?._id}`, true)
      }
      break
    }

    case 'Ready':
      return load(ref, origin, project.world_data)

    default:
      break
  }
}

async function handleUEMessages ({
  event,
  ref,
  project,
  setBudget,
  updateProjectState,
  threadId,
  setThreadId
}) {
  const { action, data } = event.data?.data
  if (event.data?.messageType === 'PS' &&
  event.data.messageId !== 'MessageFromUE') return

  if (action === 'update') {
    const newWorldState = updateParkData(project.world_data, data)
    updateProjectState(newWorldState)
    const updatedProject = { ...project, world_data: newWorldState }
    updateEstimator(event, updatedProject, threadId, setThreadId)
    const budget = await getBudget()
    setBudget(budget)
  }
}

function handleMessages (
  ref,
  project,
  setBudget,
  updateProjectState,
  threadId,
  setThreadId
) {
  const messagesHandler = (event) => {
    if (event?.data?.source?.includes('react-devtools')) return
    handlePSMessages({ event, ref, project, threadId, setThreadId })
    handleUEMessages({
      event,
      ref,
      project,
      setBudget,
      updateProjectState,
      threadId,
      setThreadId
    })
  }
  window.addEventListener('message', messagesHandler)

  return () => window.removeEventListener('message', messagesHandler)
}

// TO-DO
// 1. move updating the estimator outside of the unreal module
// 2. only update the estimator on commands after presenting and only on production iframe

async function updateEstimator (event, project, threadId, setThreadId) {
  const excludedEvents = [
    'SelectPresentation',
    'Authenticating',
    'WaitingForToken'
  ]
  const isExcluded = excludedEvents.includes(event?.data?.data)
  if (!project || isExcluded) return

  const data = {
    message: !threadId ? 'Sending Initial layout...' : 'Changes made in the layout…',
    layout: project,
    ...(threadId && { thread_id: threadId })
  }

  try {
    const { thread_id: newThreadId } = await sendEstimatorMessage(data) || {}

    if (!threadId) {
      setThreadId(newThreadId)
      await updateProject(project?._id, { thread_id: newThreadId })
    }
  } catch (error) {
    console.info('Error sending project to estimator', error)
  }
}

function updateParkData (park, updatedObject) {
  const updated = Object.keys(updatedObject).reduce((acc, key) => {
    const newKey = key.charAt(0).toLowerCase() + key.slice(1)
    acc[newKey] = updatedObject[key]
    return acc
  }, {})
  const updatedId = updated.id

  const index = park.objects.findIndex(obj => obj.id === updatedId)

  if (index !== -1) {
    park.objects[index] = updated
  } else {
    console.error('Object with the specified ID not found.')
  }

  return park
}

function sendMessage (ref, messageText) {
  const message = {
    action: 'ask',
    data: messageText
  }

  postMessage(ref, message)
}

// These events (load, save) are supposed to be on the iframe side, However
// added them here to demonstrate abstracting PS vs UE logic and exposing it
// as a sane API methods that encapsulate unreal logic.

function load (ref, origin, data) {
  const message = {
    messageType: 'PS',
    messageId: 'UE',
    data: {
      data: {
        command: 'load_world',
        data
      }
    }
  }

  postMessage(ref, message, origin)
}

function save (ref, origin) {
  const message = {
    command: 'save_world'
  }

  postMessage(ref, message, origin)
}

function postMessage (ref, message, origin = DEFAULT_ORIGIN) {
  const target = ref?.current
  if (!target) return

  target?.contentWindow?.postMessage(message, origin)
}

const unreal = {
  postMessage,
  handleMessages,
  sendMessage,
  load,
  save
}

export default unreal
