import React from 'react'
import { Box } from '@mui/material'
import styles from './Loading.module.css'

export default function Loading () {
  return (
    <Box className={styles.loaderContainer} data-testid='loader-container'>
      <Box className={styles.loader} data-testid='loader'>
        <img src='/images/ai_inkwell_logo_mini.svg' alt='logo' />
      </Box>
    </Box>
  )
}
