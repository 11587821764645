export const iconButtons = [
  {
    iconName: 'cursor',
    iconAction: () => { console.log('cursor action') }
  },
  {
    iconName: 'zoom',
    iconAction: () => { console.log('zoom action') }
  },
  {
    iconName: 'pin',
    iconAction: () => { console.log('pin action') }
  },
  {
    iconName: 'lasso',
    iconAction: () => { console.log('lasso action') }
  },
  {
    iconName: 'brush',
    iconAction: () => { console.log('brush action') }
  },
  {
    iconName: 'crop',
    iconAction: () => { console.log('crop action') }
  },
  {
    iconName: 'trash',
    iconAction: () => { console.log('cursor action') }
  }
]

export const ConstraintsLists = [
  [
    {
      id: 1,
      content: 'Site Footprint*',
      isOption: false
    },
    {
      id: 2,
      content: 'Small or Oddly Shaped Site',
      isOption: true
    },
    {
      id: 3,
      content: 'Limited Staging/Storage Areas',
      isOption: true
    },
    {
      id: 4,
      content: 'Difficult Access for Vehicle/Equipment',
      isOption: true
    }
  ],
  [
    {
      id: 1,
      content: 'Sloped Terrain',
      isOption: true
    },
    {
      id: 2,
      content: 'Uneven Bedrock',
      isOption: true
    },
    {
      id: 3,
      content: 'Water Presence',
      isOption: true
    }
  ]
]

export const design3dModes = [
  {
    mode: '3d-outliner',
    text: '3d-outliner'
  },
  {
    mode: 'material-swap',
    text: 'material swap'
  },
  {
    mode: 'primitive-library',
    text: 'primitive library'
  },
  {
    mode: '2d-slice',
    text: '2d slice'
  },
  {
    mode: 'render-view',
    text: 'render view'
  },
  {
    mode: 'interactive-render',
    text: 'interactive render'
  },
  {
    mode: 'render-asset-library',
    text: 'render asset library'
  }
]

export const firstTimeUserPrompts = [
  'A Quick tour of how to use the app\'s features',
  'How to use the app to design a park',
  'What are the file types supported by the app?',
  'A list of available attractions'
]

export const budgetPrompts = [
  'What is the budget for the project?',
  'What are the constraints for the project?',
  'what does the budget include?'
]

export const designPrompts = [
  'what are the design constraints?',
  'what are the design requirements?',
  'what are the design preferences?'
]

export const chatPrompts = [
  'How to generate a budget?',
  'What is the scope of the project?',
  'Can you try to explain this more?'
]

export const assistants = [
  {
    name: 'general-agent',
    icon: 'general-agent.png'
  },
  {
    name: 'estimator-agent',
    icon: 'estimator-agent.png'
  }
]

export const exportOptions = [
  'pdf',
  'xlsx'
]
