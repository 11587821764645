import React, { useEffect, useRef, useState } from 'react'
import styles from './styles.module.css'
import unreal from '../../utils/unreal'

// Should be moved to a dedicated module based on the AI agent integration
const getAIAgentResponse = () => {
  return {
    source: 'LLM',
    actions: [
      {
        action: 'select_asset', // should be command
        object: 'Select asset',
        id: 'select-asset',
        position: '(lat: xx, lng: yy)'
      }
    ]
  }
}

function PSPlayer () {
  const iframeRef = useRef()

  const [inputText, setInputText] = useState('')

  useEffect(() => {
    const cleaner = unreal.handleMessages(iframeRef)
    return cleaner
  }, [])

  const handleInputChange = (e) => {
    setInputText(e.target.value)
  }

  const handleLoadWorld = () => {
    unreal.load(iframeRef)
  }

  const handleSaveWorld = () => {
    unreal.save(iframeRef)
  }

  const handleSendMessage = () => {
    unreal.sendMessage(iframeRef, inputText)
    // send message to AI agent and get response
    const response = getAIAgentResponse()
    unreal.postMessage(iframeRef, response)
  }

  return (
    <div className={styles.pageContainer}>
      <iframe
        ref={iframeRef}
        id='ps-iframe'
        title='PS iframe'
        src='mock-ps-iframe.html'
        className={styles.iframeContainer}
      />

      <div>
        <button onClick={handleSendMessage}>Ask</button>
        <input
          type='text'
          value={inputText}
          onChange={handleInputChange}
          placeholder='ask AI something...'
        />
      </div>

      <div>
        <button id='load-world' onClick={handleLoadWorld}>
          Load world
        </button>
        <button id='save-world' onClick={handleSaveWorld}>
          Save world
        </button>
      </div>
    </div>
  )
}

export default PSPlayer
