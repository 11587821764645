import React, { useEffect } from 'react'
import styles from './Login.module.css'
import { Typography, Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../Context/AuthContext'
import { AUTHENTIC_SERVER } from '../../api'

const { origin } = window.location
const authenticUrl = `${AUTHENTIC_SERVER}/auth/google?redirectUrl=${origin}`

export default function Login () {
  const navigate = useNavigate()
  const { currentUser, error } = useAuth()

  const handleLogin = async (e) => {
    window.location.href = authenticUrl
  }

  useEffect(() => {
    if (currentUser?.email) navigate('/')
  }, [currentUser, navigate])

  return (
    <Box container className={styles.loginContainer}>
      <img
        src='/images/ai_inkwell_logo.png'
        alt='logo'
        className={styles.aiInkwellLogo}
      />
      <Box className={styles.loginWrapper} item xs={12} sm={6} md={4}>
        {error &&
          <Typography color='error' align='center'>
            {error}
          </Typography>}
        <Box className={styles.platformLogin} onClick={handleLogin}>
          <img
            src='/images/google-logo.svg'
            alt='google'
            className={styles.googleLogo}
          />
          <Typography>Continue with Google</Typography>
        </Box>
      </Box>
    </Box>
  )
}
