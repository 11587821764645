import React from 'react'
import { Box, Typography, LinearProgress, Tooltip } from '@mui/material'
import styles from './ConfidenceLevel.module.css'

const getProgressStyle = (score) => {
  if (score >= 80) {
    return { color: 'green' }
  } else if (score >= 50) {
    return { color: 'yellow' }
  } else {
    return { color: 'red' }
  }
}

export default function ConfidenceLevel ({ confidence }) {
  const { score, description, additionalInfo, math } = confidence || {}
  const progressStyle = getProgressStyle(score)

  return (
    <Box className={styles.container}>
      <Tooltip title={description} arrow>
        <p className={styles.title}>Confidence Level</p>
        <Box className={styles.progressContainer}>
          <LinearProgress
            variant='determinate'
            value={score}
            sx={{
              width: '100%',
              height: '10px',
              border: '1px solid #ccc',
              borderRadius: '5px',
              '& .MuiLinearProgress-bar': {
                backgroundColor: progressStyle.color
              },
              backgroundColor: 'transparent'
            }}
          />
          <Typography variant='body2' className={styles.score}>
            {score}%
          </Typography>
        </Box>
      </Tooltip>
      <Box className={styles.resultDisplay}>
        <p>Problem: {math.problem}</p>
        <p>Solution: </p>
        <ul style={{ margin: '0px' }}>
          {Array.isArray(math.solution)
            ? math.solution.map((sol, index) => <li key={index}>{sol}</li>)
            : <li>{math.solution}</li>}
        </ul>
      </Box>
      <Box className={styles.additionalInfo}>
        <p>Historical Accuracy: {additionalInfo.historicalAccuracy}</p>
        <p>Error Margin: {additionalInfo.errorMargin}</p>
        <p>Solution Consistency: {additionalInfo.solutionConsistency}</p>
      </Box>
    </Box>
  )
}
