import React, { useState } from 'react'
import { Box, Menu } from '@mui/material'
import styles from './Checklist.module.css'

const todochecklists = [
  {
    item: 'Action Item',
    stakeholder: 'Stakeholder',
    dueDate: 'Due Date',
    checked: false
  },
  {
    item: 'Action Item',
    stakeholder: 'Stakeholder',
    dueDate: 'Due Date',
    checked: false
  },
  {
    item: 'Action Item',
    stakeholder: 'Stakeholder',
    dueDate: 'Due Date',
    checked: false
  },
  {
    item: 'Action Item',
    stakeholder: 'Stakeholder',
    dueDate: 'Due Date',
    checked: false
  },
  {
    item: 'Action Item',
    stakeholder: 'Stakeholder',
    dueDate: 'Due Date',
    checked: false
  },
  {
    item: 'Action Item',
    stakeholder: 'Stakeholder',
    dueDate: 'Due Date',
    checked: false
  }
]

export default function Checklist ({ anchorEl, setAnchorEl }) {
  const [checklists, setChecklists] = useState(todochecklists)

  const handleNotify = (index) => {
    setChecklists((prevChecklists) =>
      prevChecklists.map((checklist, i) => {
        if (i === index) {
          return { ...checklist, checked: !checklist.checked }
        }
        return checklist
      })
    )
  }

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}
      sx={{
        '& .MuiPaper-root': {
          padding: '12px 20px',
          borderRadius: '7px',
          backgroundColor: '#131415'
        }
      }}
    >
      <Box className={styles.checklist}>
        <p>Stage</p>
        <Box className={styles.actionList}>
          {checklists.map((checklist, index) => {
            const { checked, item, stakeholder, dueDate } = checklist
            const path = checked ? '-checked' : '-unchecked'
            return (
              <Box className={styles.checklistItem} key={index}>
                <img
                  src={`/images/icons/checkmark${path}.svg`}
                  alt='Question'
                  className={styles.checkmarkIcon}
                  onClick={() => handleNotify(index)}
                />
                <p>{item}</p>
                <p>{stakeholder}</p>
                <p>{dueDate}</p>
                <button className={styles.notify}>Notify</button>
              </Box>
            )
          })}
        </Box>
      </Box>
    </Menu>
  )
}
