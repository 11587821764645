import { convertUnixTimestampToDate } from './date'

const compareByLastUpdated = (a, b) => b.lastUpdated - a.lastUpdated

export function filterProjects (projects, search = '') {
  const sortedProjects = projects.sort(compareByLastUpdated)
  const now = new Date()
  const thirtyDaysAgo = new Date(now)
  thirtyDaysAgo.setDate(now.getDate() - 30)
  const startOfYear = new Date(now.getFullYear(), 0, 1)

  const recentProjects = []
  const last30DaysProjects = []
  const thisYearProjects = []
  const allTimeProjects = []
  sortedProjects.forEach((project, index) => {
    const lastUpdated = convertUnixTimestampToDate(project.lastUpdated)
    if (project.name.toLowerCase().includes(search.toLowerCase())) {
      if (index < 3) {
        recentProjects.push(project)
      } else if (lastUpdated >= thirtyDaysAgo) {
        last30DaysProjects.push(project)
      } else if (lastUpdated >= startOfYear) {
        thisYearProjects.push(project)
      } else {
        allTimeProjects.push(project)
      }
    }
  })

  return {
    recentProjects,
    last30DaysProjects,
    thisYearProjects,
    allTimeProjects
  }
}

export function generateTextContent (text, persona = '', source = {}) {
  return [
    {
      text: {
        annotations: [],
        value: text,
        persona,
        source,
        followups: []
      },
      type: 'text'
    }
  ]
}
