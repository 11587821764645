import { Box, TextField, Typography } from '@mui/material'
import React from 'react'
import { useAuth } from '../../Context/AuthContext'

export default function UserDetails () {
  const { currentUser } = useAuth()
  return (
    <Box
      sx={{
        width: '550px',
        mb: 3
      }}
    >
      <Typography variant='h6' gutterBottom>
        Profile Details
      </Typography>
      <Box my={2}>
        <TextField
          label='Email'
          value={currentUser.email}
          disabled
          fullWidth
        />
      </Box>
    </Box>
  )
}
