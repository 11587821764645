import React from 'react'
import { Box, Typography } from '@mui/material'

export default function ComingSoon () {
  return (
    <Box sx={{
      height: 'calc(100vh - 60px)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}
    >
      <Typography variant='h3'>
        Coming Soon!
      </Typography>
    </Box>
  )
}
