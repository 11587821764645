import React from 'react'
import { Box, Modal, Divider } from '@mui/material'
import styles from './Description.module.css'

export default function Description ({ open, setOpen }) {
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      BackdropProps={{ style: { backgroundColor: 'transparent' } }}
      className={styles.descriptionContainer}
    >
      <Box className={styles.description}>
        <Box className={styles.tabs}>
          <button className={`${styles.tab} ${styles.active}`}>
            Basic Information
          </button>
          <button className={styles.tab}>Show Information</button>
          <button className={styles.tab}>Ride Specifications</button>
          <button className={styles.tab}>Taxonomy</button>
          <button className={styles.tab}>Historical Comparison</button>
        </Box>
        <Divider sx={{ backgroundColor: '#DCE2E5', height: '1px' }} />
        <p className={styles.attractionName}>Attraction Name</p>
        <Box className={styles.mainDetails}>
          <Box className={styles.subName}>
            <p>Sub-Name</p>
            <img
              src='/images/3d-designs/attraction.png'
              alt='Attraction'
              className={styles.attractionImg}
            />
          </Box>
          <Box className={styles.subName}>
            <p>Location</p>
            <Box className={styles.locations}>
              <p>Resort</p>
              <p>Park</p>
              <p>Land</p>
            </Box>
          </Box>
          <Box className={styles.subName}>
            <p>General</p>
            <Box className={styles.general}>
              <Box className={styles.generalList}>
                <p>Resort</p>
                <p>Park</p>
                <p>Land</p>
                <p>Ride Type</p>
                <p>Theme</p>
                <p>Ride System</p>
                <p>Track Comparison</p>
              </Box>
              <Box className={styles.generalList}>
                <p>Open Date</p>
                <p>CAPEX</p>
                <p>Site Size</p>
                <p>Guest Cap/Hr</p>
                <p>Process Phase</p>
                <p>Approval Status</p>
                <p>Project Created</p>
                <p>Project Updated</p>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={styles.mainDetails}>
          <Box className={styles.subName}>
            <p>Version History</p>
            <Box className={styles.versionHistory}>
              <p>Version 1</p>
              <p>Version 2</p>
              <p>Version 3</p>
              <p>Version 4</p>
              <p>Version 5</p>
            </Box>
          </Box>
          <Box className={styles.subName}>
            <p>WDI Description</p>
            <Box className={styles.wdiDescription}>
              <p>This is the large show description from WDI as the show was
                written, we can also provide links to other WDI information if
                a script, 3D assets or other information needs to be available
              </p>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}
