import React, { useMemo } from 'react'
import { Box, Menu } from '@mui/material'
import styles from './ProjectInfo.module.css'

const projectDetails = [
  { title: 'Experience Name', value: 'project_name' },
  { title: 'Resort', value: null },
  { title: 'Park', value: null },
  { title: 'Land', value: null },
  { title: 'Ride Type', value: null },
  { title: 'Theme', value: null },
  { title: 'Ride System', value: null },
  { title: 'Track Comparison', value: null },
  { title: 'Open Date', value: 'opening_date' },
  { title: 'CAPEX', value: null },
  { title: 'Site Size', value: null },
  { title: 'Guest Cap/Hr', value: null },
  { title: 'Process Phase', value: null },
  { title: 'Approval Status', value: null },
  { title: 'Project Created', value: 'created_at' },
  { title: 'Project Updated', value: 'updated_at' },
  { title: 'Created By', value: 'email' },
  { title: 'Project Lead', value: 'email' },
  { title: 'Estimator', value: null }
]

export default function ProjectInfo ({ anchorElInfo, setAnchorElInfo, project }) {
  const parsedProject = useMemo(() => {
    return {
      ...project,
      opening_date: project?.opening_date
        ? `${project.opening_date.month} / ${project.opening_date.year}`
        : '',
      created_at: (project?.created_at || '').slice(0, 10),
      updated_at: (project?.updated_at || '').slice(0, 10)
    }
  }, [project])

  return (
    <Menu
      anchorEl={anchorElInfo}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      open={Boolean(anchorElInfo)}
      onClose={() => setAnchorElInfo(null)}
      sx={{
        '& .MuiPaper-root': {
          width: '565px',
          padding: '12px 20px',
          borderRadius: '7px',
          backgroundColor: '#131415'
        }
      }}
    >
      <Box className={styles.projectInfo}>
        {projectDetails.map((detail, index) => {
          const value = detail.value ? parsedProject[detail.value] : ''
          return (
            <div key={index}>
              <p>{detail.title}</p>
              {!!value && <p>{value}</p>}
            </div>
          )
        })}
      </Box>
    </Menu>
  )
}
