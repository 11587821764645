import React, { useState } from 'react'
import { Box } from '@mui/material'
import styles from './TimelineList.module.css'
import { useNavigate } from 'react-router-dom'

const LIMIT = 5

export default function TimelineList ({ title, projects, toggleDrawer }) {
  const [expanded, setExpanded] = useState(false)
  const navigate = useNavigate()
  const toggleExpanded = () => {
    setExpanded(prev => !prev)
  }
  return (
    <Box className={styles.timelineListContainer}>
      <p className={styles.title}>{title}</p>
      {projects.slice(0, expanded ? projects.length : LIMIT).map(project => (
        <Box
          component='div'
          className={styles.listItem}
          key={project.id}
          onClick={() => {
            toggleDrawer()
            navigate(`/${project.id}`)
          }}
        >
          <p className={styles.listText}>{project.name}</p>
          <Box
            className={styles.exportIconContainer}
          >
            <img
              src='/images/icons/export-icon.svg'
              alt='export'
              className={styles.exportIcon}
            />
          </Box>
        </Box>
      ))}
      {projects.length > LIMIT && (
        <p className={styles.showMore} onClick={toggleExpanded}>
          {expanded ? 'Show less' : 'Show more...'}
        </p>
      )}
      {projects.length < 1 && (
        <p className={styles.listText}>No projects to show</p>
      )}
    </Box>
  )
}
