import React from 'react'
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import CustomCell from '../../CustomCell/CustomCell'
const customHeader = [
  '#',
  'Activities',
  'Labor',
  'Material',
  'Fixed',
  'Budget',
  'Actual Cost',
  'Under/Over',
  'Delta'
]

const tableThemes = [
  {
    headerBg: '#4A62D8',
    highlightBg: '#ADB5D9'
  },
  {
    headerBg: '#1AB28A',
    highlightBg: '#ADD9CD'
  },
  {
    headerBg: '#746BAB',
    highlightBg: '#B3ADD9'
  },
  {
    headerBg: '#76D0C0',
    highlightBg: '#A3D9CF'
  }
]

export default function FullEstimate ({ budget, mode = null, rows, setRows }) {
  const handleLockClick = ({ type, index, rowId, cellId }) => {
    if (mode !== 'lock') return

    setRows(currentRows => {
      const updatedRows = [...currentRows]
      updatedRows[index] = updatedRows[index].map(item => {
        if (item.id === rowId) {
          const updatedItem = { ...item }
          Object.keys(updatedItem).forEach(key => {
            if (type === 'row' || updatedItem[key].id === cellId) {
              if (updatedItem[key]?.locked) {
                updatedItem[key] = {
                  ...updatedItem[key],
                  locked: {
                    status: !updatedItem[key].locked.status
                  }
                }
              }
            }
          })
          return updatedItem
        }
        return item
      })
      return updatedRows
    })
  }

  const handleHighlightClick = ({
    type,
    color,
    index,
    rowId,
    cellId
  }) => {
    if (mode !== 'highlight') return
    setRows(currentRows => {
      const updatedRows = [...currentRows]
      updatedRows[index] = updatedRows[index].map(item => {
        if (item.id === rowId) {
          const updatedItem = { ...item }
          Object.keys(updatedItem).forEach(key => {
            if (type === 'row' || updatedItem[key].id === cellId) {
              if (updatedItem[key]?.highlight) {
                updatedItem[key] = {
                  ...updatedItem[key],
                  highlight: {
                    status: !updatedItem[key].highlight.status,
                    color
                  }
                }
              }
            }
          })

          return updatedItem
        }
        return item
      })
      return updatedRows
    })
  }
  const handleUpdate = ({
    type = 'cell',
    newValue,
    metadata,
    index,
    rowId,
    cellId
  }) => {
    if (!['edit', 'highlight'].includes(mode)) return
    setRows(currentRows => {
      const updatedRows = [...currentRows]
      updatedRows[index] = updatedRows[index].map(item => {
        if (type === 'row' && item.id === rowId) {
          const updatedItem = { ...item }
          Object.keys(updatedItem).forEach(key => {
            if (updatedItem[key]?.highlight) {
              const notes =
                `${updatedItem[key].metadata?.notes ?? ''}\n${metadata?.notes}`
              updatedItem[key] = {
                ...updatedItem[key],
                metadata: {
                  ...updatedItem[key].metadata,
                  notes
                }
              }
            }
          })
          return updatedItem
        }
        if (item.id === rowId && type === 'cell') {
          const updatedItem = { ...item }
          Object.keys(updatedItem).forEach(key => {
            if (updatedItem[key].id === cellId) {
              updatedItem[key] = {
                ...updatedItem[key],
                value: newValue,
                metadata,
                updated: true
              }
            }
          })

          return updatedItem
        }
        return item
      })
      return updatedRows
    })
  }

  function checkLockedStatus (obj) {
    for (const key in obj) {
      const activity = obj[key]
      if (activity.locked && !activity.locked.status) {
        return false
      }
    }
    return true
  }
  function checkHighlightStatus (obj) {
    for (const key in obj) {
      const activity = obj[key]
      if (activity.highlight && !activity.highlight.status) {
        return false
      }
    }
    return true
  }

  return (
    <TableContainer sx={{ height: '100%' }} component={Paper}>
      <Table
        size='small'
        aria-label='Full Estimate table'
        stickyHeader
      >
        {rows.map((table, ind) => (
          <React.Fragment key={ind}>
            <TableHead key={ind}>
              <TableRow>
                {mode === 'lock' &&
                  <CustomCell content={ind ? '' : 'Lock'} />}
                {mode === 'highlight' &&
                  <CustomCell content={ind ? '' : 'Highlight'} />}
                {customHeader.map(head => (
                  <CustomCell
                    key={head}
                    header
                    content={head}
                    theme={tableThemes[ind]}
                  />
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {table.map((row, index) => (
                <TableRow key={row.costCode}>
                  {mode === 'lock' &&
                    <CustomCell
                      content={null}
                      align='center'
                      mode={mode}
                      handleLockClick={() =>
                        handleLockClick(
                          { type: 'row', index: ind, rowId: row.id })}
                      locked={checkLockedStatus(row)
                        ? { status: true }
                        : {}}
                    />}
                  {mode === 'highlight' &&
                    <CustomCell
                      content={null}
                      align='center'
                      mode={mode}
                      handleHighlightClick={(color) =>
                        handleHighlightClick({
                          type: 'row',
                          color,
                          index: ind,
                          rowId: row.id
                        })}
                      highlight={checkHighlightStatus(row)
                        ? { status: true }
                        : {}}
                      handleUpdate={(value, metadata) => handleUpdate({
                        value,
                        type: 'row',
                        metadata,
                        index: ind,
                        rowId: row.id
                      })}
                    />}
                  <CustomCell
                    content={row.costCode}
                    adminstrativeRep={row.administrativeExp}
                    theme={tableThemes[index]}
                  />
                  <CustomCell
                    content={row.activity}
                    adminstrativeRep={row.administrativeExp}
                    theme={tableThemes[index]}
                  />
                  <CustomCell
                    align='right'
                    content={row.laborCosts.value.toFixed(2)}
                    metadata={row.laborCosts.metadata}
                    updated={row.laborCosts.updated}
                    locked={row.laborCosts.locked}
                    highlight={row.laborCosts.highlight}
                    handleHighlightClick={(color) =>
                      handleHighlightClick({
                        type: 'cell',
                        color,
                        index: ind,
                        rowId: row.id,
                        cellId: row.laborCosts.id
                      })}
                    handleUpdate={(value, metadata) =>
                      handleUpdate({
                        newValue: value,
                        metadata,
                        index: ind,
                        rowId: row.id,
                        cellId: row.laborCosts.id
                      })}
                    handleLockClick={() =>
                      handleLockClick(
                        {
                          type: 'cell',
                          index: ind,
                          rowId: row.id,
                          cellId: row.laborCosts.id
                        })}
                    prefix='$'
                    adminstrativeRep={row.administrativeExp}
                    theme={tableThemes[index]}
                    mode={mode}
                  />
                  <CustomCell
                    align='right'
                    content={row.materialCosts.value.toFixed(2)}
                    metadata={row.materialCosts.metadata}
                    updated={row.materialCosts.updated}
                    locked={row.materialCosts.locked}
                    highlight={row.materialCosts.highlight}
                    handleHighlightClick={(color) =>
                      handleHighlightClick({
                        type: 'cell',
                        color,
                        index: ind,
                        rowId: row.id,
                        cellId: row.materialCosts.id
                      })}
                    handleUpdate={(value, metadata) =>
                      handleUpdate({
                        newValue: value,
                        metadata,
                        index: ind,
                        rowId: row.id,
                        cellId: row.materialCosts.id
                      })}
                    handleLockClick={() =>
                      handleLockClick(
                        {
                          type: 'cell',
                          index: ind,
                          rowId: row.id,
                          cellId: row.materialCosts.id
                        })}
                    prefix='$'
                    adminstrativeRep={row.administrativeExp}
                    theme={tableThemes[index]}
                    mode={mode}
                  />
                  <CustomCell
                    align='right'
                    content={row.fixedCosts.value.toFixed(2)}
                    metadata={row.fixedCosts.metadata}
                    updated={row.fixedCosts.updated}
                    locked={row.fixedCosts.locked}
                    highlight={row.fixedCosts.highlight}
                    handleHighlightClick={(color) =>
                      handleHighlightClick({
                        type: 'cell',
                        color,
                        index: ind,
                        rowId: row.id,
                        cellId: row.fixedCosts.id
                      })}
                    handleUpdate={(value, metadata) =>
                      handleUpdate({
                        newValue: value,
                        metadata,
                        index: ind,
                        rowId: row.id,
                        cellId: row.fixedCosts.id
                      })}
                    handleLockClick={() =>
                      handleLockClick(
                        {
                          type: 'cell',
                          index: ind,
                          rowId: row.id,
                          cellId: row.fixedCosts.id
                        })}
                    prefix='$'
                    adminstrativeRep={row.administrativeExp}
                    theme={tableThemes[index]}
                    mode={mode}
                  />
                  <CustomCell
                    align='right'
                    content={row.budget.value.toFixed(2)}
                    metadata={row.budget.metadata}
                    updated={row.budget.updated}
                    locked={row.budget.locked}
                    highlight={row.budget.highlight}
                    handleHighlightClick={(color) =>
                      handleHighlightClick({
                        type: 'cell',
                        color,
                        index: ind,
                        rowId: row.id,
                        cellId: row.budget.id
                      })}
                    handleUpdate={(value, metadata) =>
                      handleUpdate({
                        newValue: value,
                        metadata,
                        index: ind,
                        rowId: row.id,
                        cellId: row.budget.id
                      })}
                    handleLockClick={() =>
                      handleLockClick(
                        {
                          type: 'cell',
                          index: ind,
                          rowId: row.id,
                          cellId: row.budget.id
                        })}
                    prefix='$'
                    adminstrativeRep={row.administrativeExp}
                    theme={tableThemes[index]}
                    mode={mode}
                  />
                  <CustomCell
                    align='right'
                    content={row.actualCosts.value.toFixed(2)}
                    metadata={row.actualCosts.metadata}
                    updated={row.actualCosts.updated}
                    locked={row.actualCosts.locked}
                    highlight={row.actualCosts.highlight}
                    handleHighlightClick={(color) =>
                      handleHighlightClick({
                        type: 'cell',
                        color,
                        index: ind,
                        rowId: row.id,
                        cellId: row.actualCosts.id
                      })}
                    handleUpdate={(value, metadata) =>
                      handleUpdate({
                        newValue: value,
                        metadata,
                        index: ind,
                        rowId: row.id,
                        cellId: row.actualCosts.id
                      })}
                    handleLockClick={() =>
                      handleLockClick(
                        {
                          type: 'cell',
                          index: ind,
                          rowId: row.id,
                          cellId: row.actualCosts.id
                        })}
                    prefix='$'
                    adminstrativeRep={row.administrativeExp}
                    theme={tableThemes[index]}
                    mode={mode}
                  />
                  <CustomCell
                    align='right'
                    content={row.underOver.value.toFixed(2)}
                    metadata={row.underOver.metadata}
                    updated={row.underOver.updated}
                    locked={row.underOver.locked}
                    highlight={row.underOver.highlight}
                    handleHighlightClick={(color) =>
                      handleHighlightClick({
                        type: 'cell',
                        color,
                        index: ind,
                        rowId: row.id,
                        cellId: row.underOver.id
                      })}
                    handleUpdate={(value, metadata) =>
                      handleUpdate({
                        newValue: value,
                        metadata,
                        index: ind,
                        rowId: row.id,
                        cellId: row.underOver.id
                      })}
                    handleLockClick={() =>
                      handleLockClick(
                        {
                          type: 'cell',
                          index: ind,
                          rowId: row.id,
                          cellId: row.underOver.id
                        })}
                    prefix='$'
                    adminstrativeRep={row.administrativeExp}
                    theme={tableThemes[index]}
                    mode={mode}
                  />
                  <CustomCell
                    align='right'
                    content={row.deltaPercent.value}
                    metadata={row.deltaPercent.metadata}
                    updated={row.deltaPercent.updated}
                    locked={row.deltaPercent.locked}
                    highlight={row.deltaPercent.highlight}
                    handleHighlightClick={(color) =>
                      handleHighlightClick({
                        type: 'cell',
                        color,
                        index: ind,
                        rowId: row.id,
                        cellId: row.deltaPercent.id
                      })}
                    handleUpdate={(value, metadata) =>
                      handleUpdate({
                        value,
                        metadata,
                        index: ind,
                        rowId: row.id,
                        cellId: row.deltaPercent.id
                      })}
                    handleLockClick={() =>
                      handleLockClick(
                        {
                          type: 'cell',
                          index: ind,
                          rowId: row.id,
                          cellId: row.deltaPercent.id
                        })}
                    suffix='%'
                    adminstrativeRep={row.administrativeExp}
                    theme={tableThemes[index]}
                    mode={mode}
                  />
                </TableRow>
              ))}
            </TableBody>
          </React.Fragment>
        ))}
      </Table>
    </TableContainer>
  )
}
