import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Box } from '@mui/material'
import Toolbar from '../../components/Map/Toolbar/Toolbar'
import Description from '../../components/Map/Description/Description'
import Loading from '../../components/Loading/Loading'
import FloatingButtons from '../../components/FloatingButtons/FloatingButtons'
import { LAYOUT_IFRAME_URL, getProject, loadProjectJson } from '../../api'
import unreal from '../../utils/unreal'
import config from '../../config'
import { useProject } from '../../Context/ProjectContext'
import styles from './Map.module.css'

export default function Map () {
  const navigate = useNavigate()
  const { projectId } = useParams()
  const {
    project,
    setProject,
    setBudget,
    threadId,
    setThreadId,
    updateWorldState
  } = useProject()
  const [loading, setLoading] = useState(false)
  const [openDescription, setOpenDescription] = useState(false)

  const iframeRef = useRef()

  useEffect(() => {
    if (!config.psUrl) return
    if (project) {
      const cleaner = unreal.handleMessages(
        iframeRef,
        project,
        setBudget,
        updateWorldState,
        threadId,
        setThreadId
      )
      return cleaner
    }

    // eslint-disable-next-line
  }, [project, updateWorldState])

  useEffect(() => {
    const fetchProject = async () => {
      setLoading(true)
      try {
        const response = await getProject(projectId)
        if (!response.world_data) {
          // mock world data
          const res = await loadProjectJson()
          response.world_data = { ...res, name: response.project_name }
        }
        if (response) {
          setProject(response)
          setThreadId(response?.thread_id)
        } else navigate('/')
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }
    fetchProject()
    // eslint-disable-next-line
  }, [navigate, projectId, setProject])

  if (loading) return <Loading />
  return (
    <Box className={styles.mapContainer}>
      <Toolbar iframeRef={iframeRef} />
      <Box className={styles.body}>
        <Box>
          <FloatingButtons />
          <iframe
            ref={iframeRef}
            src={LAYOUT_IFRAME_URL}
            className={styles.iframe}
            title='map'
            data-testid='iframe'
          />
        </Box>
        <Description open={openDescription} setOpen={setOpenDescription} />
      </Box>
    </Box>
  )
}
