import React, { useState, useRef, useEffect } from 'react'
import { Box, CircularProgress } from '@mui/material'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import DoneIcon from '@mui/icons-material/Done'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import styles from './Input.module.css'
import config from '../../config'

export default function Input (
  {
    placeholder,
    submitValue,
    loading,
    handleFileClick,
    fileInputRef,
    handleFileChange,
    uploadLoading,
    uploadStatus
  }
) {
  const { blueSkyUploadFlag } = config
  const [inputText, setInputText] = useState('')
  const textAreaRef = useRef(null)

  const [uploadAnchorEl, setUploadAnchorEl] = useState(null)
  const open = Boolean(uploadAnchorEl)

  const handleUploadClick = (event) => {
    setUploadAnchorEl(event.currentTarget)
  }

  const handleUploadClose = () => {
    setUploadAnchorEl(null)
  }

  useEffect(() => {
    textAreaRef.current.style.height = '18px'
    const maxRows = 6
    const maxHeight = 18 * maxRows
    textAreaRef.current.style.height =
    `${Math.min(textAreaRef.current.scrollHeight, maxHeight)}px`
  }, [inputText])

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      if (loading || !inputText.trim()) return

      submitValue(inputText)
      setInputText('')
    }
  }

  const renderUploadIcon = () => {
    if (uploadStatus === 'success') {
      return (
        <DoneIcon
          style={{ color: 'green' }}
          sx={{ height: '18px' }}
        />
      )
    }
    if (uploadStatus === 'failed') {
      return (
        <InfoOutlinedIcon
          color='error'
          sx={{ height: '18px' }}
        />
      )
    }
    if (uploadLoading) {
      return <CircularProgress color='inherit' size='18px' />
    }

    return (
      <img
        src='/images/icons/paperclip.svg'
        alt='attachment'
        className={styles.attachmentIcon}
        onClick={handleUploadClick}
      />
    )
  }

  return (
    <Box className={styles.inputContainer} data-testid='input-container'>
      <textarea
        ref={textAreaRef}
        className={styles.textInput}
        value={inputText}
        onChange={(e) => setInputText(e.target.value)}
        onKeyDown={handleKeyPress}
        placeholder={placeholder}
      />
      <Box className={styles.iconsContainer}>
        {renderUploadIcon()}
        <input
          type='file'
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
        <img
          src='/images/icons/microphone.svg'
          alt='record'
          className={styles.recordIcon}
        />
      </Box>
      <Menu
        anchorEl={uploadAnchorEl}
        open={open}
        onClose={handleUploadClose}
      >
        <MenuItem onClick={() => {
          handleFileClick()
          handleUploadClose()
        }}
        >
          Upload Doc
        </MenuItem>
        <MenuItem onClick={() => {
          handleFileClick('budget')
          handleUploadClose()
        }}
        >
          Upload Budget (*.pdf only)
        </MenuItem>
        {
          blueSkyUploadFlag && (
            <MenuItem onClick={() => {
              handleFileClick('budget')
              handleUploadClose()
            }}
            >
              Upload Blue Sky Brief
            </MenuItem>
          )
        }

      </Menu>
    </Box>
  )
}
