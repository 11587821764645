import React, { useEffect, useState } from 'react'
import { IconButton, Typography, Box, Paper } from '@mui/material'
import Draggable from 'react-draggable'
import { ResizableBox } from 'react-resizable'
import 'react-resizable/css/styles.css'
import styles from './Console.module.css'
import CloseIcon from '@mui/icons-material/Close'
import { logs } from '../../axiosInterceptor'
import CollapsibleData from './CollapsibleData'

function DraggablePanel ({ children }) {
  return (
    <Draggable handle='#handle'>
      <ResizableBox
        width={500}
        height={300}
        className={styles.console}
        minConstraints={[100, 100]}
      >
        <Paper elevation={3} style={{ overflow: 'hidden', height: '100%' }}>
          {children}
        </Paper>
      </ResizableBox>
    </Draggable>
  )
}

export default function Console ({ open, setOpen }) {
  const [logState, setLogState] = useState([])
  const urlContainsMap = window.location.pathname.split('/').includes('map')

  const containerStyles = urlContainsMap
    ? {
        backgroundColor: 'white',
        color: 'black'
      }
    : {
        backgroundColor: 'black',
        color: 'white'
      }

  useEffect(() => {
    const interval = setInterval(() => {
      setLogState([...logs])
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  if (!open) return null

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <DraggablePanel>
      <Box id='handle' className={styles.consoleTitle}>
        <Typography variant='subtitle1'>Console</Typography>
        <IconButton onClick={handleClose} className={styles.closeIcon}>
          <CloseIcon fontSize='small' />
        </IconButton>
      </Box>
      <Box className={styles.consoleContent} style={containerStyles}>
        {logState.map((log, index) => {
          return (
            <Box key={index} mb={2}>
              <Typography
                variant='caption'
                color={urlContainsMap ? 'blue' : 'cyan'}
              >
                {`[${log.type.toUpperCase()}] `}
              </Typography>
              {log.type === 'request' && (
                <Typography
                  variant='caption'
                  color={urlContainsMap ? 'red' : 'yellow'}
                >
                  {`[${log?.method?.toUpperCase()}] `}
                </Typography>
              )}
              <Typography variant='caption'>
                {`${log.url}`}
              </Typography>
              {(log.data || log.error) && (
                <pre>
                  {`${log.type}Body:`}
                  <CollapsibleData object={log.data || log.error} />
                </pre>
              )}
            </Box>
          )
        })}
      </Box>
    </DraggablePanel>
  )
}
