import React, { useCallback, useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import NavBar from '../NavBar/NavBar'
import Footer from '../Footer/Footer'
import { fetchWithLoading, generateDesign } from '../../api'

export default function Transition (
  { setTransitionView, setCurrentView, setDesign }) {
  const [slide, setSlide] = useState(false)
  const [, setLoading] = useState(false)
  const [, setError] = useState(null)

  const handleGenerateDesign = useCallback(async () => {
    const response = await fetchWithLoading(
      () => generateDesign(),
      setLoading,
      setError
    )
    setDesign(response)
    setCurrentView('design')
    setTransitionView(false)
  }, [setDesign, setCurrentView, setTransitionView])

  useEffect(() => {
    handleGenerateDesign()
    const timeout = setTimeout(() => {
      setSlide(true)
    }, 100)

    return () => clearTimeout(timeout)
  }, [handleGenerateDesign])

  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        position: 'absolute',
        top: 0,
        left: 0,
        overflow: 'hidden',
        background: slide ? 'linear-gradient(to left, white 50%, #4A62D8 50%)' : 'white',
        backgroundSize: '200% 100%',
        backgroundPosition: slide ? 'left bottom' : 'right bottom',
        transition: 'background-position 3s linear',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'space-between',
        justifyContent: 'space-between'
      }}
    >
      <NavBar transition />
      <Typography
        fontSize='36px'
        fontWeight={300}
        lineHeight='46px'
        color='white'
        textAlign='center'
      >
        Populating 2D & 3D Builder <br />
        Calling Component Repository <br />
        Importing Procedural Assets
      </Typography>
      <Footer transition />
    </Box>
  )
}
