import React, { useState, useRef, useEffect } from 'react'
import { Box, Divider } from '@mui/material'
import PromptStarter from './PromptStarter/PromptStarter'
import styles from './Landing.module.css'
import { useAuth } from '../../Context/AuthContext'
import NewProjectModal from './NewProjectModal/NewProjectModal'
import PreviousProjects from './PreviousProjects/PreviousProjects'
import CreateUserModal from './CreateUserModal/CreateUserModal'
import { getProjects } from '../../api'

const promptStarters = [
  {
    iconSrc: '/images/icons/snowflake.svg',
    text: 'New Layout'
  },
  {
    iconSrc: '/images/icons/restart-icon-green.svg',
    text: 'Continue Last'
  },
  {
    iconSrc: '/images/icons/book.svg',
    text: 'Upload Assets'
  },
  {
    iconSrc: '/images/icons/settings.svg',
    text: 'Adjust Guidelines & Rules'
  }
]

export default function Landing({ setCurrentView, threadId }) {
  const ref = useRef(null)
  const { currentUser, createAuthenticUser } = useAuth()
  const givenName = currentUser?.givenName
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [search, setSearch] = useState('')
  const [openCreateUserModal, setOpenCreateUserModal] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [projects, setProjects] = useState([])
  const [displayProjects, setDisplayProjects] = useState(false)

  const handleCreateUser = async (user) => {
    setOpenCreateUserModal(false)
    await createAuthenticUser(user.name)
  }

  useEffect(() => {
    if (!currentUser?.isRealName) setOpenCreateUserModal(true)

    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setDisplayProjects(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [currentUser])

  useEffect(() => {
    const fetchProjects = async () => {
      setLoading(true)
      try {
        const res = await getProjects()
        setProjects(res)
      } catch (err) {
        setError(err)
      } finally {
        setLoading(false)
      }
    }
    fetchProjects()
  }, [])

  const filteredProjects = projects.filter(project =>
    project?.project_name.toLowerCase().includes(search.toLowerCase())
  )

  return (
    <>
      <Box
        className={styles.landingContainer}
        sx={{
          width: { xs: '100%', sm: '90%', md: '80%', lg: '65%', xl: '50%' },
          padding: { xs: '10px', md: '20px', lg: '30px' },
          margin: '0 auto'
        }}
      >
        <Box
          className={styles.searchContainer}
          ref={ref}
          sx={
            displayProjects
              ? {
                backgroundColor: '#f5f8fa',
                borderRadius: '15px 15px 5px 5px !important'
              }
              : {}
          }
        >
          <Box
            className={
              displayProjects ? styles.activeSearchbar : styles.searchbar
            }
          >
            <img
              src='/images/icons/search-icon.svg'
              alt='search'
              className={styles.searchIcon}
            />
            <input
              type='text'
              className={styles.searchInput}
              placeholder='Search Previous Projects'
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onMouseDown={() => setDisplayProjects(true)}
            />
          </Box>
          {displayProjects && (
            <PreviousProjects
              open={displayProjects}
              setOpen={setDisplayProjects}
              projects={filteredProjects}
              loading={loading}
              error={error}
            />
          )}
        </Box>
        <p className={styles.greeting}>{`Hello, ${givenName}`}</p>
        <Box className={styles.welcomeText}>
          <p>Welcome to Project ECCO,</p>
          <p>
            A communication tool for accelerating design estimations within the
            Blue Sky process.
          </p>
        </Box>
        <Divider sx={{ backgroundColor: '#DCE2E5', height: '1px' }} />
        <Box className={styles.starters}>
          <Box className={styles.promptStarters}>
            {promptStarters.map((promptStarter, index) => {
              return (
                <PromptStarter
                  key={index}
                  iconSrc={promptStarter.iconSrc}
                  text={promptStarter.text}
                  selected={index === 0}
                  onClick={() => {
                    index === 0 && setOpenModal(true)
                  }}
                />
              )
            })}
          </Box>
        </Box>
      </Box>
      <NewProjectModal
        open={openModal}
        setOpen={setOpenModal}
        setCurrentView={setCurrentView}
        threadId={threadId}
      />
      <CreateUserModal
        open={openCreateUserModal}
        setOpen={setOpenCreateUserModal}
        onCreateUser={handleCreateUser}
      />
    </>
  )
}
