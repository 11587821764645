import axios from 'axios'

const logs = []

const isUniqueLog = (log) => {
  return !logs.some(existingLog =>
    existingLog.type === log.type &&
    existingLog.url === log.url &&
    existingLog.method === log.method &&
    JSON.stringify(existingLog.data) === JSON.stringify(log.data) &&
    existingLog.status === log.status &&
    existingLog.error === log.error
  )
}

const axiosInstance = axios.create()

axiosInstance.interceptors.request.use(request => {
  const log = {
    type: 'request',
    url: request.url,
    method: request.method,
    data: request.data
  }
  if (isUniqueLog(log)) {
    logs.push(log)
  }
  return request
}, error => {
  const log = {
    type: 'request-error',
    error: error.message
  }
  if (isUniqueLog(log)) {
    logs.push(log)
  }
  return Promise.reject(error)
})

axiosInstance.interceptors.response.use(response => {
  const log = {
    type: 'response',
    url: response.config.url,
    status: response.status,
    data: response.data
  }
  if (isUniqueLog(log)) {
    logs.push(log)
  }
  return response
}, error => {
  const log = {
    type: 'response-error',
    error: error.message
  }
  if (isUniqueLog(log)) {
    logs.push(log)
  }
  return Promise.reject(error)
})

export { logs, axiosInstance }
