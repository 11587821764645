import React, { useEffect, useRef, useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  TextField,
  Checkbox,
  CircularProgress,
  Snackbar,
  Alert
} from '@mui/material'
import ChatInterface from '../Landing/ChatInterface/ChatInterface'
import styles from './ChatModal.module.css'
import { assistants } from '../../utils/constants'
import { summarize, uploadFile } from '../../api'
import { generateTextContent } from '../../utils/projects'
import Input from '../Input/Input'

const ChatModal = ({
  open,
  currentView,
  onClose,
  project,
  conversations,
  error,
  submitValue,
  loading,
  followups,
  setConversations
}) => {
  const [selectedAssistant, setSelectedAssistant] = useState(assistants[0])
  const fileInputRef = useRef(null)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [showPrompts, setShowPrompts] = useState(false)
  const [bottomMenu, setBottomMenu] = useState('')
  const [wordCount, setWordCount] = useState(200)
  const [summary, setSummary] = useState(null)
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  const [useTimeframe, setUseTimeframe] = useState(false)
  const [summarizing, setSummarizing] = useState(false)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarSeverity, setSnackbarSeverity] = useState('success')
  const [exlcudeConversation, setExcludeConversation] = useState([])
  const [isSummaryOpen, setIsSummaryOpen] = useState(false)
  const [pin, setPin] = useState(false)
  const [uploadLoading, setUploadLoading] = useState(false)
  const [uploadStatus, setUploadStatus] = useState('')
  const [uploadStructure, setUploadStructure] = useState(null)

  const handleBottomMenuContent = (content) => {
    const isClosing = bottomMenu === content
    setBottomMenu(isClosing ? '' : content)
    setIsSummaryOpen(!isClosing)
  }

  const chatTreeButtons = [
    { name: 'Decision History', icon: 'tree', active: true, onClick: () => {} },
    {
      name: 'Summarize',
      icon: 'wallet-pass',
      active: false,
      onClick: () => handleBottomMenuContent('summarize-menu')
    },
    { name: 'Sources', icon: 'network', active: false, onClick: () => {} },
    { name: 'Export', icon: 'tray-and-arrow', active: false, onClick: () => {} }
  ]

  const inactivityTimer = useRef(null)
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
      clearTimeout(window.uploadStatusTimeout)
    }
  }, [])

  useEffect(() => {
    setShowPrompts(false)
    if (conversations.length === 0) setShowPrompts(true)
    else {
      clearTimeout(inactivityTimer.current)
      inactivityTimer.current = setTimeout(() => {
        setShowPrompts(true)
      }, 5000)
    }
    return () => clearTimeout(inactivityTimer.current)
  }, [conversations])

  useEffect(() => {
    if (conversations.length === 0) setSelectedAssistant(assistants[0])
    else {
      const lastMessage = conversations[conversations.length - 1]
      if (lastMessage.role !== 'user') {
        if (lastMessage?.content[0]?.text?.persona === 'Estimator') {
          setSelectedAssistant(assistants[1])
        } else setSelectedAssistant(assistants[0])
      }
    }
  }, [conversations])

  const handleSummarize = async () => {
    let filteredConversations = conversations
    setSummarizing(true)
    if (useTimeframe) {
      const from = new Date(`${fromDate}T00:00:00`)
      const to = new Date(`${toDate}T23:59:59`)
      filteredConversations = filterMessagesByDate(conversations, from, to)
    }

    filteredConversations = filteredConversations.filter(
      (conversation) => !exlcudeConversation.includes(conversation.id)
    )

    if (filteredConversations < 1) {
      setSnackbarMessage('0 messages selected for this timeframe')
      setSnackbarSeverity('error')
      setSnackbarOpen(true)
      setSummarizing(false)
      return
    }

    try {
      const res = await summarize({
        messages: filteredConversations,
        wordCount
      })
      setSnackbarMessage('summarized successfully')
      setSnackbarSeverity('success')
      setSummarizing(false)
      setSnackbarOpen(true)
      setSummary(res.summary)
      setConversations(prev => [...prev, {
        role: 'estimator',
        type: 'summary',
        content: generateTextContent(res.summary)
      }])
      handleBottomMenuContent('')
      setIsSummaryOpen(false)
    } catch (error) {
      setSnackbarMessage('Error summarizing')
      setSnackbarSeverity('error')
      setSummarizing(false)
      setSnackbarOpen(true)
    }
  }

  const filterMessagesByDate = (messages, from, to) => {
    return messages.filter(message => {
      const messageDate = new Date(message.created_at * 1000)
      return messageDate >= from && messageDate <= to
    })
  }

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false)
  }

  const handleFileClick = (structure) => {
    setUploadStructure(structure)
    fileInputRef.current.click()
  }

  const handleFileChange = async (event) => {
    const file = event.target.files[0]
    if (!file) return

    if (uploadStructure === 'budget' && file.type !== 'application/pdf') {
      const ext = file.name
        .substring(file.name.lastIndexOf('.') + 1).toUpperCase()

      const extError = `${ext} is not supported`
      setSnackbarMessage(
        `${extError}. Only PDF files can be used for budget analysis.`
      )
      setSnackbarSeverity('error')
      setSnackbarOpen(true)
      fileInputRef.current.value = ''
      return
    }

    try {
      setUploadLoading(true)
      await uploadFile(file, uploadStructure)
      setSnackbarMessage('File uploaded successfully')
      setSnackbarSeverity('success')
      setUploadStatus('success')
      clearTimeout(window.uploadStatusTimeout)
      window.uploadStatusTimeout = setTimeout(() => {
        setUploadStatus('')
      }, 2000)
      setUploadLoading(false)
    } catch (error) {
      const errorString = error.response.data.error
      setSnackbarMessage(errorString)
      setSnackbarSeverity('error')
      setUploadLoading(false)
      setUploadStatus('failed')
      clearTimeout(window.uploadStatusTimeout)
      window.uploadStatusTimeout = setTimeout(() => {
        setUploadStatus('')
      }, 2000)
    }
    setSnackbarOpen(true)
    fileInputRef.current.value = ''
  }

  return (
    <Dialog
      open={open}
      onClose={pin ? null : onClose}
      fullScreen
      BackdropProps={{
        style: {
          backgroundColor: 'transparent'
        }
      }}
      PaperProps={{
        style: {
          position: 'absolute',
          right:
            windowWidth < 600
              ? '0'
              : (currentView === 'design' ? '50%' : '0px'),
          bottom: '0px',
          margin: 0,
          padding: '20px 20px',
          overflow: 'hidden',
          height: '100vh',
          borderRadius: 8,
          maxWidth: '600px',
          minWidth: '300px',
          backgroundColor: '#F5F8FA'
        }
      }}
      sx={{
        '& .MuiPaper-root': {
          boxShadow: 'none'
        }
      }}
    >
      <Box
        display='flex'
        flexDirection='row-reverse'
        padding={0}
      >
        <img
          style={{
            height: '12px',
            width: 'auto',
            cursor: 'pointer'
          }}
          src={`/images/icons/pin${pin ? '-active' : ''}.svg`}
          alt='pin'
          onClick={() => setPin(prev => !prev)}
        />
      </Box>
      <Box sx={{ flex: 1, overflow: 'auto', mb: 2 }}>
        <ChatInterface
          project={project}
          currentView={currentView}
          conversations={conversations}
          setConversations={setConversations}
          loading={loading}
          submitValue={submitValue}
          selectedAssistant={selectedAssistant}
          showPrompts={showPrompts}
          error={error}
          followups={followups}
          setExcludeConversation={setExcludeConversation}
          isSummaryOpen={isSummaryOpen}
          summary={summary}
          setSummary={setSummary}
        />
      </Box>
      <Input
        placeholder='What would you like to do?'
        loading={loading}
        submitValue={submitValue}
        handleFileClick={handleFileClick}
        fileInputRef={fileInputRef}
        handleFileChange={handleFileChange}
        uploadLoading={uploadLoading}
        uploadStatus={uploadStatus}
      />
      <Box className={styles.chatTreeContainer}>
        {chatTreeButtons.map((button, index) => {
          const { name, icon, active, onClick } = button
          return (
            <Box
              className={active
                ? `${styles.chatTree} ${styles.active}`
                : styles.chatTree}
              key={index}
              onClick={onClick}
            >
              <img
                src={`/images/icons/${icon}.svg`}
                alt={name}
                className={styles.chatTreeIcon}
              />
              <p>{name}</p>
            </Box>
          )
        })}
      </Box>
      {bottomMenu === 'summarize-menu' && (
        <Box maxHeight='35%' overflow='auto' p={2}>

          <Box display='flex' gap={2} my={1}>
            <FormControl fullWidth>
              <InputLabel id='word-count-select-label'>Word Count</InputLabel>
              <Select
                id='word-count-select'
                labelId='word-count-select-label'
                label='Word Count'
                value={wordCount}
                onChange={(e) => setWordCount(e.target.value)}
                size='small'
              >
                <MenuItem value={100}>Brief</MenuItem>
                <MenuItem value={200}>Moderate</MenuItem>
                <MenuItem value={300}>Detailed</MenuItem>
              </Select>
            </FormControl>
            {useTimeframe && (
              <>
                <TextField
                  label='From Date'
                  type='date'
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                  InputLabelProps={{
                    shrink: true
                  }}
                  fullWidth
                  size='small'
                  disabled={!useTimeframe}
                />
                <TextField
                  label='To Date'
                  type='date'
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                  InputLabelProps={{
                    shrink: true
                  }}
                  fullWidth
                  size='small'
                  disabled={!useTimeframe}
                />
              </>
            )}
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row-reverse',
              justifyContent: 'space-between',
              my: 0.5
            }}
          >
            <Button
              variant='containedSecondaryRounded'
              onClick={handleSummarize}
              disabled={summarizing}
              sx={{ minWidth: '120px' }}
            >
              {summarizing
                ? <CircularProgress size={14} sx={{ color: 'white' }} />
                : 'Summarize'}
            </Button>
            <Typography variant='h6' fontSize={12} my={0} lineHeight={1}>
              <Checkbox
                checked={useTimeframe}
                onChange={(e) => setUseTimeframe(e.target.checked)}
                inputProps={{ 'aria-label': 'Enable timeframe filtering' }}
                size='small'
                sx={{ ml: 1 }}
              />
              Timeframe
            </Typography>
          </Box>
        </Box>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        sx={{ maxWidth: '400px' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        sx={{ maxWidth: '400px' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Dialog>
  )
}

export default ChatModal
