import qs from 'qs'

const config = {
  clearFlag: false,
  mockPSFlag: false,
  saveWorldFlag: false,
  blueSkyUploadFlag: false,
  psUrl: 'https://ecco-aik-creator.uw.r.appspot.com',
  apiServer: 'https://ecco-api.wdi.aiinkwell.app'
}

const keyMap = {
  clearFlag: 'clear',
  mockPSFlag: 'mock-ps-flag',
  saveWorldFlag: 'save-world-flag',
  blueSkyUploadFlag: 'blue-sky-upload-flag',
  psUrl: 'ps-url',
  apiServer: 'api-server'
}

Object.keys(config).forEach((key) => {
  const mappedKey = keyMap[key] || key

  const lsVal = localStorage.getItem(mappedKey)
  if (lsVal) {
    config[key] = lsVal === 'true' ? true : lsVal === 'false' ? false : lsVal
  }

  const qsVal = qs.parse(window.location.search.slice(1))[mappedKey]
  if (qsVal) {
    const formattedValue =
      qsVal === 'true' ? true : qsVal === 'false' ? false : qsVal
    config[key] = formattedValue
    localStorage.setItem(mappedKey, formattedValue)
  }

  if (config.clearFlag) {
    Object.keys(keyMap).forEach((k) => {
      localStorage.removeItem(keyMap[k])
    })
  }
})

export default config
