import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Modal,
  Box,
  MenuItem,
  Select,
  Slide,
  Typography,
  Snackbar,
  Alert
} from '@mui/material'
import styles from './NewProjectModal.module.css'
import { saveProject } from '../../../api'

export default function NewProjectModal ({
  open,
  setOpen,
  setCurrentView,
  threadId
}) {
  const navigate = useNavigate()
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarSeverity, setSnackbarSeverity] = useState('success')
  const [project, setProject] = useState({
    name: '',
    targetInvestment: '',
    openingDate: { month: '', year: '' },
    theme: ''
  })
  const [errors, setErrors] = useState({})

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ]

  const handleInputChange = (event) => {
    setProject({ ...project, [event.target.name]: event.target.value })
  }

  const handleDateChange = (name, value) => {
    setProject({
      ...project,
      openingDate: { ...project.openingDate, [name]: value }
    })
  }

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false)
  }

  const CustomIcon = () => (
    <img
      src='/images/icons/arrow-down.svg'
      alt='Select'
      className={styles.selectIcon}
    />
  )

  const validateForm = () => {
    const newErrors = {}
    if (!project.name) newErrors.name = 'Project name is required'
    if (!project.theme) newErrors.theme = 'Theme is required'
    if (!project.targetInvestment || project.targetInvestment <= 500) {
      newErrors.targetInvestment = 'Target investment must be higher than $500'
    }
    const currentDate = new Date()
    const openingDate =
      new Date(project.openingDate.year, project.openingDate.month - 1)
    if (
      !project.openingDate.month ||
      !project.openingDate.year ||
      openingDate <= currentDate) {
      newErrors.openingDate = 'Opening date must be in the future'
    }
    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const handleCreateProject = async () => {
    if (!validateForm()) return

    try {
      const res = await saveProject({
        project_name: project.name,
        target_investment: project.targetInvestment,
        opening_date: project.openingDate,
        thread_id: threadId ?? null
      })
      setSnackbarMessage('Project created successfully')
      setSnackbarSeverity('success')
      setSnackbarOpen(true)
      setOpen(false)
      navigate(`/map/${res?._id}`)
    } catch (error) {
      console.error('Failed to create project:', error)
      const projectNameError =
        error?.response?.data?.errorDetails?.project_name?.message
      const stageError =
        error?.response?.data?.errorDetails?.stage?.message
      const confidenceLevelError =
        error?.response?.data?.errorDetails?.confidence_level?.message

      setSnackbarMessage(
        projectNameError ??
        stageError ??
        confidenceLevelError ??
        'Error Creating Project'
      )
      setSnackbarSeverity('error')
      setSnackbarOpen(true)
    }
  }

  return (
    <Box>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.2)'
          }
        }}
      >
        <Slide direction='up' in={open} mountOnEnter unmountOnExit>
          <Box className={styles.modal} sx={{}}>
            <Box className={styles.header}>
              <img
                src='/images/icons/close.svg'
                alt='Close'
                className={styles.closeIcon}
                onClick={() => setOpen(false)}
              />
            </Box>
            <Box className={styles.inputContainer}>
              <p>Project Name</p>
              <input
                type='text'
                name='name'
                value={project.name}
                onChange={handleInputChange}
                placeholder=''
                className={styles.projectNameInput}
              />
              {errors.name && (
                <Typography color='error' variant='caption'>
                  {errors.name}
                </Typography>
              )}
            </Box>
            <Box className={styles.inputContainer}>
              <p>Target Investment</p>
              <input
                type='number'
                name='targetInvestment'
                value={project.targetInvestment}
                onChange={handleInputChange}
                placeholder='$XXXXXXXX'
                className={styles.targetInput}
              />
              {errors.targetInvestment && (
                <Typography color='error' variant='caption'>
                  {errors.targetInvestment}
                </Typography>
              )}
            </Box>
            <Box className={styles.inputContainer}>
              <p>Opening Date</p>
              <Box className={styles.selectContainer}>
                <Select
                  displayEmpty
                  value={project.openingDate.month}
                  className={styles.selectInput}
                  onChange={(e) => handleDateChange('month', e.target.value)}
                  renderValue={(selected) => {
                    if (selected === '') {
                      return <p className={styles.selectLabel}>Month</p>
                    }
                    return months[selected - 1]
                  }}
                  IconComponent={CustomIcon}
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 'none',
                      outlined: 'none'
                    }
                  }}
                >
                  {months.map((month, index) => (
                    <MenuItem
                      key={month}
                      value={index + 1}
                      className={styles.selectOption}
                    >
                      {month}
                    </MenuItem>
                  ))}
                </Select>
                <Select
                  displayEmpty
                  value={project.openingDate.year}
                  className={styles.selectInput}
                  onChange={(e) => handleDateChange('year', e.target.value)}
                  renderValue={(selected) => {
                    if (selected === '') {
                      return <p className={styles.selectLabel}>Year</p>
                    }
                    return selected
                  }}
                  IconComponent={CustomIcon}
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 'none',
                      outlined: 'none'
                    }
                  }}
                >
                  {Array.from({ length: 12 }, (_, i) => 2024 + i).map((year) => (
                    <MenuItem
                      key={year}
                      value={year}
                      className={styles.selectOption}
                    >
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              {errors.openingDate && (
                <Typography color='error' variant='caption'>
                  {errors.openingDate}
                </Typography>
              )}
            </Box>
            <Box className={styles.inputContainer}>
              <p>IP</p>
              <input
                type='text'
                name='theme'
                value={project.theme}
                onChange={handleInputChange}
                placeholder='What’s the theme of your ride?'
                className={styles.projectNameInput}
              />
              {errors.theme && (
                <Typography color='error' variant='caption'>
                  {errors.theme}
                </Typography>
              )}
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <button className={styles.uploadButton}>Upload</button>
              <button
                className={styles.createButton}
                onClick={handleCreateProject}
              >
                Create
              </button>
            </Box>
          </Box>
        </Slide>
      </Modal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        sx={{ maxWidth: '400px' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  )
}
