import React from 'react'
import { Box } from '@mui/material'
import styles from './Footer.module.css'

export default function Footer ({ dragDrop }) {
  return (
    <Box className={styles.footerContainer}>
      <Box className={styles.footer}>
        <Box sx={{ width: '21px' }} />
        <p
          className={styles.footerText}
          style={{ color: dragDrop ? '#FFF' : '#939699' }}
        >
          This is experimental software. Verify all results.
        </p>
      </Box>
    </Box>
  )
}
