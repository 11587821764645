import React from 'react'
import {
  Box,
  Popover,
  Table,
  styled,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell
} from '@mui/material'
import { tableCellClasses } from '@mui/material/TableCell'
import { RECENT_PROJECTS } from '../../constants/projects'
import styles from './RecentProjects.module.css'
import ProjectStatus from '../ProjectStatus/ProjectStatus'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#DCE2E5',
    color: '#131415',
    borderBottom: '1px solid #939699',
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '18px',
    padding: '10px 15px'
  },
  [`&.${tableCellClasses.body}`]: {
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '18px',
    color: '#939699',
    padding: '10px 15px',
    borderBottom: 'none'
  }
}))

export default function RecentProjects ({ anchorEl, handlePopoverClose }) {
  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handlePopoverClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          boxShadow: 'none'
        }
      }}
    >
      <Box className={styles.recentProjects}>
        <TableContainer component={Box} sx={{ maxHeight: '100%' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell
                  align='left'
                  sx={{
                    fontSize: '14px !important',
                    fontWeight: '600 !important',
                    lineHeight: '21px !important'
                  }}
                >Recent Projects
                </StyledTableCell>
                <StyledTableCell align='center'>Category</StyledTableCell>
                <StyledTableCell align='center'>Confidence</StyledTableCell>
                <StyledTableCell align='center'>Status</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {RECENT_PROJECTS.map((row) => (
                <TableRow
                  key={row?._id}
                  sx={{
                    '&:hover': {
                      '& .MuiTableCell-root': {
                        borderTop: '1px solid #939699',
                        borderBottom: '1px solid #939699',
                        borderLeft: 'none',
                        borderRight: 'none'
                      },
                      '& .MuiTableCell-root:first-of-type': {
                        borderLeft: '1px solid #939699',
                        borderTopLeftRadius: '4px',
                        borderBottomLeftRadius: '4px'
                      },
                      '& .MuiTableCell-root:last-of-type': {
                        borderRight: '1px solid #939699',
                        borderTopRightRadius: '4px',
                        borderBottomRightRadius: '4px'
                      }
                    }
                  }}
                >
                  <StyledTableCell scope='row'>
                    {row?.project_name}
                  </StyledTableCell>
                  <StyledTableCell align='center'>
                    {row?.category}
                  </StyledTableCell>
                  <StyledTableCell align='center'>
                    {row?.confidence_level}
                  </StyledTableCell>
                  <StyledTableCell align='center'>
                    <ProjectStatus stage={row?.stage} />
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Popover>
  )
}
