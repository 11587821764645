import React from 'react'
import { Box } from '@mui/material'
import styles from './PromptStarter.module.css'

export default function PromptStarter ({ iconSrc, text, selected, onClick }) {
  return (
    <Box
      className={styles.promptStarterContainer}
      onClick={onClick}
      sx={selected ? { border: '2px solid #4A62D8' } : {}}
    >
      <img src={iconSrc} alt='prompt starter' className={styles.icon} />
      <p className={styles.text}>{text}</p>
    </Box>
  )
}
