import React, { useState } from 'react'
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import { ZoomInMap, ZoomOutMap } from '@mui/icons-material'
import CustomCell from '../../CustomCell/CustomCell'

export default function ScopeSummary ({ budget, mode = null, rows, setRows }) {
  const [mapExpanded, setMapExpanded] = useState(false)
  if (!budget.scopeSummary) return null

  const updateLockStatus = (sectionPath, id, note) => {
    setRows(prev => {
      const newState = JSON.parse(JSON.stringify(prev))
      const section = sectionPath.reduce((acc, part) => acc[part], newState)
      section.forEach(item => {
        if (item.id === id) {
          item.locked.status = !item.locked.status
          item.locked.note = note
        }
      })

      return newState
    })
  }
  const updateHighlightStatus = (sectionPath, id, color) => {
    setRows(prev => {
      const newState = JSON.parse(JSON.stringify(prev))
      const section = sectionPath.reduce((acc, part) => acc[part], newState)
      section.forEach(item => {
        if (item.id === id) {
          item.highlight.status = !item.highlight.status
          item.highlight.color = color
        }
      })

      return newState
    })
  }
  const updateValue = (sectionPath, id, newValue, metadata) => {
    setRows(prev => {
      const newState = JSON.parse(JSON.stringify(prev))
      const section = sectionPath.reduce((acc, part) => acc[part], newState)
      section.forEach(item => {
        if (item.id === id) {
          if (item.surface) item.surface = newValue
          else item.value = newValue
          item.updated = true
          item.metadata = metadata
        }
      })

      return newState
    })
  }

  const handleLockClick = (type, id, index) => {
    if (mode !== 'lock') return

    const typeToPath = {
      areas: ['siteOverallBD', 'areas', index, 'subAreas'],
      metrics: ['scopeMetrics'],
      cost: ['costPerSF'],
      ace: ['ace']
    }

    const path = typeToPath[type]
    if (path) {
      updateLockStatus(path, id)
    }
  }
  const handleHighlightClick = ({ type, color, id, index }) => {
    if (mode !== 'highlight') return

    const typeToPath = {
      areas: ['siteOverallBD', 'areas', index, 'subAreas'],
      metrics: ['scopeMetrics'],
      cost: ['costPerSF'],
      ace: ['ace']
    }

    const path = typeToPath[type]
    if (path) {
      updateHighlightStatus(path, id, color)
    }
  }
  const handleUpdate = ({ type, newValue, metadata, id, index }) => {
    if (!['edit', 'highlight'].includes(mode)) return

    const typeToPath = {
      areas: ['siteOverallBD', 'areas', index, 'subAreas'],
      metrics: ['scopeMetrics'],
      cost: ['costPerSF'],
      ace: ['ace']
    }

    const path = typeToPath[type]
    if (path) {
      updateValue(path, id, newValue, metadata)
    }
  }

  return (
    <TableContainer
      sx={{ height: '100%', backgroundColor: '#f5f8fa' }}
      component={Paper}
    >
      <Box
        display='flex'
        flexDirection='column'
      >
        <Typography variant='h6' gutterBottom>
          Site Overall breakdown
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={mapExpanded ? 12 : 6} order={mapExpanded ? 1 : 2}>
            <Box position='relative'>
              <img
                src='./images/site-plan-2.png'
                alt='site-plan'
                width='100%'
                height='auto'
              />
              <Box
                component='div'
                onClick={() => setMapExpanded(prev => !prev)}
                sx={{
                  position: 'absolute',
                  right: 5,
                  top: 5,
                  cursor: 'pointer'
                }}
              >
                {mapExpanded ? <ZoomInMap /> : <ZoomOutMap />}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={mapExpanded ? 12 : 6} order={mapExpanded ? 2 : 1}>
            <Box>
              <Table
                size='small'
                aria-label='site breakdown table'
              >
                {rows.siteOverallBD.areas.map((area, index) => (
                  <React.Fragment key={area.name + index}>
                    <TableHead>
                      <TableRow>
                        <CustomCell colSpan={3} header content={area.name} />
                        <CustomCell colSpan={2} header bold content='Total' />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {area.subAreas.map((subArea, subIndex) => (
                        <TableRow key={subArea.name + subIndex}>
                          <CustomCell content='' />
                          <CustomCell
                            content={subArea.name}
                            colSpan={2}
                          />
                          <CustomCell
                            content={subArea.surface}
                            metadata={subArea.metadata}
                            updated={subArea.updated}
                            align='right'
                            mode={mode}
                            locked={subArea.locked}
                            highlight={subArea.highlight}
                            handleHighlightClick={(color) =>
                              handleHighlightClick({
                                type: 'areas',
                                color,
                                id: subArea.id,
                                index
                              })}
                            handleUpdate={(value, metadata) =>
                              handleUpdate({
                                type: 'areas',
                                newValue: value,
                                metadata,
                                id: subArea.id,
                                index
                              })}
                            handleLockClick={() =>
                              handleLockClick('areas', subArea.id, index)}
                          />
                          <CustomCell
                            content={area.unit}
                          />
                        </TableRow>
                      ))}
                      <TableRow>
                        <CustomCell content='' />
                        <CustomCell content='' />
                        <CustomCell
                          content='Total'
                          bold
                          header
                        />
                        <CustomCell
                          content={area.surface}
                          bold
                          align='right'
                          mode={mode === 'lock' ? '' : mode}
                        />
                        <CustomCell
                          content={area.unit}
                          bold
                        />
                      </TableRow>
                      {index < rows.siteOverallBD.areas.length - 1 && (
                        <TableRow>
                          <CustomCell colSpan={5} content='' />
                        </TableRow>
                      )}
                      {index === rows.siteOverallBD.areas.length - 1 && (
                        <>
                          <TableRow>
                            <CustomCell colSpan={5} content='' />
                          </TableRow>
                          <TableRow>
                            <CustomCell
                              content='OVERALL SITE'
                              header
                              colSpan={3}
                            />
                            <CustomCell
                              content={184445} bold align='right'
                              mode={mode === 'lock' ? '' : mode}
                            />
                            <CustomCell content='sqft' bold />
                          </TableRow>
                        </>
                      )}
                    </TableBody>
                  </React.Fragment>
                ))}
              </Table>
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={2} mt={2}>
          <Grid item xs={6}>
            <Table aria-label='scope metrics table'>
              <TableHead>
                <TableRow>
                  <CustomCell header content='Scope Metrics' colSpan={4} />
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.scopeMetrics.map((metric, index) => (
                  <TableRow key={metric.name + index}>
                    <CustomCell content='' />
                    <CustomCell
                      content={metric.name}
                    />
                    <CustomCell
                      content={metric.value}
                      metadata={metric.metadata}
                      updated={metric.updated}
                      align='right'
                      mode={mode}
                      locked={metric.locked}
                      highlight={metric.highlight}
                      handleHighlightClick={(color) =>
                        handleHighlightClick({
                          type: 'metrics',
                          color,
                          id: metric.id
                        })}
                      handleUpdate={(value, metadata) =>
                        handleUpdate({
                          type: 'metrics',
                          newValue: value,
                          metadata,
                          id: metric.id
                        })}
                      handleLockClick={() =>
                        handleLockClick('metrics', metric.id)}
                    />
                    <CustomCell content={metric.unit} />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          <Grid item xs={6}>
            <Table aria-label='CPSF table'>
              <TableHead>
                <TableRow>
                  <CustomCell
                    header
                    content='Cost per Square Foot'
                    colSpan={2}
                  />
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.costPerSF.map((cost, index) => (
                  <TableRow key={cost.name + index}>
                    <CustomCell content={cost.name} />
                    <CustomCell
                      content={cost.value}
                      metadata={cost.metadata}
                      updated={cost.updated}
                      align='right'
                      mode={mode}
                      locked={cost.locked}
                      highlight={cost.highlight}
                      handleHighlightClick={(color) =>
                        handleHighlightClick({
                          type: 'cost',
                          color,
                          id: cost.id
                        })}
                      handleUpdate={(value, metadata) =>
                        handleUpdate({
                          type: 'cost',
                          newValue: value,
                          metadata,
                          id: cost.id
                        })}
                      handleLockClick={() =>
                        handleLockClick('cost', cost.id)}
                    />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>

        <Box mt={2}>
          <Table aria-label='ace table'>
            <TableHead>
              <TableRow>
                <CustomCell
                  header
                  content='ACE - Show & Ride Box Breakdown'
                  colSpan={5}
                />
                <CustomCell header content='% of Show Area' align='center' />
                <CustomCell header content='Direct Costs' align='center' />
                <CustomCell header content='Allocated' align='center' />
                <CustomCell header content='Subtotal' align='center' />
                <CustomCell header content='$/sf' align='center' />
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.ace.map((ace, index) => (
                <TableRow key={ace.id + index}>
                  <CustomCell content='' />
                  <CustomCell content={ace.id} />
                  <CustomCell content={ace.name} />
                  <CustomCell
                    content={ace.surface}
                    metadata={ace.metadata}
                    updated={ace.updated}
                    align='right'
                    mode={mode}
                    locked={ace.locked}
                    highlight={ace.highlight}
                    handleHighlightClick={(color) =>
                      handleHighlightClick({
                        type: 'ace',
                        color,
                        id: ace.id
                      })}
                    handleUpdate={(value, metadata) =>
                      handleUpdate({
                        type: 'ace',
                        newValue: value,
                        metadata,
                        id: ace.id,
                        index
                      })}
                    handleLockClick={() =>
                      handleLockClick('ace', ace.id)}
                  />
                  <CustomCell content={ace.unit} />
                  <CustomCell
                    content={(100 * ace.percentageOfTotal)}
                    suffix='%'
                    align='right'
                    mode={['lock', 'highlight'].includes(mode) ? '' : mode}
                  />
                  <CustomCell
                    content={ace.directCosts} align='right'
                    mode={['lock', 'highlight'].includes(mode) ? '' : mode}
                  />
                  <CustomCell
                    content={ace.allocated ?? '-'} align='right'
                    mode={['lock', 'highlight'].includes(mode) ? '' : mode}
                  />
                  <CustomCell
                    content={ace.subtotal ?? '-'} align='right'
                    mode={['lock', 'highlight'].includes(mode) ? '' : mode}
                  />
                  <CustomCell
                    content={ace.costPerSF ?? '-'} align='right'
                    mode={['lock', 'highlight'].includes(mode) ? '' : mode}
                  />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Box>
    </TableContainer>
  )
}
