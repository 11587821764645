import React, { useState } from 'react'
import { Box, Container, Typography } from '@mui/material'
import Sidebar from '../../components/Sidebar/Sidebar'
import UserDetails from '../../components/Settings/UserDetails'

export default function Settings () {
  const [sidebarOpen, setSidebarOpen] = useState(false)

  return (
    <Box
      sx={{
        boxSizing: 'border-box'
      }}
    >
      <Sidebar open={sidebarOpen} setOpen={setSidebarOpen} />
      <Container sx={{ mt: 3 }}>
        <Typography variant='h5'>
          Settings
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <UserDetails />
        </Box>
      </Container>
    </Box>
  )
}
