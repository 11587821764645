import React from 'react'
import { Box } from '@mui/material'
import styles from './ProjectStatus.module.css'

export default function ProjectStatus ({ stage }) {
  const getStatusBackground = (stage) => {
    if (stage === 'production') {
      return '#4A62D8'
    }
    if (stage === 'stage3') {
      return '#514B78'
    }
    if (stage === 'stage2') {
      return '#746BAB'
    }
    if (stage === 'stage1') {
      return '#968BDE'
    }
    if (stage === 'inactive') {
      return '#939699'
    }
  }

  return (
    <Box
      className={styles.projectStatus}
      sx={{ backgroundColor: getStatusBackground(stage) }}
    >
      {stage}
    </Box>
  )
}
