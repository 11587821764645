import React from 'react'
import { Box, Typography, Button } from '@mui/material'
import { Link } from 'react-router-dom'

export default function NotFound () {
  return (
    <Box sx={{
      height: 'calc(100vh - 60px)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }}
    >
      <Typography variant='h3'>
        Nothing to see here!
      </Typography>
      <Button
        component={Link}
        to='/'
        variant='containedPrimary'
        color='primary'
        sx={{ mt: 2 }}
      >
        Go to Home Page
      </Button>
    </Box>
  )
}
