import html2canvas from 'html2canvas'
import JsPDF from 'jspdf'

export const fullEstimateAdapt = (data) => {
  const convertedFullEstimate = data?.map((el) => {
    return {
      'Cost Code': el?.costCode,
      Activities: el?.activity,
      Labor: el?.laborCosts,
      Material: el?.materialCosts,
      Fixed: el?.fixedCosts,
      Budget: el?.budget,
      'Actual Cost': el?.actualCosts,
      'Under/Over': el?.underOver,
      Delta: el?.deltaPercent
    }
  })

  return convertedFullEstimate
}

const getNotes = (cell) => {
  if (cell.metadata?.notes === undefined) return ''
  const note = 'Note: ' + (cell.metadata?.notes ?? '') + '\n'
  if (note) {
    cell.note = note
  }
  return note
}

export const convertFullEstimateToCSV = (fullEstimates) => {
  let str = ''
  fullEstimates.forEach((singleEstimateTable) => {
    const headers = Object.keys(singleEstimateTable[0]).map((key) => key)
      .join(',')
    str += headers + '\r\n'

    for (let i = 0; i < singleEstimateTable.length; i++) {
      let line = ''
      for (const key in singleEstimateTable[i]) {
        if (Object.prototype.hasOwnProperty.call(singleEstimateTable[i], key)) {
          if (typeof singleEstimateTable[i][key] === 'object' &&
            !Array.isArray(singleEstimateTable[i][key])) {
            line += singleEstimateTable[i][key].value + ','
          } else {
            line += singleEstimateTable[i][key] + ','
          }
        }
      }
      line = line.slice(0, -1)
      str += line + '\r\n'
    }
    str += '\r\n\n\n'
  })
  return str
}

export const convertScopeOfWorkToCSV = (scopeOfWorkItems) => {
  let str = ''
  // Ace
  str += 'Ace \r\n\n'
  scopeOfWorkItems.ace.forEach(item => {
    const row = [`"${item.id}"`]
    row.push(`"${item?.name}"`)
    row.push(`"${item?.surface}"`)
    row.push(`"${item?.unit}"`)
    row.push(`"${(item?.surface * item?.percentageOfTotal) / 100}%"`)
    row.push(`"${item?.directCosts}%"`)
    str += row.join(',') + '\n'
  })

  // Cost Per Square Feet
  str += 'Cost Per Square Feet \r\n\n'
  scopeOfWorkItems.costPerSF.forEach(item => {
    const row = [`${item.name}`]
    row.push(`"${item?.value}"`)
    str += row.join(',') + '\n  '
  })

  // Scope Metrics
  str += 'Scope Metrics \r\n\n'
  scopeOfWorkItems.scopeMetrics.forEach(item => {
    const row = [`${item.name}`]
    row.push(`"${item?.value}"`)
    row.push(`"${item?.unit}"`)
    str += row.join(',') + '\n  '
  })

  // Site overall breakdown
  str += 'Site overall breakdown \r\n\n'
  scopeOfWorkItems.siteOverallBD?.areas?.forEach(item => {
    str += `"${item?.name}" \r\n`
    item.subAreas?.forEach(subArea => {
      const row = [`"${subArea.name}"`]
      row.push(`"${subArea.surface}"`)
      row.push(`"${scopeOfWorkItems.siteOverallBD.unit}"`)
      str += row.join(',') + '\n'
    })
  })
  return str
}

export const convertDOWSummaryToCSV = (dowSummaryItems) => {
  let str = ''
  dowSummaryItems.forEach(item => {
    if (item.type === 'title') {
      str += `"","${item?.name}"\n`
    } else {
      const row = item.type === 'subtotal'
        ? [`"","${item.name}"`]
        : [`"${item.num}","${item.name}"`]
      item?.prior.forEach(cost => {
        row.push(`"${cost.value}"`)
      })
      item?.current.forEach(cost => {
        row.push(`"${cost.value}"`)
      })
      row.push(item.valueChange)
      row.push(item.percentChange)
      str += row.join(',') + '\n'
    }
  })
  return str
}

export const convertGrandSummaryToCSV = (grandSummaryItems) => {
  return grandSummaryItems.map(item => {
    if (item.type === 'header') {
      return `"${item.category}"\n`
    }

    if (item?.costs !== undefined) {
      const row = [
        `"${item.category}"`,
        ...item.costs.map(cost => `"${cost.value}"`)
      ]
      return row.join(',') + '\n'
    }

    return ''
  }).join('')
}

export const exportFullEstimateToExcel = async (ExcelJS, saveAs, data) => {
  const workbook = new ExcelJS.Workbook()
  const worksheet = workbook.addWorksheet('Sheet1')
  const convertColor = (color) => {
    if (!color) return null
    return 'FF' + color.slice(1)
  }

  data.forEach((table, tableIndex) => {
    const headers = Object.keys(table[0])
    worksheet.addRow(headers)

    table.forEach((el) => {
      const rowValues = headers.map(header => {
        const note = getNotes(el[header])
        return {
          value: el[header].value || el[header],
          style: el[header].highlight && el[header].highlight.status
            ? {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: convertColor(el[header].highlight.color) }
              }
            : null,
          note
        }
      })

      const row = worksheet.addRow(rowValues.map(cell => cell.value))
      rowValues.forEach((cell, idx) => {
        row.getCell(idx + 1).alignment = { horizontal: 'center' }
        if (cell.style) {
          row.getCell(idx + 1).fill = cell.style
        }
        if (cell.note) {
          row.getCell(idx + 1).note = cell.note
        }
      })
    })

    worksheet.addRow()
  })

  const buffer = await workbook.xlsx.writeBuffer()
  const blob = new Blob([buffer], { type: 'application/octet-stream' })
  saveAs(blob, `full_estimate-${new Date().toISOString()}.xlsx`)
}

export const exportGrandSummaryToExcel = (ExcelJS, saveAs, data) => {
  const workbook = new ExcelJS.Workbook()
  const worksheet = workbook.addWorksheet('Report')

  const headers = [
    'Category',
    'Area 1',
    'Area 2',
    'Area 3',
    'Area 4',
    'Area 5',
    'Total Overall'
  ]
  const headerRow = worksheet.addRow(headers)
  headerRow.font = { bold: true }

  const convertColor = (color) => {
    return color ? 'FF' + color.slice(1) : 'FFFFFFFF'
  }

  data.forEach((item, rowIndex) => {
    const row = [item.category]
    if (item.type === 'title' || item.type === 'header') {
      const mergeRow = worksheet.addRow(row)
      const { lastRow } = worksheet
      worksheet.mergeCells(`A${lastRow.number}:G${lastRow.number}`)
      mergeRow.eachCell((cell) => {
        cell.font = { bold: true }
        cell.alignment = { horizontal: 'center' }
      })
    } else if (item.costs) {
      item.costs.forEach(cost => row.push(cost.value))
      const dataRow = worksheet.addRow(row)
      dataRow.eachCell((cell, colIndex) => {
        cell.alignment = { horizontal: 'center' }
        if (item.costs[colIndex - 2] && item.costs[colIndex - 2].highlight &&
          item.costs[colIndex - 2].highlight.color) {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
              argb: convertColor(item.costs[colIndex - 2].highlight.color)
            }
          }
        }
        if (item.costs[colIndex - 2]) {
          const note = getNotes(item.costs[colIndex - 2])
          if (note) {
            cell.note = note
          }
        }
      })
    }
  })

  workbook.xlsx.writeBuffer().then(buffer => {
    const blob = new Blob([buffer], { type: 'application/octet-stream' })
    saveAs(blob, `grand_summary-${new Date().toISOString()}.xlsx`)
  })
}

export const exportDowSummaryToExcel = (ExcelJS, saveAs, data) => {
  const wb = new ExcelJS.Workbook()
  const ws = wb.addWorksheet('Project Costs')

  const wsData = [
    [
      '',
      '',
      'PRIOR',
      '',
      '',
      '',
      '',
      '',
      '',
      'CURRENT',
      '',
      '',
      '',
      '',
      '',
      ''
    ],
    [
      '',
      '',
      'Project Name and Date',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      'Gate 1 Estimate (May 16, 2024)',
      '',
      '',
      '',
      '',
      ''
    ],
    [
      'Hard Costs',
      '',
      'Area 1',
      'Area 2',
      'Area 3',
      'Area 4',
      'Area 5',
      '',
      'Area 1',
      'Area 2',
      'Area 3',
      'Area 4',
      'Area 5',
      ''
    ]
  ]

  // Convert HTML color code to Excel format (ARGB)
  const convertColor = (color) => {
    return color ? 'FF' + color.slice(1) : 'FFFFFFFF'
  }

  data.forEach(item => {
    if (item.type === 'title') {
      wsData.push([item.name])
    } else {
      const row = [item.num, item.name]
      item.prior.forEach((cost, idx) => {
        const note = getNotes(cost)
        const cellData = {
          value: cost.value,
          style: cost.highlight && cost.highlight.color
            ? {
                fill: {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: convertColor(cost.highlight.color) }
                }
              }
            : null,
          note
        }
        row.push(cellData)
      })
      row.push({ value: item.valueChange, style: null })
      item.current.forEach((cost, idx) => {
        const note = getNotes(cost)
        const cellData = {
          value: cost.value,
          style: cost.highlight && cost.highlight.color
            ? {
                fill: {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: convertColor(cost.highlight.color) }
                }
              }
            : null,
          note
        }
        row.push(cellData)
      })
      row.push({ value: item.valueChange, style: null })

      wsData.push(row)
    }
  })

  // Add data to worksheet
  wsData.forEach((rowData, rowIndex) => {
    const row = ws.addRow(
      rowData.map(cell => cell?.value !== undefined ? cell.value : cell))
    rowData.forEach((cell, colIndex) => {
      row.getCell(colIndex + 1).alignment = { horizontal: 'center' }
      if (cell?.style && cell.style.fill) {
        row.getCell(colIndex + 1).fill = cell.style.fill
      }
      if (cell?.note) {
        row.getCell(colIndex + 1).note = cell.note
      }
    })
  })

  // Merging cells to create the desired header structure
  ws.mergeCells('C1:I1')
  ws.mergeCells('J1:P1')
  ws.mergeCells('C2:H2')
  ws.mergeCells('J2:O2')

  // Styling (optional)
  const headerStyle = {
    font: { bold: true, color: { argb: 'FFFFFFFF' } },
    fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF4F81BD' } },
    alignment: { horizontal: 'center', vertical: 'middle' }
  }

  // Apply styles to header rows
  const applyStyles = (startRow, endRow, startCol, endCol) => {
    for (let row = startRow; row <= endRow; row++) {
      for (let col = startCol; col <= endCol; col++) {
        const cell = ws.getCell(row, col)
        cell.font = headerStyle.font
        cell.fill = headerStyle.fill
        cell.alignment = headerStyle.alignment
      }
    }
  }

  // Apply styles to header rows (first three rows)
  applyStyles(1, 1, 3, 9) // First merged cell
  applyStyles(1, 1, 10, 16) // Second merged cell
  applyStyles(2, 2, 3, 8) // Third merged cell
  applyStyles(2, 2, 10, 15) // Fourth merged cell
  applyStyles(3, 3, 1, 16) // Third row

  // Write the workbook to a buffer and use file-saver to save the file
  wb.xlsx.writeBuffer().then(buffer => {
    const blob = new Blob([buffer], { type: 'application/octet-stream' })
    saveAs(blob, `dow_summary-${new Date().toISOString()}.xlsx`)
  })
}

export const exportScopeOfWorkToExcel = async (
  ExcelJS,
  saveAs,
  data,
  outputFileName
) => {
  const { siteOverallBD, scopeMetrics, costPerSF, ace } = data

  const base64 = await fetchAndConvertImageToBase64(
    'http://localhost:3000/images/site-plan-2.png'
  )

  // Prepare data for each sheet
  const siteOverallBDSheet = []
  const scopeMetricsSheet = []
  const costPerSFSheet = []
  const aceSheet = []

  // Populate siteOverallBDSheet
  siteOverallBD.areas.forEach(area => {
    siteOverallBDSheet.push({
      AreaName: area.name,
      Surface: area.surface,
      Unit: area.unit
    })
    area.subAreas.forEach(subArea => {
      siteOverallBDSheet.push({
        SubAreaName: subArea.name,
        SubAreaSurface: subArea.surface,
        SubAreaUnit: area.unit,
        highlight: subArea.highlight,
        locked: subArea.locked,
        updated: subArea.updated,
        metadata: subArea.metadata
      })
    })
  })

  // Populate scopeMetricsSheet
  scopeMetrics.forEach(metric => {
    scopeMetricsSheet.push({
      MetricName: metric.name,
      Value: metric.value,
      Unit: metric.unit,
      highlight: metric.highlight,
      locked: metric.locked,
      updated: metric.updated,
      metadata: metric.metadata
    })
  })

  // Populate costPerSFSheet
  costPerSF.forEach(cost => {
    costPerSFSheet.push({
      CostName: cost.name,
      Value: cost.value,
      Unit: cost.unit,
      highlight: cost.highlight,
      locked: cost.locked,
      updated: cost.updated,
      metadata: cost.metadata
    })
  })

  // Populate aceSheet
  ace.forEach(aceItem => {
    aceSheet.push({
      ACEName: aceItem.name,
      Surface: aceItem.surface,
      Unit: aceItem.unit,
      PercentageOfTotal: aceItem.percentageOfTotal,
      DirectCosts: aceItem.directCosts,
      highlight: aceItem.highlight,
      locked: aceItem.locked,
      updated: aceItem.updated,
      metadata: aceItem.metadata
    })
  })

  const convertColor = (color) => {
    return color ? 'FF' + color.slice(1) : 'FFFFFFFF'
  }
  // Create a new workbook and add worksheets
  const workbook = new ExcelJS.Workbook()

  // Add siteOverallBD sheet
  const siteOverallBDWorksheet = workbook.addWorksheet('Site Overall BD')
  siteOverallBDWorksheet.columns = [
    { header: 'Area Name', key: 'AreaName', width: 30 },
    { header: 'Surface', key: 'Surface', width: 15 },
    { header: 'Unit', key: 'Unit', width: 10 },
    { header: 'Sub Area Name', key: 'SubAreaName', width: 30 },
    { header: 'Sub Area Surface', key: 'SubAreaSurface', width: 20 },
    { header: 'Sub Area Unit', key: 'SubAreaUnit', width: 15 }
  ]
  siteOverallBDSheet.forEach((rowData, colIndex) => {
    const row = siteOverallBDWorksheet.addRow(rowData)
    row.eachCell((cell, colIndex) => {
      cell.alignment = { horizontal: 'center' }
    })
    if (rowData.highlight && rowData.highlight.color) {
      row.getCell(colIndex + 2).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: convertColor(rowData.highlight.color) }

      }
    }
    const note = getNotes(rowData)
    if (note) {
      row.getCell(colIndex + 2).note = note
    }
  })

  // Add image to the first sheet
  const imageId = workbook.addImage({
    base64,
    extension: 'png'
  })
  siteOverallBDWorksheet.addImage(imageId, {
    tl: { col: 0, row: siteOverallBDSheet.length + 2 },
    ext: { width: 500, height: 300 }
  })

  // Add scopeMetrics sheet
  const scopeMetricsWorksheet = workbook.addWorksheet('Scope Metrics')
  scopeMetricsWorksheet.columns = [
    { header: 'Metric Name', key: 'MetricName', width: 30 },
    { header: 'Value', key: 'Value', width: 10 },
    { header: 'Unit', key: 'Unit', width: 10 }
  ]
  scopeMetricsSheet.forEach((rowData, colIndex) => {
    const row = scopeMetricsWorksheet.addRow(rowData)
    row.eachCell((cell, colIndex) => {
      cell.alignment = { horizontal: 'center' }
      if (colIndex === 2 && rowData.highlight &&
        rowData.highlight.color) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: convertColor(rowData.highlight.color)
          }
        }
        const note = getNotes(rowData)
        if (colIndex === 2 && note) {
          cell.note = note
        }
      }
    })
  })

  // Add costPerSF sheet
  const costPerSFWorksheet = workbook.addWorksheet('Cost Per SF')
  costPerSFWorksheet.columns = [
    { header: 'Cost Name', key: 'CostName', width: 30 },
    { header: 'Value', key: 'Value', width: 10 },
    { header: 'Unit', key: 'Unit', width: 10 }
  ]
  costPerSFSheet.forEach((rowData, colIndex) => {
    const row = costPerSFWorksheet.addRow(rowData)
    row.eachCell((cell, colIndex) => {
      cell.alignment = { horizontal: 'center' }
      if (colIndex === 2 && rowData.highlight &&
        rowData.highlight.color) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: convertColor(rowData.highlight.color)
          }
        }
        const note = getNotes(rowData)
        if (colIndex === 2 && note) {
          cell.note = note
        }
      }
    })
  })

  // Add ace sheet
  const aceWorksheet = workbook.addWorksheet('ACE')
  aceWorksheet.columns = [
    { header: 'ACE Name', key: 'ACEName', width: 30 },
    { header: 'Surface', key: 'Surface', width: 10 },
    { header: 'Unit', key: 'Unit', width: 10 },
    { header: 'Percentage Of Total', key: 'PercentageOfTotal', width: 20 },
    { header: 'Direct Costs', key: 'DirectCosts', width: 15 }
  ]
  aceSheet.forEach((rowData, colIndex) => {
    const row = aceWorksheet.addRow(rowData)
    row.eachCell((cell, colIndex) => {
      cell.alignment = { horizontal: 'center' }
      if (colIndex === 2 && rowData.highlight &&
        rowData.highlight.color) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: convertColor(rowData.highlight.color)
          }
        }
        const note = getNotes(rowData)
        if (colIndex === 2 && note) {
          cell.note = note
        }
      }
    })
  })

  // Write the workbook to a buffer and use file-saver to save the file
  const buffer = await workbook.xlsx.writeBuffer()
  const blob = new Blob([buffer], { type: 'application/octet-stream' })
  saveAs(blob, outputFileName)
}

const fetchAndConvertImageToBase64 = async (imagePath) => {
  const response = await fetch(imagePath)
  const blob = await response.blob()
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onloadend = () =>
      resolve(reader.result.split(',')[1]) // Remove the data URL prefix
    reader.onerror = (error) => reject(error)
  })
}

const convertFullEstimatesToTable = (data) => {
  return data.map((item) => {
    let table =
      '<table border="1" style="width: 100%; border-collapse: collapse;">'
    table +=
      '<tr style="text-align: center; background: #4A62D8; color: white;">'
    Object.keys(item[0]).forEach((key) => {
      table += `<th>${key}</th>`
    })
    table += '</tr>'

    item.forEach((row) => {
      table += '<tr>'
      Object.keys(row).forEach((key) => {
        if (typeof row[key] === 'object' &&
          row[key] !== null &&
          'value' in row[key]) {
          const { highlight, value } = row[key] || {}
          table += `<td style="background: ${highlight?.color}">${value}</td>`
        } else {
          table += `<td>${row[key]}</td>`
        }
      })
      table += '</tr>'
    })

    table += '</table><br>'
    return table
  }).join('')
}

const convertGrandSummaryToTable = (data) => {
  let table =
    '<table border="1" style="width: 100%; border-collapse: collapse;">'
  table += '<tr>'
  const tableHeaders =
    ['', 'Area1', 'Area1', 'Area1', 'Area1', 'Area1', 'Total Overall']
  tableHeaders.forEach((key) => {
    table +=
      `<th
        style="text-align: center; background: #4A62D8; color: white;"
      >${key}</th>`
  })
  table += '</tr>'

  data.forEach((row) => {
    table += '<tr>'
    if (row.type === 'header') {
      table += `<td>${row.category}</td>`
    } else if (row.costs !== undefined) {
      table += `<td>${row.category}</td>`
      row.costs.forEach(el => {
        const { highlight, value } = el
        table += `<td style="background: ${highlight?.color}">${value}</td>`
      })
    }
    table += '</tr>'
  })

  table += '</table>'
  return table
}

const convertDowToTable = (data) => {
  let table =
    '<table border="1" style="width: 100%; border-collapse: collapse;">'
  table += '<tr>'
  table += `<tr
              style="text-align: center; background: #4A62D8; color: white;"
            >
            <th
              style="text-align: center; background: #4A62D8; color: white;"
              class="merged-cell"
              colspan="2"
              ></th>
            <th
              style="text-align: center; background: #4A62D8; color: white;"
              class="merged-cell"
              colspan="6"
              >PRIOR</th>
            <th
              style="text-align: center; background: #4A62D8; color: white;"
              class="merged-cell"
              colspan="6"
              >CURRENT</th>
            <th
              style="text-align: center; background: #4A62D8; color: white;"
              class="merged-cell"
              colspan="1"
              >Value Change (increase/decrease)</th>
            <th
              style="text-align: center; background: #4A62D8; color: white;"
              class="merged-cell"
              colspan="1"
              >% change</th>
        </tr>
        <tr style="text-align: center; background: #4A62D8; color: white;">
            <th class="header" colspan="2"></th>
            <th class="header" colspan="6">Project Name and Date</th>
           
            <th class="header" colspan="6">Gate 1 Estimate (May 16, 2024)</th>
            <th class="header" colspan="1"></th>
            <th class="header" colspan="1"></th>
        </tr>
        <tr style="text-align: center; background: #4A62D8; color: white;">
            <td colspan="2"></td>
            <td colspan="1" class="header">Area 1</td>
            <td colspan="1" class="header">Area 2</td>
            <td colspan="1" class="header">Area 3</td>
            <td colspan="1" class="header">Area 4</td>
            <td colspan="1" class="header">Area 5</td>
            <td colspan="1" class="header">Total</td>
            <td colspan="1" class="header">Area 1</td>
            <td colspan="1" class="header">Area 2</td>
            <td colspan="1" class="header">Area 3</td>
            <td colspan="1" class="header">Area 4</td>
            <td colspan="1" class="header">Area 5</td>
            <td colspan="1" class="header">Total</td>
            <td colspan="1" class="header"></td>
            <td colspan="1" class="header"></td>
        </tr>`

  data.forEach(row => {
    table += '<tr>'
    if (row.type === 'title') {
      table += `<td>${row.name}</td>`
    } else {
      table += `<td>${row.num || ''}</td>`
      table += `<td>${row.name}</td>`

      row.prior.forEach((singlePrior) => {
        const { highlight, value } = singlePrior
        table += `<td style="background: ${highlight?.color}">${value}</td>`
      })
      row.current.forEach((singlePrior) => {
        const { highlight, value } = singlePrior
        table += `<td style="background: ${highlight?.color}">${value}</td>`
      })

      table += `<td>${row.valueChange}</td>`
      table += `<td>${row.percentChange}</td>`
    }

    table += '</tr>'
  })
  table += '</table>'
  return table
}

const convertScopeOfWorkToTable = (data) => {
  const siteOverallBd = () => {
    let table =
      '<table border="1" style="width: 100%; border-collapse: collapse;">'
    table += `<thead>
      <tr>
        <td 
          style="text-align: center; background: #4A62D8; color: white;"
          colspan="10"
        >Site Overall Breakdown</td>
      </tr>
    </thead>`
    data.siteOverallBD.areas.forEach((item) => {
      table += '<tr>'
      table +=
        `<td
          style="text-align: center; background: #4A62D8; color: white;"
          colspan="6">${item.name}</td>`
      table +=
        `<td 
          style="text-align: center; background: #4A62D8; color: white;"
          colspan="6">Total</td>`
      table += '</tr>'
      item.subAreas.forEach((subArea) => {
        const { highlight, name, surface } = subArea
        const style = `text-align: center; background: ${highlight?.color}`
        table += '<tr>'
        table +=
          `<td style="text-align: center;" colspan="6" >${name}</td>`
        table +=
          `<td style="${style}" colspan="3" >${surface}</td>`
        table +=
          '<td style="text-align: center;" colspan="3" >sqft</td>'
        table += '</tr>'
      })
    })
    table += '</table>'
    return table
  }

  const scopeMetrics = () => {
    let table =
      '<table border="1" style="width: 100%; border-collapse: collapse;">'
    table += `<thead>
      <tr>
        <td
          style="text-align: center; background: #4A62D8; color: white;"
          colspan="10"
        >Scope Metrics</td>
      </tr>
    </thead>`
    data.scopeMetrics.forEach((item) => {
      const style = `text-align: center; background: ${item.highlight?.color}`
      table += '<tr>'
      table += `<td colspan="6">${item.name}</td>`
      table += `<td colspan="2" style="${style}">${item.value}</td>`
      table += `<td colspan="2" style="text-align: center;">${item.unit}</td>`
      table += '</tr>'
    })
    table += '</table>'
    return table
  }

  const costPerSqFoot = () => {
    let table =
      '<table border="1" style="width: 100%; border-collapse: collapse;">'
    table += `<thead>
      <tr>
        <td
          style="text-align: center; background: #4A62D8; color: white;"
          colspan="10"
        >Cost per Square Foot</td>
      </tr>
    </thead>`
    data.costPerSF.forEach((item) => {
      const style = `text-align: center; background: ${item.highlight?.color}`
      table += '<tr>'
      table += `<td>${item.name}</td>`
      table += `<td style="${style}">${item.value}</td>`
      table += '</tr>'
    })
    table += '</table>'
    return table
  }

  const ace = () => {
    let table =
      '<table border="1" style="width: 100%; border-collapse: collapse;">'
    table += `<thead>
      <tr>
        <td 
          style="text-align: center; background: #4A62D8; color: white;"
          colspan="6"
        >ACE - Show & Ride Box Breakdown</td>
        <td
          style="text-align: center; background: #4A62D8; color: white;"
          colspan="1"
          >% of Show Area</td>
        <td
          style="text-align: center; background: #4A62D8; color: white;"
          colspan="1"
          >Direct Costs</td>
        <td
          style="text-align: center; background: #4A62D8; color: white;"
          colspan="1"
          >Allocated</td>
        <td
          style="text-align: center; background: #4A62D8; color: white;"
          colspan="1"
          >Subtotal</td>
        <td
          style="text-align: center; background: #4A62D8; color: white;"
          colspan="1"
          >$/sf</td>
      </tr>
    </thead>`
    data.ace.forEach((item) => {
      const style = `text-align: center; background: ${item.highlight?.color}`
      table +=
        '<tr>'
      table +=
        `<td style="text-align: center;" colspan="1" >${item.id}</td>`
      table +=
        `<td style="text-align: center;" colspan="2" >${item.name}</td>`
      table +=
        `<td style="${style}" colspan="2" >${item.surface}</td>`
      table +=
        `<td style="text-align: center;" colspan="1" >${item.unit}</td>`
      table +=
        `<td style="text-align: center;" colspan="1">
          ${item.percentageOfTotal}
        </td>`
      table +=
        `<td style="text-align: center;" colspan="1">${item.directCosts}</td>`
      table +=
        `<td style="text-align: center;" colspan="1">${item.allocated}</td>`
      table +=
        `<td style="text-align: center;" colspan="1">${item.subtotal}</td>`
      table +=
        `<td style="text-align: center;" colspan="1">${item.costPerSf}</td>`
      table +=
        '</tr>'
    })
    table += '</table>'
    return table
  }

  return [
    siteOverallBd(),
    scopeMetrics(),
    costPerSqFoot(),
    ace()
  ].join('<br>')
}

export const exportToPdf = async (data, view) => {
  const viewConverters = {
    'Full Estimate': convertFullEstimatesToTable,
    'Grand Summary': convertGrandSummaryToTable,
    'DOW Summary': convertDowToTable,
    'Scope of Work': convertScopeOfWorkToTable
  }
  const table = viewConverters[view](data)

  const container = document.createElement('div')
  container.style.width = '100%'
  container.innerHTML = table
  document.body.appendChild(container)

  const canvas = await html2canvas(container, {
    scale: 1
  })

  const imgData = canvas.toDataURL('image/png')
  const pdf = new JsPDF('p', 'pt', 'a4') // 'p' for portrait, 'pt' for points, 'a4' page size

  const margin = 20 // Margin in points
  const pageWidth = pdf.internal.pageSize.width - 2 * margin
  const pageHeight = pdf.internal.pageSize.height - 2 * margin

  const imgWidth = pageWidth
  const imgHeight = canvas.height * imgWidth / canvas.width
  let position = margin

  // Add the image to the page
  pdf.addImage(imgData, 'PNG', margin, position, imgWidth, imgHeight)
  let heightLeft = imgHeight - pageHeight

  while (heightLeft >= 0) {
    position = margin - heightLeft
    pdf.addPage()
    pdf.addImage(imgData, 'PNG', margin, position, imgWidth, imgHeight)
    heightLeft -= pageHeight
  }

  pdf.save(
    `${view.toLowerCase().replace(' ', '_')}-${new Date().toISOString()}.pdf`)
  document.body.removeChild(container)
}
