import React, { useEffect, useState } from 'react'
import { Box, Drawer, Typography } from '@mui/material'
import styles from './Sidebar.module.css'
import TimelineList from './TimelineList'
import { getProjects } from '../../api/projectsService'
import { filterProjects } from '../../utils/projects'

export default function Sidebar ({ project, open, setOpen }) {
  const [projects, setProjects] = useState([])
  const [filtredProjects, setFilteredProjects] = useState({
    recentProjects: [],
    last30DaysProjects: [],
    thisYearProjects: [],
    allTimeProjects: []
  })
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [search, setSearch] = useState('')

  const toggleDrawer = () => (e) => {
    if (e.type === 'keydown' && (e.key === 'Tab' || e.key === 'Shift')) {
      return
    }
    setOpen(!open)
  }

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const res = await getProjects()
        setProjects(res)
        setFilteredProjects(filterProjects(res))
      } catch (err) {
        setError(err)
      } finally {
        setLoading(false)
      }
    }
    fetchProjects()
  }, [project])

  useEffect(() => {
    setFilteredProjects(filterProjects(projects, search))
  }, [projects, search])
  return (
    <>
      <Drawer
        anchor='left'
        open={open}
        onClose={toggleDrawer(false)}
        sx={{
          '& .MuiDrawer-paper': {
            boxShadow: 'none',
            borderRight: 'none'
          },
          '& .MuiBackdrop-root': {
            backgroundColor: 'transparent'
          }
        }}
      >
        <Box
          className={styles.sidebarContainer}
        >
          <Box className={styles.logoContainer}>
            <img
              src='/images/ai_inkwell_logo.png'
              alt='logo'
              className={styles.aiInkwellLogo}
            />
          </Box>
          <Box className={styles.searchbar}>
            <img
              src='/images/icons/search-icon.svg'
              alt='search'
              className={styles.searchIcon}
            />
            <input
              type='text'
              className={styles.searchInput}
              placeholder='Search Anything'
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Box>
          <Box className={styles.timelineContainer}>
            {!loading && (
              <Box className={styles.timeline}>
                <TimelineList
                  toggleDrawer={toggleDrawer}
                  title='Recent Projects'
                  projects={filtredProjects.recentProjects}
                />
                {filtredProjects.last30DaysProjects.length > 0 && (
                  <TimelineList
                    toggleDrawer={toggleDrawer}
                    title='Last 30 Days'
                    projects={filtredProjects.last30DaysProjects}
                  />
                )}
                {filtredProjects.thisYearProjects.length > 0 && (
                  <TimelineList
                    toggleDrawer={toggleDrawer}
                    title='This Year'
                    projects={filtredProjects.thisYearProjects}
                  />
                )}
                {filtredProjects.allTimeProjects.length > 0 && (
                  <TimelineList
                    toggleDrawer={toggleDrawer}
                    title='All time'
                    projects={filtredProjects.allTimeProjects}
                  />
                )}
              </Box>
            )}
            {error && <Typography color='error'>{error}</Typography>}
          </Box>
          <Box className={styles.footer}>
            <img
              src='/images/icons/settings-icon.svg'
              alt='settings'
              className={styles.settingsIcon}
            />
          </Box>
        </Box>
      </Drawer>
      <Box
        sx={{
          position: 'fixed',
          left: open ? 250 : 0,
          top: '50%',
          marginLeft: open ? '0px' : '16px'
        }}
        className={styles.drawerArrowContainer}
        onClick={toggleDrawer(prev => !prev)}
      >
        <img
          src='/images/icons/arrow.svg'
          alt='drawer'
          className={styles.drawerArrow}
          style={{
            transform: open ? 'rotate(180deg)' : 'none'
          }}
        />
      </Box>
    </>
  )
}
