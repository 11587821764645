import React from 'react'
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material'
import CustomCell from '../../CustomCell/CustomCell'

export default function DOWSummary ({ budget, mode = null, rows, setRows }) {
  const { dowSummary } = budget || {}
  if (!dowSummary) return null

  const handleLockClick = (type, rowId, cellId) => {
    if (mode !== 'lock') return

    setRows(prevRows => prevRows.map(row => {
      if (row.id === rowId) {
        const updatedPrior = row.prior.map(cost => {
          if (type === 'row' || cost.id === cellId) {
            return {
              ...cost,
              locked: {
                ...cost.locked,
                status: !cost.locked.status
              }
            }
          }
          return cost
        })

        const updatedCurrent = row.current.map(cost => {
          if (type === 'row' || cost.id === cellId) {
            return {
              ...cost,
              locked: {
                ...cost.locked,
                status: !cost.locked.status
              }
            }
          }
          return cost
        })

        return {
          ...row,
          prior: updatedPrior,
          current: updatedCurrent
        }
      }
      return row
    }))
  }
  const handleHighlightClick = ({ type, color, rowId, cellId }) => {
    if (mode !== 'highlight') return

    setRows(prevRows => prevRows.map(row => {
      if (row.id === rowId) {
        const updatedPrior = row.prior.map(cost => {
          if (type === 'row' || cost.id === cellId) {
            return {
              ...cost,
              highlight: {
                ...cost.highlight,
                status: !cost.highlight.status,
                color
              }
            }
          }
          return cost
        })

        const updatedCurrent = row.current.map(cost => {
          if (type === 'row' || cost.id === cellId) {
            return {
              ...cost,
              highlight: {
                ...cost.highlight,
                status: !cost.highlight.status,
                color
              }
            }
          }
          return cost
        })

        return {
          ...row,
          prior: updatedPrior,
          current: updatedCurrent
        }
      }
      return row
    }))
  }
  const handleUpdate = ({
    type = 'cell',
    newValue,
    metadata,
    rowId,
    cellId
  }) => {
    if (!['edit', 'highlight'].includes(mode)) return
    setRows(prevRows => prevRows.map(row => {
      if (row.id === rowId) {
        const updatedPrior = row.prior.map(cost => {
          if (cost.id === cellId && type === 'cell') {
            return {
              ...cost,
              value: newValue,
              metadata,
              updated: true
            }
          }
          if (type === 'row') {
            const notes = `${cost.metadata?.notes ?? ''}\n${metadata?.notes}`
            return {
              ...cost,
              metadata: {
                ...cost.metadata,
                notes
              }
            }
          }
          return cost
        })

        const updatedCurrent = row.current.map(cost => {
          if (cost.id === cellId && type === 'cell') {
            return {
              ...cost,
              value: newValue,
              metadata,
              updated: true
            }
          }
          if (type === 'row') {
            const notes = `${cost.metadata?.notes ?? ''}\n${metadata?.notes}`
            return {
              ...cost,
              metadata: {
                ...cost.metadata,
                notes

              }
            }
          }
          return cost
        })

        return {
          ...row,
          prior: updatedPrior,
          current: updatedCurrent
        }
      }
      return row
    }))
  }

  return (
    <TableContainer
      sx={{ height: '100%', backgroundColor: '#f5f8fa' }}
      component={Paper}
    >
      <Table size='small' aria-label='DOW Summary table' stickyHeader>
        <TableHead>
          <TableRow>
            {mode === 'lock' ? <CustomCell content='' /> : null}
            {mode === 'highlight' ? <CustomCell content='' /> : null}
            <CustomCell colSpan={1} content='' />
            <CustomCell colSpan={1} content='' />
            <CustomCell header colSpan={6} content='PRIOR' align='center' />
            <CustomCell header colSpan={6} content='CURRENT' align='center' />
            <CustomCell
              header
              colSpan={1}
              content='Value Change (increase/decrease)'
            />
            <CustomCell header colSpan={1} content='% change' />
          </TableRow>
          <TableRow>
            {mode === 'lock' ? <CustomCell content='' /> : null}
            {mode === 'highlight' ? <CustomCell content='' /> : null}
            <CustomCell colSpan={1} content='' />
            <CustomCell colSpan={1} content='' />
            <CustomCell
              header
              colSpan={6}
              content='Project Name and Date'
              align='center'
            />
            <CustomCell
              header
              colSpan={6}
              content='Gate 1 Estimate (May 16, 2024)'
              align='center'
            />
            <CustomCell header colSpan={1} content='' />
            <CustomCell header colSpan={1} content='' />
          </TableRow>
          <TableRow>
            {mode === 'lock' ? <CustomCell content='Lock' /> : null}
            {mode === 'highlight' ? <CustomCell content='Highlight' /> : null}
            <CustomCell content='' />
            <CustomCell content='' />
            {[...dowSummary.headers, ...dowSummary.headers].map(
              (col, index) => (
                <CustomCell key={index} header content={col} mode={mode} />
              )
            )}
            <CustomCell header content='' />
            <CustomCell header content='' />
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) =>
            row.type === 'title'
              ? (
                <TableRow key={index}>
                  {mode === 'lock' ? <CustomCell content='' /> : null}
                  {mode === 'highlight' ? <CustomCell content='' /> : null}
                  <CustomCell content='' />
                  <CustomCell key={row.id} content={row.name} bold />
                  {Array(dowSummary.headers.length * 2 + 2)
                    .fill(0)
                    .map((col, index) => (
                      <CustomCell key={index} content='' />
                    ))}
                </TableRow>
                )
              : (
                <TableRow key={index}>
                  {mode === 'lock' || mode === 'highlight'
                    ? <CustomCell
                        key={row.id}
                        content={null}
                        mode={mode}
                        handleLockClick={() =>
                          handleLockClick('row', row.id)}
                        handleHighlightClick={(color) =>
                          handleHighlightClick({
                            type: 'row',
                            color,
                            rowId: row.id
                          })}
                        handleUpdate={(value, metadata) =>
                          handleUpdate({
                            value,
                            type: 'row',
                            metadata,
                            rowId: row.id
                          })}
                        highlight={[...row.prior, ...row.current]
                          ?.every(cost => cost.highlight.status === true)
                          ? { status: true }
                          : {}}
                        locked={[...row.prior, ...row.current]
                          ?.every(cost => cost.locked.status === true)
                          ? { status: true }
                          : {}}
                      />
                    : null}
                  <CustomCell
                    key={row.id + row.num}
                    content={row.num ?? ''}
                    adminstrativeRep={row.type === 'subtotal'}
                  />
                  <CustomCell
                    key={row.id + row.name}
                    content={row.name}
                    adminstrativeRep={row.type === 'subtotal'}
                  />
                  {[...row.prior, ...row.current].map((cost) => {
                    const { value, id, locked, highlight, metadata } = cost
                    return (
                      <CustomCell
                        key={id}
                        metadata={metadata}
                        updated={cost.updated}
                        content={value !== '' ? value : ''}
                        prefix={value !== '' ? '$' : ''}
                        adminstrativeRep={row.type === 'subtotal'}
                        mode={mode}
                        locked={locked}
                        highlight={highlight}
                        handleHighlightClick={(color) =>
                          handleHighlightClick({
                            type: 'cell',
                            color,
                            rowId: row.id,
                            cellId: id
                          })}
                        handleUpdate={(value, metadata) =>
                          handleUpdate({
                            newValue: value,
                            metadata,
                            rowId: row.id,
                            cellId: id
                          })}
                        handleLockClick={() =>
                          handleLockClick('cell', row.id, id)}
                      />
                    )
                  })}
                  <CustomCell
                    key={row.id + row.valueChange}
                    content={row.valueChange}
                    prefix='$'
                    adminstrativeRep={row.type === 'subtotal'}
                    mode={mode === 'lock' ? '' : mode}
                  />
                  <CustomCell
                    key={row.id + row.percentChange}
                    content={row.percentChange}
                    suffix='%'
                    adminstrativeRep={row.type === 'subtotal'}
                    mode={mode === 'lock' ? '' : mode}
                  />
                </TableRow>
                )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
