import React, { useState, useEffect, useRef } from 'react'
import { Box, Typography, Button } from '@mui/material'
import SourceOutlinedIcon from '@mui/icons-material/SourceOutlined'
import Checkbox from '@mui/material/Checkbox'
import Markdown from 'react-markdown'
import ConfidenceLevel from '../../BudgetDrawer/ConfidenceLevel/ConfidenceLevel'
import styles from './Message.module.css'

export default function Message ({
  message,
  setExcludeConversation,
  isSummaryOpen,
  handleExport,
  setConversations,
  setSummary,
  index
}) {
  const ref = useRef(null)
  const personaIcon = 'assistants/general-agent.png'
  const icon = message.role === 'user' ? 'user-icon.svg' : personaIcon

  const personaTitle = 'AI Agent'
  const title = message.role === 'user' ? 'You' : personaTitle
  const {
    source, confidence, micro_services: microServices, persona
  } = message.content[0].text
  const [displaySource, setDisplaySource] = useState(false)
  const sourceList = [
    {
      title: 'Files',
      data: source?.files
    },
    {
      title: 'Rows',
      data: source?.rows
    },
    {
      title: 'Columns',
      data: source?.columns
    },
    {
      title: 'Database',
      data: source?.database
    },
    {
      title: microServices?.length > 1 ? 'Microservices' : 'Microservice',
      data: microServices ?? null
    },
    {
      title: 'Persona',
      data: [persona] ?? null
    }
  ]
  const [checked, setChecked] = useState(true)
  const [open, setOpen] = useState(false)
  const [showConfidence, setShowConfidence] = useState(false)

  const toggleConfidence = () => {
    setShowConfidence(prev => !prev)
  }
  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked)
    event.target.checked
      ? setExcludeConversation((prev) => prev.filter((n) => n !== message.id))
      : setExcludeConversation((prev) => [...prev, message.id])
  }

  useEffect(() => {
    function handleClickOutside (event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const [isEditing, setIsEditing] = useState(false)

  const handleEdit = () => {
    setIsEditing(!isEditing)
  }

  const handleInputChange = (e) => {
    setConversations((prev) => {
      const newConversations = [...prev]
      newConversations[index].content[0].text.value = e.target.value
      return newConversations
    })

    if (setSummary)setSummary(e.target.value)
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      setIsEditing(false)
    }
  }

  return (
    <Box>
      <Box
        className={styles.main}
      >
        {(isSummaryOpen && !!setExcludeConversation) && (
          <div>
            <Checkbox
              color='default'
              style={{ paddingTop: 0 }}
              checked={checked}
              onChange={handleCheckboxChange}
            />
          </div>)}
        <Box className={styles.messageContainer}>
          {icon !== ''
            ? (
              <img
                src={`/images/icons/${icon}`}
                alt='user'
                className={styles.userIcon}
              />)
            : (
              <Box
                sx={{
                  width: '21px'
                }}
              />
              )}

          <Box className={styles.messageInfo}>
            <p className={styles.title}>{title}</p>
            <p
              className={styles.message}
              style={message.type === 'error' ? { color: 'red' } : {}}
            >
              <p
                className={styles.message}
                style={message.type === 'error' ? { color: 'red' } : {}}
              >
                {message.type === 'summary' && isEditing
                  ? (
                    <EditableTextarea
                      value={message.content[0].text.value}
                      onChange={handleInputChange}
                      onKeyDown={handleKeyPress}
                    />
                    )
                  : (
                    <Markdown>{message.content[0].text.value}</Markdown>
                    )}
              </p>
            </p>
            {message.role === 'assistant' &&
            message.content[0].text.source.length > 0 && (
              <Typography
                variant='caption'
                color='#939699'
                fontStyle='italic'
                fontSize='10px'
                fontWeight={400}
                lineHeight='12px'
              >
                {message.content[0].text.source.length > 1
                  ? 'Sources: '
                  : 'Source: '}{' '}
                {message.content[0].text.source.map((src, index) => (
                  <span key={index}>
                    {src}
                    {index === message.content[0].text.source.length - 1
                      ? ''
                      : ', '}
                  </span>
                ))}
              </Typography>
            )}
          </Box>
        </Box>
        {message.role !== 'user'
          ? (
            <Box sx={{ width: 'fit-content' }}>
              <p className={styles.metrics}>
                Key Metrics
              </p>
            </Box>
            )
          : (
            <Box className={styles.messageOptions}>
              <Box className={styles.options}>
                <img
                  src='/images/icons/edit-icon.svg'
                  alt='edit'
                  className={styles.optionIcon}
                  onClick={handleEdit}
                />
                <img
                  src='/images/icons/restart-icon.svg'
                  alt='restart'
                  className={styles.optionIcon}
                />
                <Box sx={{ position: 'relative' }}>
                  <img
                    src='/images/icons/export-icon.svg'
                    alt='export'
                    className={styles.optionIcon}
                    onClick={() => {
                      if (message.type === 'summary') setOpen(true)
                    }}
                  />
                  {open && (
                    <Box className={styles.dropdown} ref={ref}>
                      <Button
                        variant='outlined'
                        onClick={() => handleExport('txt')}
                        sx={{ width: '100%', height: '10px' }}
                      >Text
                      </Button>
                      <Button
                        variant='outlined'
                        onClick={() => handleExport('md')}
                        sx={{ width: '100%', height: '10px' }}
                      >Markdown
                      </Button>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>)}
      </Box>
      {message.role !== 'user' &&
        <Box className={styles.sourceActionsContainer}>
          {sourceList.Microservice && <p>Micro-Service</p>}
          {(sourceList.files || sourceList.rows || sourceList.columns) && <p>Direct Source</p>}
          {sourceList.persona && <p>AI Agent</p>}
          {sourceList.database && <p>Database</p>}
          {(message.role !== 'user' && confidence?.score) &&
            <p onClick={toggleConfidence}>
              Confidence Score : {confidence.score}
            </p>}
        </Box>}
      {(message.role !== 'user' && confidence?.score && showConfidence) &&
        <ConfidenceLevel confidence={confidence} />}
      {(message.role !== 'user') &&
        <Box className={styles.sourceContainer}>
          <SourceOutlinedIcon
            className={styles.sourceIcon}
            onClick={() => setDisplaySource(prev => !prev)}
          />
          {displaySource && (
            <Box className={styles.source}>
              {sourceList.map((source, index) => (
                <Box key={index}>
                  <p className={styles.title}>
                    {source.title}:
                  </p>
                  <ul style={{ margin: '0px' }}>
                    {source.data && source.data.length > 0
                      ? source.data.map((value, index) => (
                        <li key={index} className={styles.message}>
                          {
                            source.title === 'Persona' && value === ''
                              ? 'Agent'
                              : value
                          }
                        </li>
                      ))
                      : (
                        <li className={styles.message}>
                          No data
                        </li>
                        )}
                  </ul>
                </Box>
              ))}
            </Box>
          )}
        </Box>}
    </Box>
  )
}

function EditableTextarea ({ value, onChange, onKeyDown }) {
  return (
    <textarea
      value={value}
      onChange={onChange}
      onKeyDown={onKeyDown}
      style={{
        width: '100%',
        fontFamily: 'Poppins',
        fontSize: '12px',
        fontWeight: '400',
        lineHeight: '18px',
        textAlign: 'left',
        color: '#33373e',
        border: '1px solid #1AB28A',
        outline: 'none',
        overflow: 'auto',
        minHeight: '100px',
        minWidth: '400px',
        backgroundColor: 'transparent'
      }}
    />
  )
}
