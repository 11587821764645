import React, { useState } from 'react'
import { Modal, Box, Slide } from '@mui/material'
import styles from './AssetLibrary.module.css'

export default function AssetLibrary ({ open, setOpen }) {
  const [search, setSearch] = useState('')
  const assets = ['cloth', 'house', 'plant', 'car', 'school']
  const assetList = Array(42)
    .fill()
    .map(() => assets[Math.floor(Math.random() * assets.length)])

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      closeAfterTransition
      BackdropProps={{ style: { backgroundColor: 'transparent' } }}
    >
      <Slide direction='right' in={open} mountOnEnter unmountOnExit>
        <Box className={styles.assetLibraryContainer}>
          <Box className={styles.searchbar}>
            <img
              src='/images/icons/search-icon.svg'
              alt='search'
              className={styles.searchIcon}
            />
            <input
              type='text'
              className={styles.searchInput}
              placeholder='Search Asset Library'
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Box>
          <Box className={styles.assets}>
            {assetList.map((asset, index) => {
              return (
                <Box className={styles.assetContainer} key={index}>
                  <img
                    src={`/images/icons/assets/${asset}.svg`}
                    alt={asset}
                    className={styles.asset}
                  />
                </Box>
              )
            })}
          </Box>
        </Box>
      </Slide>
    </Modal>
  )
}
