import React from 'react'
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import CustomCell from '../../CustomCell/CustomCell'

export default function GrandSummary ({ budget, mode = null, rows, setRows }) {
  if (!budget.grandSummary) return null

  let headers = ['', ...budget.grandSummary.headers]
  if (mode === 'lock') {
    headers = ['Lock', '', ...budget.grandSummary.headers]
  }
  if (mode === 'highlight') {
    headers = ['Highlight', '', ...budget.grandSummary.headers]
  }

  const handleLockClick = (type, rowId, cellId) => {
    if (mode !== 'lock') return

    setRows(prevRows => prevRows.map(row => {
      if (row.id === rowId) {
        const updatedCosts = row.costs.map(cost => {
          if (type === 'row' || cost.id === cellId) {
            return {
              ...cost,
              locked: {
                ...cost.locked,
                status: !cost.locked.status
              }
            }
          }
          return cost
        })

        return {
          ...row,
          costs: updatedCosts
        }
      }
      return row
    }))
  }

  const handleHighlightClick = ({ type, color, rowId, cellId }) => {
    if (mode !== 'highlight') return

    setRows(prevRows => prevRows.map(row => {
      if (row.id === rowId) {
        const updatedCosts = row.costs.map(cost => {
          if (type === 'row' || cost.id === cellId) {
            return {
              ...cost,
              highlight: {
                ...cost.highlight,
                status: !cost.highlight.status,
                color
              }
            }
          }
          return cost
        })

        return {
          ...row,
          costs: updatedCosts
        }
      }
      return row
    }))
  }

  const handleUpdate = ({
    type = 'cell',
    newValue,
    metadata,
    rowId,
    cellId
  }) => {
    if (!['edit', 'highlight'].includes(mode)) return

    setRows(prevRows => prevRows.map(row => {
      if (row.id === rowId) {
        const updatedCosts = row.costs.map(cost => {
          if (cost.id === cellId && type === 'cell') {
            return {
              ...cost,
              value: newValue,
              metadata,
              updated: true
            }
          }
          if (type === 'row') {
            const notes = `${cost.metadata?.notes ?? ''}\n${metadata?.notes}`
            return {
              ...cost,
              metadata: {
                ...cost.metadata,
                notes
              }
            }
          }
          return cost
        })

        return {
          ...row,
          costs: updatedCosts
        }
      }
      return row
    }))
  }

  return (
    <TableContainer
      sx={{ height: '100%', backgroundColor: '#f5f8fa' }}
      component={Paper}
    >
      <Table
        size='small'
        aria-label='Grand Summary table'
        stickyHeader
      >
        <TableHead>
          <TableRow>
            {headers.map(head => (
              <CustomCell key={head} header content={head} />
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index}>
              {mode === 'lock' &&
                <CustomCell
                  content=''
                  align='center'
                  mode={['header'].includes(row.type) || !row?.category
                    ? ''
                    : mode}
                  handleLockClick={() =>
                    handleLockClick('row', row.id)}
                  locked={row.costs?.every(cost => cost.locked.status === true)
                    ? { status: true }
                    : {}}
                />}
              {mode === 'highlight' &&
                <CustomCell
                  content={null}
                  align='center'
                  mode={['header'].includes(row.type) || !row?.category
                    ? ''
                    : mode}
                  handleLockClick={() =>
                    handleLockClick('row', row.id)}
                  handleHighlightClick={(color) =>
                    handleHighlightClick({
                      type: 'row',
                      color,
                      rowId: row.id
                    })}
                  handleUpdate={(value, metadata) =>
                    handleUpdate({
                      value,
                      type: 'row',
                      metadata,
                      rowId: row.id
                    })}
                  locked={row.costs?.every(cost => cost.locked.status === true)
                    ? { status: true }
                    : {}}
                />}
              <CustomCell
                key={row.category}
                content={row.category}
                bold={['subtotal', 'total'].includes(row.type)}
                adminstrativeRep={row.type === 'header'}
              />
              {row.costs
                ? row.costs.map((cost, idx) => (
                  <CustomCell
                    key={cost.id}
                    content={cost.value}
                    metadata={cost.metadata}
                    updated={cost.updated}
                    prefix='$'
                    align='center'
                    mode={mode}
                    locked={cost.locked}
                    highlight={cost.highlight}
                    handleHighlightClick={(color) =>
                      handleHighlightClick({
                        type: 'cell',
                        color,
                        rowId: row.id,
                        cellId: cost.id
                      }
                      )}
                    handleUpdate={(value, metadata) =>
                      handleUpdate({
                        newValue: value,
                        metadata,
                        rowId: row.id,
                        cellId: cost.id
                      })}
                    handleLockClick={() =>
                      handleLockClick('cell', row.id, cost.id)}
                  />
                ))
                : Array(headers.length - 1).fill().map((_, idx) => (
                  <CustomCell
                    key={idx}
                    content=''
                    bold
                  />
                ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
