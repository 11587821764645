import React, { useEffect, useMemo, useRef } from 'react'
import { Box } from '@mui/material'
import { marked } from 'marked'
import styles from './ChatInterface.module.css'
import Message from '../Message/Message'
import {
  firstTimeUserPrompts,
  chatPrompts,
  budgetPrompts,
  designPrompts,
  assistants
} from '../../../utils/constants'

export default function ChatInterface ({
  project,
  conversations = [],
  setConversations,
  currentView,
  loading: loadingMessage,
  error: messageError,
  submitValue,
  selectedAssistant = assistants[0],
  showPrompts,
  followups = [],
  setExcludeConversation,
  isSummaryOpen,
  summary,
  setSummary
}) {
  const chatBodyRef = useRef(null)
  const followupsRef = useRef(null)

  const handlePromptClick = (prompt) => {
    submitValue(prompt, selectedAssistant.name)
  }

  const promptList = useMemo(() => {
    if (conversations?.length === 0) return firstTimeUserPrompts
    if (followups?.length > 0) return followups
    switch (currentView) {
      case 'chat':
        return chatPrompts
      case 'budget':
        return budgetPrompts
      case 'design':
        return designPrompts
      default:
        return firstTimeUserPrompts
    }
  }, [conversations?.length, currentView, followups])

  useEffect(() => {
    if (chatBodyRef.current &&
    typeof chatBodyRef.current.scrollIntoView === 'function') {
      chatBodyRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
  }, [conversations])
  useEffect(() => {
    if (followupsRef.current &&
    typeof followupsRef.current.scrollIntoView === 'function') {
      followupsRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
  }, [followups, showPrompts])

  function markdownToPlainText (markdown) {
    const html = marked(markdown)
    const div = document.createElement('div')
    div.innerHTML = html
    return div.textContent || div.innerText || ''
  }

  function downloadFile (summary, isMarkdown) {
    const content = isMarkdown ? summary : markdownToPlainText(summary)
    const fileExtension = isMarkdown ? '.md' : '.txt'
    const mimeType = isMarkdown ? 'text/markdown' : 'text/plain'
    const filename = `summary${fileExtension}`

    const blob = new Blob([content], { type: mimeType })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = filename
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(url)
  }

  const handleExport = (type, index) => {
    const isMarkdown = type === 'md'
    const text = conversations?.[index]?.content[0]?.text?.value
    downloadFile(summary || text, isMarkdown)
  }

  return (
    <Box className={styles.chatInterface}>
      <p className={styles.chatTitle}>{project?.name ?? ''}</p>
      <Box className={styles.chatBody}>
        <Box className={styles.conversations} ref={chatBodyRef}>
          {conversations?.map((message, index) => (
            <Message
              message={message}
              key={index}
              index={index}
              setExcludeConversation={setExcludeConversation}
              isSummaryOpen={isSummaryOpen}
              setConversations={setConversations}
              handleExport={(e) => handleExport(e, index)}
              setSummary={setSummary}
            />
          ))}
        </Box>
        {(!loadingMessage && !messageError && showPrompts) &&
          <Box className={styles.continueChoices} ref={followupsRef}>
            <img
              src='/images/icons/assistants/general-agent.png'
              alt='estimator-icon'
              className={styles.estimatorIcon}
            />
            <Box className={styles.continueChatbox}>
              <Box className={styles.brainstormOptions}>
                {promptList?.map((prompt, index) => (
                  <p
                    key={index}
                    variant='outlinedSecondary'
                    className={styles.brainstormOption}
                    onClick={() => handlePromptClick(prompt)}
                  >
                    {prompt}
                  </p>
                ))}
              </Box>
            </Box>
          </Box>}
      </Box>
    </Box>
  )
}
