import React, { useState } from 'react'

const getIndentationStyle = (level) => ({
  marginLeft: `${level * 10}px`,
  display: 'flex',
  gap: '4px',
  cursor: 'pointer'
})

export default function CollapsibleData ({ object, level = 0 }) {
  const [isCollapsed, setIsCollapsed] = useState(true)

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed)
  }

  if (typeof object !== 'object' || object === null) {
    return <div>{String(object)}</div>
  }

  const indentation = getIndentationStyle(level)

  return (
    <div>
      <div style={{ display: 'flex', gap: '6px' }}>
        <span style={indentation} onClick={toggleCollapse}>
          {isCollapsed ? '►' : '▼'}
        </span>
        <span onClick={toggleCollapse} style={{ cursor: 'pointer' }}>
          {Array.isArray(object) ? `Array(${object.length})` : '{...}'}
        </span>
      </div>
      {!isCollapsed && (
        <div style={{ marginLeft: '10px' }}>
          {Object.entries(object).map(([key, value]) => (
            <div key={key} style={indentation}>
              <strong>{key}:</strong>{' '}
              <CollapsibleData object={value} level={level + 1} />
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
