import React, {
  createContext, useContext, useEffect, useState, useCallback
} from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { findUser, findOrCreateUser } from '../api'
import Loading from '../components/Loading/Loading'

const AuthContext = createContext()

export const useAuth = () => useContext(AuthContext)

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')
  const [searchParams, setSearchParams] = useSearchParams()
  const tokenKey = '_aui_authToken'
  const navigate = useNavigate()

  const fetchUser = useCallback(async () => {
    const token = localStorage.getItem(tokenKey)
    try {
      const user = await findUser()
      setCurrentUser({
        email: user.email, givenName: user.name, isRealName: true
      })
    } catch (err) {
      if (err.response?.status === 404) {
        const [, payload] = token.split('.')
        const { email } = JSON.parse(atob(payload))
        const [givenName] = email.split('@')

        setCurrentUser({ email, givenName, isRealName: false })
      }
      if (err.response?.status === 401 || err.response?.status === 403) {
        localStorage.removeItem(tokenKey)
        setError('Unauthorized')
        navigate('/login')
      }
    } finally {
      setLoading(false)
    }
  }, [navigate, tokenKey])

  const createAuthenticUser = useCallback(async (name) => {
    const user = await findOrCreateUser(name)
    setCurrentUser({
      email: user.email, givenName: user.name, isRealName: true
    })
  }, [])

  useEffect(() => {
    const token = searchParams.get('jwt') || localStorage.getItem(tokenKey)
    if (!token) return setLoading(false)
    localStorage.setItem(tokenKey, token)

    searchParams.delete('jwt')
    setSearchParams(searchParams)

    if (!error) fetchUser(token)
  }, [searchParams, setSearchParams, error, fetchUser])

  const logoutAuthentic = async () => {
    localStorage.removeItem(tokenKey)
    setCurrentUser(null)
    navigate('/login')
  }

  return (
    <AuthContext.Provider
      value={{ currentUser, createAuthenticUser, logoutAuthentic, error }}
    >
      {(loading && !currentUser) ? <Loading /> : children}
    </AuthContext.Provider>
  )
}
