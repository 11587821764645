import { createTheme } from '@mui/material'

export const palette = {
  mode: 'light',
  gold: '#FBBE4F',
  seafoam: '#76D0C0',
  mint: '#1AB28A',
  pale_grape: '#746BAB',
  azure: '#4A62D8',
  sky: '#2CB7E3',
  red: '#E96060'
}

export const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          padding: '15px 18.75px',
          fontSize: '14px',
          lineHeight: '10px',
          fontWeight: 600,
          textTransform: 'capitalize',
          maxHeight: '40px'
        }
      },
      variants: [
        {
          props: { variant: 'containedPrimary' },
          style: {
            backgroundColor: palette.mint,
            color: '#fff',
            '&:hover': {
              backgroundColor: '#138063'
            }
          }
        },
        {
          props: { variant: 'containedSecondary' },
          style: {
            backgroundColor: palette.azure,
            color: '#fff',
            '&:hover': {
              backgroundColor: '#3D51B2'
            }
          }
        },
        {
          props: { variant: 'containedSecondaryRounded' },
          style: {
            backgroundColor: palette.azure,
            color: '#fff',
            borderRadius: '19px',
            '&:hover': {
              backgroundColor: '#3D51B2'
            }
          }
        },
        {
          props: { variant: 'outlinedPrimary' },
          style: {
            border: '2px solid ' + palette.mint,
            color: palette.mint,
            '&:hover': {
              backgroundColor: '#138063',
              borderColor: '#138063'
            }
          }
        },
        {
          props: { variant: 'outlinedSecondary' },
          style: {
            border: '1px solid #ABAFB2',
            color: '#939699',
            '&:hover': {
              backgroundColor: '#DCE2E5',
              color: '#494B4D',
              borderColor: '#494B4D'
            }
          }
        }
      ]
    }
  },
  palette,
  typography: {
    fontFamily: 'Poppins, sans-serif'
  }
})
