import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  checkMessageStatus,
  fetchWithLoading,
  retrieveMessages,
  updateProject,
  sendMessage
} from '../../api'
import ChatModal from '../ChatModal/ChatModal'
import Console from '../Console/Console'
import AssetLibrary from '../AssetLibrary/AssetLibrary'
import BudgetDrawer from '../BudgetDrawer/BudgetDrawer'
import TerminalIcon from '@mui/icons-material/Terminal'
import RefreshIcon from '@mui/icons-material/Refresh'
import { generateTextContent } from '../../utils/projects'
import { MESSAGE_STATUS } from '../../constants/messages'
import styles from './FloatingButtons.module.css'
import { useProject } from '../../Context/ProjectContext'

export default function FloatingButtons () {
  const navigate = useNavigate()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [budgetDrawer, setBudgetDrawer] = useState(false)
  const [consoleOpen, setConsoleOpen] = useState(false)
  const [assetModalOpen, setAssetModalOpen] = useState(false)
  const [conversations, setConversations] = useState([])
  const [followups, setFollowups] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const { project, threadId, setThreadId } = useProject()

  async function submitValue (newPrompt, persona) {
    setConversations(prev => [...prev, {
      role: 'user',
      content: generateTextContent(newPrompt)
    }, {
      role: '',
      content: generateTextContent('Loading...')
    }])

    try {
      setLoading(true)
      const messageResponse = await sendMessage(
        {
          message: newPrompt,
          layout: project?.world_data,
          ...(threadId && { thread_id: threadId })
        }
      )

      const newThreadId = messageResponse?.thread_id

      if (!threadId) {
        setThreadId(newThreadId)
        await updateProject(project?._id, { thread_id: newThreadId })
      }

      const response = await checkStatus(newThreadId)

      if (response.status === MESSAGE_STATUS.completed) {
        const projectData = await fetchWithLoading(
          () => retrieveMessages({ threadId: threadId || newThreadId }),
          setLoading,
          setError
        )
        setFollowups(projectData.data[0].content[0].text.followups)
        setConversations(projectData.data.reverse())
      } else if (response.status === MESSAGE_STATUS.failed) {
        const errorMessage = 'Something went wrong. Please retry'
        displayErrorMessage(errorMessage)
        setLoading(false)
        setError(errorMessage)
      }
    } catch (error) {
      console.error('API error:', error)
      displayErrorMessage(error.message)
      setError(error.message)
      setLoading(false)
    }
  }
  const displayErrorMessage = (errorMessage) => {
    setConversations(prevConversations => {
      if (prevConversations.length === 0) return prevConversations
      const newConversations = [...prevConversations]
      const lastElementIndex = newConversations.length - 1
      newConversations[newConversations.length - 1] = {
        ...newConversations[lastElementIndex],
        type: 'error',
        content: generateTextContent(errorMessage)
      }
      return newConversations
    })
  }

  const checkStatus = async (threadId) => {
    try {
      const statusResponse = await checkMessageStatus({ threadId })
      const status = statusResponse.status
      if ([MESSAGE_STATUS.completed, MESSAGE_STATUS.failed].includes(status)) {
        return statusResponse
      } else {
        await new Promise(resolve => setTimeout(resolve, 1000))
        return checkStatus(threadId)
      }
    } catch (error) {
      console.error('Failed to check message status:', error)
      throw error
    }
  }

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const res = await fetchWithLoading(
          () => retrieveMessages({ threadId }),
          setLoading,
          setError
        )
        setFollowups(res.data[0]?.content[0]?.text?.followups)
        setConversations(res.data.reverse())
      } catch (err) {
        navigate('/')
      }
    }
    if (threadId) fetchProject()
    else {
      setConversations([])
    }
  }, [threadId, navigate])

  const handleRefreshClick = () => {
    navigate('/')
  }

  return (
    <Box>
      <Box
        className={styles.actionButtons}
      >
        <Box
          className={styles.actionButtonIcon}
          sx={{
            backgroundColor: '#4a62d8 !important',
            color: '#ffffff',
            border: '1px solid #293678'
          }}
          onClick={() => setIsModalOpen(true)}
        >
          <img src='/images/ai-inkwell-mini-logo-white.svg' alt='chatbot' />
          <p className={styles.buttonText}>AI Agent</p>
        </Box>
        <Box
          className={styles.actionButtonIcon}
          onClick={() => setBudgetDrawer(true)}
        >
          <img src='/images/icons/budget.svg' alt='budget' />
          <p className={styles.buttonText}>Budget</p>
        </Box>
        <Box
          className={styles.actionButtonIcon}
          onClick={() => setAssetModalOpen(true)}
        >
          <img src='/images/icons/asset.svg' alt='Asset library' />
          <p className={styles.buttonText}>Assets</p>
        </Box>
        <TerminalIcon
          className={styles.terminal}
          onClick={() => setConsoleOpen(true)}
        />
        <RefreshIcon
          className={styles.refreshIcon}
          onClick={handleRefreshClick}
        />
      </Box>
      <BudgetDrawer
        open={budgetDrawer}
        setOpen={setBudgetDrawer}
      />
      <ChatModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        currentView='chat'
        conversations={conversations}
        loading={loading}
        error={error}
        submitValue={submitValue}
        followups={followups}
        setConversations={setConversations}
      />
      <Console
        open={consoleOpen}
        setOpen={setConsoleOpen}
      />
      <AssetLibrary
        open={assetModalOpen}
        setOpen={setAssetModalOpen}
      />
    </Box>
  )
}
