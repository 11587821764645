import React, { useEffect, useState } from 'react'
import {
  Box,
  Drawer,
  Typography,
  Menu,
  MenuItem,
  Button
} from '@mui/material'
import { saveAs } from 'file-saver'
import ExcelJS from 'exceljs'
import FullEstimate from './FullEstimate/FullEstimate'
import GrandSummary from './GrandSummary/GrandSummary'
import DOWSummary from './DOWSummary/DOWSummary'
import ScopeSummary from './ScopeSummary/ScopeSummary'
import styles from './BudgetDrawer.module.css'
import { exportOptions } from '../../utils/constants'
import {
  convertDOWSummaryToCSV,
  convertFullEstimateToCSV,
  convertGrandSummaryToCSV,
  convertScopeOfWorkToCSV,
  exportDowSummaryToExcel,
  exportFullEstimateToExcel,
  exportGrandSummaryToExcel,
  exportScopeOfWorkToExcel,
  exportToPdf,
  fullEstimateAdapt
} from '../../utils/fileExport'
import CompareBudget from './CompareBudget'
import { useProject } from '../../Context/ProjectContext'
import { updateBudget } from '../../api'
import { budgetEstimatorMapping } from '../../utils/budget'

const buttons = [
  {
    title: 'Full Estimate',
    icon: 'book.svg'
  },
  {
    title: 'Grand Summary',
    icon: 'book.svg'
  },
  {
    title: 'DOW Summary',
    icon: 'book.svg'
  },
  {
    title: 'Scope of Work',
    icon: 'leaf.svg'
  },
  {
    title: 'Summary',
    icon: 'clipboard.svg'
  },
  {
    title: 'Decision Overview',
    icon: 'globe.svg'
  }
]

export default function BudgetDrawer ({
  open,
  setOpen,
  budgetToCompare
}) {
  const { budget, setBudget } = useProject()
  const [view, setView] = useState('Full Estimate')
  const [currentMode, setCurrentMode] = useState(null)
  const [grandSummary, setGrandSummary] = useState(budget?.grandSummary?.rows)
  const [dowSummary, setDowSummary] = useState(budget?.dowSummary?.rows)
  const [fullEstimate, setFullEstimate] = useState(budget?.data)
  const [scopeSummary, setScopeSummary] = useState(budget.scopeSummary)
  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorElCompare, setAnchorElCompare] = useState(null)
  const [budgetCompare, setBudgetCompare] = useState(false)
  const [title, setTitle] = useState(null)

  const handleClose = () => {
    setAnchorElCompare(null)
  }
  const handleCloseMenu = () => {
    setAnchorElCompare(null)
  }
  const handleOptionClick = (option) => {
    setTitle(option)
    setBudgetCompare(true)
    handleClose()
  }

  const handleToggleCompare = () => {
    if (title) {
      setBudgetCompare(false)
      setTitle(null)
    } else {
      setAnchorElCompare(document.querySelector('#menu-button'))
    }
  }
  const handleClickExport = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseExport = () => {
    setAnchorEl(null)
  }

  const getPayloadForView = (view) => {
    switch (view) {
      case 'Full Estimate':
        return fullEstimate
      case 'DOW Summary':
        return dowSummary
      case 'Scope of Work':
        return scopeSummary
      case 'Grand Summary':
        return grandSummary
      default:
        return null
    }
  }

  const updateBudgetData = (view, updatedBudget) => {
    if (view === 'Full Estimate' || view === 'Scope of Work') {
      return updatedBudget.data
    }

    if (view === 'Grand Summary' || view === 'DOW Summary') {
      return { ...budget[budgetEstimatorMapping[view]], rows: updatedBudget.data }
    }

    return null
  }

  const handleUpdateBudget = async () => {
    try {
      const payload = getPayloadForView(view)
      if (!payload) {
        console.error('Invalid view selected')
        return
      }

      const updatedBudget = await updateBudget(payload)
      const updatedData = updateBudgetData(view, updatedBudget)

      setBudget({
        ...budget,
        [budgetEstimatorMapping[view]]: updatedData
      })
    } catch (err) {
      console.error(err)
    }
  }

  const handleModeChange = (mode) => {
    if (currentMode === mode) {
      setCurrentMode(null)
      handleUpdateBudget()
    } else setCurrentMode(mode)
  }

  const handleCloseDrawer = () => {
    setOpen(false)
    setView('Full Estimate')
    setCurrentMode(null)
  }

  const convertToCSV = (objArray, view) => {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray

    const viewConverters = {
      'Full Estimate': convertFullEstimateToCSV,
      'Grand Summary': convertGrandSummaryToCSV,
      'DOW Summary': convertDOWSummaryToCSV,
      'Scope of Work': convertScopeOfWorkToCSV
    }

    return viewConverters[view](array)
  }

  const exportToCSV = (data, view) => {
    const csvData = convertToCSV(data, view)
    const blob = new Blob([csvData], { type: 'text/csvcharset=utf-8' })
    const link = document.createElement('a')
    const url = URL.createObjectURL(blob)
    link.href = url
    link.setAttribute(
      'download',
      `${view.toLowerCase().replace(' ', '_')}-${new Date().toISOString()}.csv`
    )
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const exportToExcel = (data, view) => {
    switch (view) {
      case 'Full Estimate':
        return exportFullEstimateToExcel(
          ExcelJS,
          saveAs,
          data
        )
      case 'Grand Summary':
        return exportGrandSummaryToExcel(
          ExcelJS,
          saveAs,
          data
        )
      case 'DOW Summary':
        return exportDowSummaryToExcel(
          ExcelJS,
          saveAs,
          data
        )
      case 'Scope of Work':
        return exportScopeOfWorkToExcel(
          ExcelJS,
          saveAs,
          data,
          `scope_of_work-${new Date().toISOString()}.xlsx`
        )
      default:
        return ''
    }
  }

  const handleFormats = (data, format, view) => {
    switch (format) {
      case 'csv': {
        exportToCSV(data, view)
        break
      }
      case 'xlsx': {
        exportToExcel(data, view)
        break
      }
      case 'pdf': {
        exportToPdf(data, view)
        break
      }
      default: {
        return null
      }
    }
  }

  // Event trigger to export the data w.r.t view & format
  const handleExportData = (format) => {
    const allTablesFullEst = fullEstimate.map((el) => {
      return fullEstimateAdapt(el)
    })
    switch (view) {
      case 'Full Estimate': {
        handleFormats(allTablesFullEst, format, view)
        break
      }
      case 'Grand Summary': {
        handleFormats(grandSummary, format, view)
        break
      }
      case 'DOW Summary': {
        handleFormats(dowSummary, format, view)
        break
      }
      case 'Scope of Work': {
        handleFormats(scopeSummary, format, view)
        break
      }
      default: {
        return ''
      }
    }
    handleCloseExport()
  }

  const handleCombineExport = () => {
    const allTablesFullEst = fullEstimate.map((el) => {
      return fullEstimateAdapt(el)
    })
    // CSV string formatting
    let combinedCSVString = 'Full Estimates \r\n'
    combinedCSVString += convertToCSV(allTablesFullEst, 'Full Estimate')
    combinedCSVString += '\r\n\n'
    combinedCSVString += 'Grand Summary \r\n'
    combinedCSVString += convertToCSV(grandSummary, 'Grand Summary')
    combinedCSVString += '\r\n\n'
    combinedCSVString += 'DOW Summary \r\n'
    combinedCSVString += convertToCSV(dowSummary, 'DOW Summary')
    combinedCSVString += '\r\n\n'
    combinedCSVString += 'Scope of Work \r\n'
    combinedCSVString += convertToCSV(scopeSummary, 'Scope of Work')

    // Logic to export the file in csv format
    const blob = new Blob([combinedCSVString], { type: 'text/csvcharset=utf-8' })
    const link = document.createElement('a')
    const url = URL.createObjectURL(blob)
    link.href = url
    link.setAttribute('download', `full_export-${new Date().toISOString()}.csv`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  useEffect(() => {
    setGrandSummary(budget?.grandSummary?.rows)
    setDowSummary(budget?.dowSummary?.rows)
    setFullEstimate(budget?.data)
    setScopeSummary(budget?.scopeSummary)
  }, [budget])

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={handleCloseDrawer}
      PaperProps={{
        className: styles.drawerPaper
      }}
      sx={{
        '& .MuiDrawer-paper': {
          display: 'flex',
          flexDirection: 'row',
          gap: '10px',
          width: {
            xs: '90%',
            sm: '90%',
            md: budgetCompare ? '74.35%' : '40.2%',
            lg: budgetCompare ? '58.7%' : '40.2%',
            xl: budgetCompare ? '58.7%' : '31%'
          },
          boxShadow: 'none',
          borderRight: 'none'
        },
        '& .MuiBackdrop-root': {
          backgroundColor: 'transparent'
        }
      }}
    >
      {budgetCompare && (
        <CompareBudget budget={budgetToCompare} title={title} />
      )}
      <Box
        display='flex'
        flexDirection='column'
        height='100%'
        gap={0}
        width={budgetCompare ? '48.752%' : '100%'}
        overflow='auto'
      >
        <Typography textAlign='center'>Title: Current Budget</Typography>
        <Box
          display='flex'
          flexDirection='row-reverse'
          padding={0}
        >
          <img
            className={styles.pinIcon}
            src='/images/icons/pin.svg'
            alt='pin-icon'
          />
        </Box>
        <Box
          className={styles.buttonsContainer}
        >
          {buttons.map((button, index) => (
            <Box
              key={index}
              className={styles.button + ' ' + (view === button.title
                ? styles.activeButton
                : '')}
              onClick={() => {
                setView(button.title)
              }}
            >
              <img
                src={'/images/icons/' + button.icon}
                alt={button.title}
                height={15}
              />
              <Typography
                fontSize={10}
                fontWeight={500}
              >
                {button.title}
              </Typography>
            </Box>
          ))}
          <Box
            className={styles.button}
            onClick={() => {
              handleCombineExport()
            }}
          >
            <Typography
              fontSize={10}
              fontWeight={500}
            >
              Combine Export
            </Typography>
          </Box>
          <Box>
            <Button
              id='menu-button'
              onClick={handleToggleCompare}
              className={styles.button}
            >
              <Typography fontSize={10} fontWeight={500}>
                {budgetCompare ? 'Close Compare' : 'Compare Budget'}
              </Typography>
            </Button>
            <Menu
              anchorEl={anchorElCompare}
              open={Boolean(anchorElCompare)}
              onClose={handleCloseMenu}
            >
              <MenuItem onClick={() => handleOptionClick('Uploaded Budget')}>
                Uploaded Budget
              </MenuItem>
              <MenuItem onClick={() => handleOptionClick('Other Site Configuration')}>
                Other Site Configuration
              </MenuItem>
            </Menu>
          </Box>
        </Box>
        <Box
          className={styles.infoContainer}
        >
          <Box className={styles.infoBox}>
            <Typography className={styles.info} fontWeight={500}>
              Attraction Name
            </Typography>
            <Typography className={styles.info}>Resort</Typography>
            <Typography className={styles.info}>Park</Typography>
          </Box>
          <Box className={styles.infoBox}>
            <Typography className={styles.info}>Ride Type</Typography>
            <Typography className={styles.info}>Theme</Typography>
            <Typography className={styles.info}>Ride System</Typography>
          </Box>
          <Box className={styles.infoBox}>
            <Typography className={styles.info}>Open Date</Typography>
            <Typography className={styles.info}>CAPEX</Typography>
            <Typography className={styles.info}>Riders/Hr</Typography>
          </Box>
          <Box className={styles.infoBox}>
            <Typography className={styles.info}>Created By</Typography>
            <Typography className={styles.info}>Project Lead</Typography>
          </Box>
        </Box>
        <Box
          className={styles.tableContainer}
        >
          {view === 'Full Estimate' &&
            <FullEstimate
              budget={budget}
              mode={currentMode}
              rows={fullEstimate}
              setRows={setFullEstimate}
            />}
          {view === 'Grand Summary' &&
            <GrandSummary
              budget={budget}
              mode={currentMode}
              rows={grandSummary}
              setRows={setGrandSummary}
            />}
          {view === 'DOW Summary' &&
            <DOWSummary
              budget={budget}
              mode={currentMode}
              rows={dowSummary}
              setRows={setDowSummary}
            />}
          {view === 'Scope of Work' &&
            <ScopeSummary
              budget={budget}
              mode={currentMode}
              rows={scopeSummary}
              setRows={setScopeSummary}
            />}
        </Box>
        <Box
          className={styles.buttonsContainer}
          justifyContent='flex-start'
          gap='15px'
          marginTop='auto'
        >
          <Box
            component='div'
            onClick={() => handleModeChange('edit')}
            className={styles.actionButton}
            sx={{
              border: currentMode === 'edit'
                ? '1px solid black'
                : '1px solid transparent'
            }}
          >
            <img src='/images/icons/settings.svg' alt='edit-icon' height={15} />
            <Typography fontSize={10} fontWeight={500}>Edit</Typography>
          </Box>
          <Box
            component='div'
            onClick={() => handleModeChange('highlight')}
            className={styles.actionButton}
            sx={{
              border: currentMode === 'highlight'
                ? '1px solid black'
                : '1px solid transparent'
            }}
          >
            <img
              src='/images/icons/clipboard.svg'
              alt='highlight-icon'
              height={15}
            />
            <Typography fontSize={10} fontWeight={500}>Highlight</Typography>
          </Box>
          <Box
            component='div'
            onClick={() => handleModeChange('lock')}
            className={styles.actionButton}
            sx={{
              border: currentMode === 'lock'
                ? '1px solid black'
                : '1px solid transparent'
            }}
          >
            <img src='/images/icons/lock.svg' alt='lock-icon' height={15} />
            <Typography fontSize={10} fontWeight={500}>Lock</Typography>
          </Box>
          <Box
            className={styles.actionButton + ' ' + styles.exportButton}
            sx={{
              position: 'relative'
            }}
            onClick={handleClickExport}
          >
            <img src='/images/icons/export.svg' alt='export-icon' height={15} />
            <Typography fontSize={10} fontWeight={500}>Export</Typography>
            <img
              src='/images/icons/arrow-down.svg'
              alt='down-arrow'
              style={{
                transform: anchorEl ? 'rotate(180deg)' : 'none',
                transition: '.2s linear',
                boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
              }}
            />
          </Box>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleCloseExport}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            MenuListProps={{
              autoFocusItem: false,
              sx: {
                width: '100px',
                '& .MuiMenuItem-root': {
                  '&:hover': {
                    background: 'lightgray'
                  }
                }
              }
            }}
          >
            {exportOptions.map((option, index) => (
              <MenuItem
                key={option}
                onClick={() => handleExportData(option)}
                sx={{ display: 'flex', justifyContent: 'center' }}
              >
                {option.toUpperCase()}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Box>
    </Drawer>
  )
}
