import React, { createContext, useState, useContext, useEffect } from 'react'
import { getBudget } from '../api'

const ProjectContext = createContext()

export const useProject = () => useContext(ProjectContext)

export const ProjectProvider = ({ children }) => {
  const [project, setProject] = useState(null)
  const [threadId, setThreadId] = useState(null)
  const [budget, setBudget] = useState({})

  const updateWorldState = (newState) => {
    setProject((prev) => ({ ...prev, world_data: newState }))
  }

  useEffect(() => {
    const fetchBudget = async () => {
      try {
        const b = await getBudget()
        setBudget(b)
      } catch (error) {
        console.error('Failed to fetch budget:', error)
      }
    }

    fetchBudget()
  }, [])

  return (
    <ProjectContext.Provider
      value={{
        project,
        setProject,
        budget,
        setBudget,
        threadId,
        setThreadId,
        updateWorldState
      }}
    >
      {children}
    </ProjectContext.Provider>
  )
}
