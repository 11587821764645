import React, { useState, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import { useAuth } from '../../Context/AuthContext'
import styles from './NavBar.module.css'
import RecentProjects from '../RecentProjects/RecentProjects'

export default function NavBar ({ dragDrop = false, transition = false }) {
  const { currentUser, logoutAuthentic } = useAuth()

  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorElUser, setAnchorElUser] = useState(null)

  const inputRef = useRef(null)

  const avatarRef = useRef()

  const handlePopoverOpen = (event) => {
    setAnchorEl(inputRef.current)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const handleLogout = () => {
    logoutAuthentic()
    handleCloseUserMenu()
  }

  if (!currentUser) return null

  return (
    <>
      <Box
        className={styles.navBarContainer}
        sx={{ backgroundColor: dragDrop ? '#FBBE4F' : '#FFF' }}
      >
        <Link to='/'>
          <img
            src='/images/ai_inkwell_logo.png'
            alt='logo'
            className={styles.aiInkwellLogo}
          />
        </Link>
        <Box className={styles.centerArea}>
          <Box className={styles.toolName}>
            <p style={{ color: dragDrop ? '#FFF' : '#131415' }}>
              Estimator
            </p>
            <img
              src={`/images/icons/arrow${dragDrop ? '-white' : ''}.svg`}
              alt='expand'
              className={styles.expandIcon}
            />
          </Box>
          <Box
            className={styles.searchbar}
            sx={{ borderColor: dragDrop ? '#fff !important' : '#dce2e5' }}
            ref={inputRef}
          >
            <img
              src={`/images/icons/search-icon${dragDrop ? '-white' : ''}.svg`}
              alt='search'
              className={styles.searchIcon}
            />
            <input
              type='text'
              className={
                `${styles.searchInput} ${dragDrop ? styles.lightPlace : ''} 
                ${transition ? styles.transition : ''}`
              }
              placeholder='Search Anything'
              onClick={handlePopoverOpen}
            />
          </Box>
        </Box>
        <Box
          className={styles.profileContainer}
          ref={avatarRef}
        >
          <IconButton onClick={handleOpenUserMenu}>
            <img
              src={currentUser.picture ||
                `/images/icons/profile-icon${dragDrop ? '-white' : ''}.svg`}
              alt='profile'
              className={styles.profileIcon}
              data-testid='profile'
            />
          </IconButton>
          <Menu
            sx={{ mt: '35px' }}
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <MenuItem
              onClick={() => {
                navigate('/settings')
                handleCloseUserMenu()
              }}
            >
              <Typography textAlign='center'>Settings</Typography>
            </MenuItem>
            <MenuItem onClick={handleLogout}>
              <Typography textAlign='center'>Logout</Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Box>
      <RecentProjects
        anchorEl={anchorEl}
        handlePopoverClose={handlePopoverClose}
      />
    </>
  )
}
