import { createServer, Factory, Model } from 'miragejs'
import { faker } from '@faker-js/faker'
import { BASE_URL } from '../api'

const stages = [
  'PRODUCTION',
  'STAGE 3',
  'STAGE 2',
  'STAGE 1',
  'BLUE SKY',
  'INACTIVE'
]
const categories = ['Other', 'Residential', 'Commercial', 'Industrial']
const confidenceLevels = ['Low', 'Medium', 'High']

export function makeServer () {
  return createServer({
    models: {
      estimate: Model,
      project: Model
    },
    factories: {
      project: Factory.extend({
        id () {
          return faker.string.uuid()
        },
        project_name () {
          return faker.commerce.productName()
        },
        target_investment () {
          return faker.finance.amount()
        },
        opening_date () {
          return {
            month: faker.number.int({ min: 1, max: 12 }),
            year: faker.date.future().getFullYear()
          }
        },
        thread_id () {
          return null
        },
        stage () {
          return stages[faker.number.int({ min: 0, max: 2 })]
        },
        category () {
          return categories[faker.number.int({ min: 0, max: 3 })]
        },
        confidence_level () {
          return confidenceLevels[faker.number.int({ min: 0, max: 2 })]
        },
        created_at () {
          return new Date().toISOString()
        },
        updated_at () {
          return new Date().toISOString()
        }
      })
    },
    seeds (server) {
      const storedProjects = JSON.parse(localStorage.getItem('projects')) || []
      storedProjects.forEach(project => {
        server.create('project', project)
      })
    },
    timing: 1500,
    routes () {
      const NativeXMLHttpRequest = window.XMLHttpRequest

      window.XMLHttpRequest = function XMLHttpRequest () {
        const request = new NativeXMLHttpRequest(arguments)
        delete request.onloadend
        return request
      }
      this.passthrough('https://ecco-wdi.firebase.com/**')
      this.passthrough('https://identitytoolkit.googleapis.com/**')
      this.passthrough('https://firestore.googleapis.com/**')
      this.passthrough('https://securetoken.googleapis.com/**')
      this.passthrough(`${BASE_URL}/***`)

      this.post('/generate-budget', (schema, request) => {
        const response = generateBudget()
        return response
      })
      this.post('/generate-design', (schema, request) => {
        const response = { sidebarSections: generateSidebarSections() }
        return response
      })
    }
  })
}

export function generateBudget () {
  const header = [
    'Cost Codes',
    'Activities',
    'Labor Costs',
    'Material Costs',
    'Fixed Costs',
    'Budget',
    'Fixed Costs',
    'Under/Over',
    'Delta'
  ]
  const data = [
    fullEstimateHelper(),
    fullEstimateHelper(),
    fullEstimateHelper(),
    fullEstimateHelper()
  ]

  const designHeader = ['Activities', 'Labor', 'Material', 'Fixed']

  const archetypes = generateArchtypes()
  const grandSummary = generateGrandSummary()
  const dowSummary = generateDOWSummary()
  const scopeSummary = generateScopeSummary()

  return {
    header,
    designHeader,
    data,
    archetypes,
    grandSummary,
    dowSummary,
    scopeSummary
  }
}

function generateGrandSummary () {
  const headers = [
    'Area 1',
    'Area 2',
    'Area 3',
    'Area 4',
    'Area 5',
    'Total Overall'
  ]

  const rows = [
    { category: 'Hard Costs', type: 'header' },
    { category: 'Facility', costs: [0, 0, 0, 0, 0, 0] },
    { category: 'Show', costs: [0, 0, 0, 0, 0, 0] },
    { category: 'Show Hard Costs', costs: [0, 0, 0, 0, 0, 0] },
    { category: 'Show Labor Costs', costs: [0, 0, 0, 0, 0, 0] },
    { category: 'Ride', costs: [0, 0, 0, 0, 0, 0] },
    { category: 'Ride Hard Costs', costs: [0, 0, 0, 0, 0, 0] },
    { category: 'Ride Labor Costs', costs: [0, 0, 0, 0, 0, 0] },
    { category: 'DLE', costs: [0, 0, 0, 0, 0, 0] },
    {
      category: 'Hard Cost Subtotal',
      type: 'subtotal',
      costs: [0, 0, 0, 0, 0, 0]
    },

    { category: 'Soft Costs', type: 'header' },
    { category: 'Creative', costs: [0, 0, 0, 0, 0, 0] },
    { category: 'D+P', costs: [0, 0, 0, 0, 0, 0] },
    { category: 'PM/ Project Controls', costs: [0, 0, 0, 0, 0, 0] },
    { category: 'CM', costs: [0, 0, 0, 0, 0, 0] },
    { category: 'Gen Requirements', costs: [0, 0, 0, 0, 0, 0] },
    { category: 'Ops Requirements', costs: [0, 0, 0, 0, 0, 0] },
    {
      category: 'Soft Cost Subtotal',
      type: 'subtotal',
      costs: [0, 0, 0, 0, 0, 0]
    },

    { category: 'Indirect Costs', type: 'header' },
    { category: 'HC Escalation', costs: [0, 0, 0, 0, 0, 0] },
    { category: 'Labor Escalation', costs: [0, 0, 0, 0, 0, 0] },
    { category: 'Contingency', costs: [0, 0, 0, 0, 0, 0] },
    { category: 'Warranty', costs: [0, 0, 0, 0, 0, 0] },
    { category: 'Mgt. Reserve', costs: [0, 0, 0, 0, 0, 0] },
    { category: 'Capital Interest', costs: [0, 0, 0, 0, 0, 0] },
    {
      category: 'Indirect Cost Subtotal',
      type: 'subtotal',
      costs: [0, 0, 0, 0, 0, 0]
    },
    { category: '' },
    { category: 'Project Total', type: 'total', costs: [0, 0, 0, 0, 0, 0] }
  ]

  const updatedRows = rows.map(row => {
    row.id = faker.string.uuid()
    if (Array.isArray(row.costs)) {
      return {
        ...row,
        costs: row.costs.map(() => ({
          value: 0,
          updated: false,
          metadata: {},
          id: faker.string.uuid(),
          locked: {
            status: false
          },
          highlight: {
            status: false,
            color: ''
          }
        }))
      }
    }

    return row
  })

  return { headers, rows: updatedRows }
}

function generateDOWSummary () {
  const headers = ['Area 1', 'Area 2', 'Area 3', 'Area 4', 'Area 5', 'Total']
  const rows = [
    {
      name: 'Hard Costs',
      type: 'title'
    },
    {
      num: '2.02',
      name: 'Existing Conditions',
      prior: ['', '', '', '', '', 0],
      current: [0, 0, 0, 0, 0, 0],
      valueChange: 0,
      percentChange: '0.0'
    },
    {
      num: '2.03',
      name: 'Concrete',
      prior: ['', '', '', '', '', 0],
      current: [0, 0, 0, 0, 0, 0],
      valueChange: 0,
      percentChange: '0.0'
    },
    {
      num: '2.04',
      name: 'Masonry',
      prior: ['', '', '', '', '', 0],
      current: [0, 0, 0, 0, 0, 0],
      valueChange: 0,
      percentChange: '0.0'
    },
    {
      num: '2.05',
      name: 'Metals',
      prior: ['', '', '', '', '', 0],
      current: [0, 0, 0, 0, 0, 0],
      valueChange: 0,
      percentChange: '0.0'
    },
    {
      num: '2.06',
      name: 'Woods, Plastics, and Composites',
      prior: ['', '', '', '', '', 0],
      current: [0, 0, 0, 0, 0, 0],
      valueChange: 0,
      percentChange: '0.0'
    },
    {
      num: '2.07',
      name: 'Thermal and Moisture Protection',
      prior: ['', '', '', '', '', 0],
      current: [0, 0, 0, 0, 0, 0],
      valueChange: 0,
      percentChange: '0.0'
    },
    {
      num: '2.08',
      name: 'Openings',
      prior: ['', '', '', '', '', 0],
      current: [0, 0, 0, 0, 0, 0],
      valueChange: 0,
      percentChange: '0.0'
    },
    {
      name: 'Facility - Subtotal',
      type: 'subtotal',
      prior: ['', '', '', '', '', 0],
      current: [0, 0, 0, 0, 0, 0],
      valueChange: 0,
      percentChange: '0.0'
    },
    {
      num: '3.17',
      name: 'Music',
      prior: ['', '', '', '', '', 0],
      current: [0, 0, 0, 0, 0, 0],
      valueChange: 0,
      percentChange: '0.0'
    },
    {
      num: '3.39',
      name: 'Digital Arts',
      prior: ['', '', '', '', '', 0],
      current: [0, 0, 0, 0, 0, 0],
      valueChange: 0,
      percentChange: '0.0'
    }
  ]

  const updatedRows = rows.map(row => {
    row.id = faker.string.uuid()
    if (Array.isArray(row.prior)) {
      return {
        ...row,
        prior: row.prior.map(() => ({
          value: 0,
          updated: false,
          metadata: {},
          id: faker.string.uuid(),
          locked: {
            status: false
          },
          highlight: {
            status: false,
            color: ''
          }
        })),
        current: row.current.map(() => ({
          value: 0,
          updated: false,
          metadata: {},
          id: faker.string.uuid(),
          locked: {
            status: false
          },
          highlight: {
            status: false,
            color: ''
          }
        }))
      }
    }

    return row
  })

  return { headers, rows: updatedRows }
}

function generateScopeSummary () {
  return {
    siteOverallBD: {
      areas: [
        {
          name: 'Area 1',
          surface: 100300,
          updated: false,
          metadata: {},
          unit: 'sqft',
          subAreas: [
            {
              id: faker.string.uuid(),
              name: 'Sub Area 1',
              surface: 14810,
              updated: false,
              metadata: {},
              locked: {
                status: false
              },
              highlight: {
                status: false,
                color: ''
              }
            },
            {
              id: faker.string.uuid(),
              name: 'Sub Area 2',
              surface: 9090,
              updated: false,
              metadata: {},
              locked: {
                status: false
              },
              highlight: {
                status: false,
                color: ''
              }
            },
            {
              id: faker.string.uuid(),
              name: 'Sub Area 3',
              surface: 30600,
              updated: false,
              metadata: {},
              locked: {
                status: false
              },
              highlight: {
                status: false,
                color: ''
              }
            },
            {
              id: faker.string.uuid(),
              name: 'Sub Area 4',
              surface: 45800,
              updated: false,
              metadata: {},
              locked: {
                status: false
              },
              highlight: {
                status: false,
                color: ''
              }
            }
          ]
        },
        {
          name: 'Area 1',
          surface: 100300,
          updated: false,
          metadata: {},
          unit: 'sqft',
          subAreas: [
            {
              id: faker.string.uuid(),
              name: 'Sub Area 1',
              surface: 14810,
              updated: false,
              metadata: {},
              locked: {
                status: false
              },
              highlight: {
                status: false,
                color: ''
              }
            },
            {
              id: faker.string.uuid(),
              name: 'Sub Area 2',
              surface: 9090,
              updated: false,
              metadata: {},
              locked: {
                status: false
              },
              highlight: {
                status: false,
                color: ''
              }
            },
            {
              id: faker.string.uuid(),
              name: 'Sub Area 3',
              surface: 30600,
              updated: false,
              metadata: {},
              locked: {
                status: false
              },
              highlight: {
                status: false,
                color: ''
              }
            },
            {
              id: faker.string.uuid(),
              name: 'Sub Area 4',
              surface: 45800,
              updated: false,
              metadata: {},
              locked: {
                status: false
              },
              highlight: {
                status: false,
                color: ''
              }
            }
          ]
        },
        {
          name: 'Area 1',
          surface: 100300,
          updated: false,
          metadata: {},
          unit: 'sqft',
          subAreas: [
            {
              id: faker.string.uuid(),
              name: 'Sub Area 1',
              surface: 14810,
              updated: false,
              metadata: {},
              locked: {
                status: false
              },
              highlight: {
                status: false,
                color: ''
              }
            },
            {
              id: faker.string.uuid(),
              name: 'Sub Area 2',
              surface: 9090,
              updated: false,
              metadata: {},
              locked: {
                status: false
              },
              highlight: {
                status: false,
                color: ''
              }
            },
            {
              id: faker.string.uuid(),
              name: 'Sub Area 3',
              surface: 30600,
              updated: false,
              metadata: {},
              locked: {
                status: false
              },
              highlight: {
                status: false,
                color: ''
              }
            },
            {
              id: faker.string.uuid(),
              name: 'Sub Area 4',
              surface: 45800,
              updated: false,
              metadata: {},
              locked: {
                status: false
              },
              highlight: {
                status: false,
                color: ''
              }
            }
          ]
        },
        {
          name: 'Area 1',
          surface: 100300,
          updated: false,
          metadata: {},
          unit: 'sqft',
          subAreas: [
            {
              id: faker.string.uuid(),
              name: 'Sub Area 1',
              surface: 14810,
              updated: false,
              metadata: {},
              locked: {
                status: false
              },
              highlight: {
                status: false,
                color: ''
              }
            },
            {
              id: faker.string.uuid(),
              name: 'Sub Area 2',
              surface: 9090,
              updated: false,
              metadata: {},
              locked: {
                status: false
              },
              highlight: {
                status: false,
                color: ''
              }
            },
            {
              id: faker.string.uuid(),
              name: 'Sub Area 3',
              surface: 30600,
              updated: false,
              metadata: {},
              locked: {
                status: false
              },
              highlight: {
                status: false,
                color: ''
              }
            },
            {
              id: faker.string.uuid(),
              name: 'Sub Area 4',
              surface: 45800,
              updated: false,
              metadata: {},
              locked: {
                status: false
              },
              highlight: {
                status: false,
                color: ''
              }
            }
          ]
        }
      ],
      siteOverallSurface: 184445,
      updated: false,
      metadata: {},
      unit: 'sqft'
    },
    scopeMetrics: [
      {
        id: faker.string.uuid(),
        name: 'Unique Show Set Elements Count',
        value: 122,
        updated: false,
        metadata: {},
        unit: 'each',
        locked: {
          status: false
        },
        highlight: {
          status: false,
          color: ''
        }
      },
      {
        id: faker.string.uuid(),
        name: 'Animated Figures/Props Count',
        value: 4,
        updated: false,
        metadata: {},
        unit: 'each',
        locked: {
          status: false
        },
        highlight: {
          status: false,
          color: ''
        }
      },
      {
        id: faker.string.uuid(),
        name: 'projector Count',
        value: 78,
        updated: false,
        metadata: {},
        unit: 'each',
        locked: {
          status: false
        },
        highlight: {
          status: false,
          color: ''
        }
      },
      {
        id: faker.string.uuid(),
        name: 'Ride Vehicles Count',
        value: 21,
        updated: false,
        metadata: {},
        unit: 'each',
        locked: {
          status: false
        },
        highlight: {
          status: false,
          color: ''
        }
      },
      {
        id: faker.string.uuid(),
        name: 'Tracl Length',
        value: 1240,
        updated: false,
        metadata: {},
        unit: 'lnft',
        locked: {
          status: false
        },
        highlight: {
          status: false,
          color: ''
        }
      },
      {
        id: faker.string.uuid(),
        name: 'Total conditioned space',
        value: 93065,
        updated: false,
        metadata: {},
        unit: 'sqft',
        locked: {
          status: false
        },
        highlight: {
          status: false,
          color: ''
        }
      }
    ],
    costPerSF: [
      {
        id: faker.string.uuid(),
        name: 'Facility Direct Cost per Facility Sqft',
        value: 1547,
        updated: false,
        metadata: {},
        unit: '$/sf',
        locked: {
          status: false
        },
        highlight: {
          status: false,
          color: ''
        }
      },
      {
        id: faker.string.uuid(),
        name: 'Show Direct Cost per',
        value: 0,
        updated: false,
        metadata: {},
        unit: '$/sf',
        locked: {
          status: false
        },
        highlight: {
          status: false,
          color: ''
        }
      },
      {
        id: faker.string.uuid(),
        name: 'Ride Direct Cost per Track Length',
        value: 0,
        updated: false,
        metadata: {},
        unit: '$/lf',
        locked: {
          status: false
        },
        highlight: {
          status: false,
          color: ''
        }
      }
    ],
    ace: [
      {
        id: 'Sc 00',
        name: 'Exterior Queue',
        surface: 10000,
        updated: false,
        metadata: {},
        unit: 'sqft',
        percentageOfTotal: 0.2,
        directCosts: 0,
        allocated: null,
        subtotal: null,
        costPerSF: null,
        locked: {
          status: false
        },
        highlight: {
          status: false,
          color: ''
        }
      },
      {
        id: 'Sc 01',
        name: 'Pre-Show',
        surface: 10000,
        updated: false,
        metadata: {},
        unit: 'sqft',
        percentageOfTotal: 0.2,
        directCosts: 0,
        allocated: null,
        subtotal: null,
        costPerSF: null,
        locked: {
          status: false
        },
        highlight: {
          status: false,
          color: ''
        }
      },
      {
        id: 'Sc 02',
        name: 'Ride Building',
        surface: 10000,
        updated: false,
        metadata: {},
        unit: 'sqft',
        percentageOfTotal: 0.2,
        directCosts: 0,
        allocated: null,
        subtotal: null,
        costPerSF: null,
        locked: {
          status: false
        },
        highlight: {
          status: false,
          color: ''
        }
      },
      {
        id: 'Sc 03',
        name: 'Ride Track',
        surface: 10000,
        updated: false,
        metadata: {},
        unit: 'sqft',
        percentageOfTotal: 0.2,
        directCosts: 0,
        allocated: null,
        subtotal: null,
        costPerSF: null,
        locked: {
          status: false
        },
        highlight: {
          status: false,
          color: ''
        }
      },
      {
        id: 'Sc 04',
        name: 'Ride Support',
        surface: 10000,
        updated: false,
        metadata: {},
        unit: 'sqft',
        percentageOfTotal: 0.2,
        directCosts: 0,
        allocated: null,
        subtotal: null,
        costPerSF: null,
        locked: {
          status: false
        },
        highlight: {
          status: false,
          color: ''
        }
      }
    ]
  }
}

export function generateSidebarSections () {
  const allowedColors = ['gold', 'seafoam', 'pale_grape', 'mint', 'azure']

  const selectionTags = ['Residential', 'Commercial', 'Industrial']

  function getRandomColor () {
    return allowedColors[Math.floor(Math.random() * allowedColors.length)]
  }

  const sections = [
    {
      name: 'units',
      items: Array.from({ length: 4 }, () => ({
        name: faker.commerce.productName(),
        value: faker.number.int({ min: 100, max: 1000 }).toString(),
        color: null
      }))
    },
    {
      name: 'Selection tags',
      items: Array.from({ length: 3 }, () => ({
        name: selectionTags[Math.floor(Math.random() * selectionTags.length)],
        value: `${faker.number.int({ min: 100, max: 1000 })}²`,
        color: getRandomColor()
      }))
    },
    {
      name: 'units',
      items: Array.from({ length: 3 }, () => ({
        name: faker.commerce.department(),
        value: `${faker.number.int({ min: 100, max: 1000 })}²`,
        color: getRandomColor()
      }))
    }
  ]

  return sections
}

function generateArchtypes () {
  const costTypes = [
    'cost_type_1',
    'cost_type_2',
    'cost_type_3',
    'cost_type_4',
    'cost_type_5',
    'cost_type_6'
  ]

  const archtypes = Array.from({ length: 5 }, () => ({
    label: faker.word.words({ count: { min: 1, max: 2 } }),
    type: costTypes[Math.floor(Math.random() * costTypes.length)]
  }))

  return archtypes
}

function fullEstimateHelper () {
  const data = []
  const types = ['vendorAndSubCont', 'invoiceAndPurchases']
  for (let i = 1; i <= 10; i++) {
    const budget = faker.finance.amount({
      min: 50,
      max: 1000,
      dec: 2
    })
    const actualCosts = faker.finance.amount({
      min: 50,
      max: 1000,
      dec: 2
    })
    const underOver = (actualCosts - budget).toFixed(2)
    const deltaPercent = ((underOver / budget) * 100).toFixed(2)

    data.push({
      id: faker.string.uuid(),
      costCode: `CC${i}`,
      activity: `Activity ${i}`,
      laborCosts: {
        id: faker.string.uuid(),
        value: parseFloat(
          faker.finance.amount({
            min: 50,
            max: 1000,
            dec: 2
          })
        ),
        updated: false,
        metadata: {},
        locked: {
          status: false
        },
        highlight: {
          status: false,
          color: ''
        }
      },
      materialCosts: {
        id: faker.string.uuid(),
        value: parseFloat(
          faker.finance.amount({
            min: 50,
            max: 1000,
            dec: 2
          })
        ),
        updated: false,
        metadata: {},
        locked: {
          status: false
        },
        highlight: {
          status: false,
          color: ''
        }
      },
      fixedCosts: {
        id: faker.string.uuid(),
        value: parseFloat(
          faker.finance.amount({
            min: 50,
            max: 1000,
            dec: 2
          })
        ),
        updated: false,
        metadata: {},
        locked: {
          status: false
        },
        highlight: {
          status: false,
          color: ''
        }
      },
      budget: {
        id: faker.string.uuid(),
        value: parseFloat(budget),
        updated: false,
        metadata: {},
        locked: {
          status: false
        },
        highlight: {
          status: false,
          color: ''
        }
      },
      actualCosts: {
        id: faker.string.uuid(),
        value: parseFloat(actualCosts),
        updated: false,
        metadata: {},
        locked: {
          status: false
        },
        highlight: {
          status: false,
          color: ''
        }
      },
      underOver: {
        id: faker.string.uuid(),
        value: parseFloat(underOver),
        updated: false,
        metadata: {},
        locked: {
          status: false
        },
        highlight: {
          status: false,
          color: ''
        }
      },
      deltaPercent: {
        id: faker.string.uuid(),
        value: parseFloat(deltaPercent),
        updated: false,
        metadata: {},
        locked: {
          status: false
        },
        highlight: {
          status: false,
          color: ''
        }
      },
      type: types[Math.floor(Math.random() * types.length)],
      administrativeExp: faker.datatype.boolean({ probability: 0.2 })
    })
  }

  return data
}

export function logsMock (length) {
  return Array.from({ length }, () => {
    return {
      model: faker.helpers.arrayElement(['Butler', 'Turbo', 'Gemini']),
      response: faker.lorem.sentence({ min: 5, max: 15 })
    }
  })
}
