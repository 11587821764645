import React from 'react'
import { Box, CircularProgress, Grow, Typography } from '@mui/material'
import styles from './PreviousProjects.module.css'
import ProjectStatus from '../../ProjectStatus/ProjectStatus'
import { useNavigate } from 'react-router-dom'

const getFormattedDateAndTimestamp = (lastSave) => {
  const utcDate = new Date(lastSave)

  const day = String(utcDate.getUTCDate()).padStart(2, '0')
  const month = String(utcDate.getUTCMonth() + 1).padStart(2, '0')
  const year = utcDate.getUTCFullYear()

  const hours = String(utcDate.getUTCHours()).padStart(2, '0')
  const minutes = String(utcDate.getUTCMinutes()).padStart(2, '0')
  const seconds = String(utcDate.getUTCSeconds()).padStart(2, '0')

  return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`
}

export default function PreviousProjects ({
  open,
  setOpen,
  projects,
  loading,
  error
}) {
  const navigate = useNavigate()
  return (
    <Grow
      in={open}
      style={{ transformOrigin: '0 0 0' }}
      {...(open ? { timeout: 500 } : {})}
    >
      <Box className={styles.recentProjects}>
        {loading && (
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            <CircularProgress />
          </Box>
        )}
        {error && (
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            <Typography
              variant='h6'
              color='error'
              align='center'
            >
              {error}
            </Typography>
          </Box>
        )}
        {projects.map((row) => (
          <Box
            component='div'
            className={styles.dataRow}
            key={row?._id}
            onClick={() => {
              navigate(`/map/${row._id}`)
            }}
          >
            <Box sx={{ flex: '0 0 250px' }} className={styles.cell}>
              <p>{row?.project_name}</p>
              <p style={{ fontSize: '8px' }}>{getFormattedDateAndTimestamp(row?.updated_at)}</p>
            </Box>
            <p className={styles.cell}>{row?.category}</p>
            <p className={styles.cell}>{row?.confidence_level}</p>
            <Box sx={{ width: '125px' }}>
              <ProjectStatus stage={row?.stage} />
            </Box>
          </Box>
        ))}
      </Box>
    </Grow>
  )
}
