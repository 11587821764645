export const RECENT_PROJECTS = [
  {
    _id: 1,
    project_name: 'Project 1: The Project of All Projects',
    category: 'Category 1',
    confidence_level: 'High',
    stage: 'production'
  },
  {
    _id: 2,
    project_name: 'Project 2',
    category: 'Category 2',
    confidence_level: 'High',
    stage: 'stage3'
  },
  {
    _id: 3,
    project_name: 'Project 3',
    category: 'Category 3',
    confidence_level: 'Med',
    stage: 'stage2'
  },
  {
    _id: 4,
    project_name: 'Project 4',
    category: 'Category 4',
    confidence_level: 'Low',
    stage: 'stage1'
  },
  {
    _id: 6,
    project_name: 'Project 6',
    category: 'Category 6',
    confidence_level: 'Low',
    stage: 'inactive'
  },
  {
    _id: 7,
    project_name: 'Project 7',
    category: 'Category 7',
    confidence_level: 'Low',
    stage: 'inactive'
  },
  {
    _id: 8,
    project_name: 'Project 8',
    category: 'Category 8',
    confidence_level: 'Low',
    stage: 'inactive'
  },
  {
    _id: 9,
    project_name: 'Project 9',
    category: 'Category 9',
    confidence_level: 'Low',
    stage: 'inactive'
  }
]

export const DECISION_TREE = [
  { id: 1, name: 'Project Start' },
  { id: 2, name: 'Family Friendly Ages 6-12' },
  { id: 3, name: 'Interactive Underwater Drones' },
  { id: 4, name: '$3MM Increase Tech Budget' },
  { id: 5, name: 'Trackless Vehicles' },
  { id: 6, name: 'Mission-Based Storyline' },
  { id: 7, name: 'Surround Sound & 360deg Projections' },
  { id: 8, name: 'Educational Queue Experience' }
]
