export function convertUnixTimestampToDate (timestamp) {
  const milliseconds = Math.floor(timestamp.nanoseconds / 1e6)
  const date = new Date((timestamp.seconds * 1000) + milliseconds)
  return date
}
export function formatLastSignInTime (lastSignInTime) {
  const options = {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  }
  return new Date(lastSignInTime).toLocaleString('en-US', options)
}
