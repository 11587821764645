import React, { useState } from 'react'
import {
  TableCell,
  Typography,
  TextField,
  Tooltip,
  Box,
  Button,
  IconButton,
  Modal,
  Select,
  MenuItem
} from '@mui/material'
import { styled } from '@mui/system'
import { useAuth } from '../../Context/AuthContext'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import SaveIcon from '@mui/icons-material/Save'
import {
  CloseOutlined,
  DoneOutline,
  PaletteOutlined
} from '@mui/icons-material'
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import styles from './CustomCell.module.css'

const colorsArray = [
  { value: '#D3D3D3', label: 'Grey' },
  { value: '#DCDCDC', label: 'Light Grey' },
  { value: '#FFA500', label: 'Orange' },
  { value: '#FFDAB9', label: 'Light Orange' },
  { value: '#FF0000', label: 'Red' },
  { value: '#FFB6C1', label: 'Light Red' },
  { value: '#008000', label: 'Green' },
  { value: '#90EE90', label: 'Light Green' },
  { value: '#0000FF', label: 'Blue' },
  { value: '#ADD8E6', label: 'Light Blue' },
  { value: '#800080', label: 'Purple' },
  { value: '#E6E6FA', label: 'Light Purple' }
]

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    opacity: 0,
    transition: 'opacity 0.3s ease',
    pointerEvents: 'none'
  },
  '&:hover::before': {
    opacity: 1
  }
}))

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
  borderRadius: '8px'
}

export default function CustomCell ({
  header = false,
  align = 'left',
  content,
  bold = false,
  adminstrativeRep = false,
  colSpan,
  prefix = '',
  suffix = '',
  mode = null,
  theme = {
    headerBg: '#4A62D8',
    highlightBg: '#ADB5D9'
  },
  locked = {},
  handleLockClick = () => {},
  highlight = {},
  handleHighlightClick = () => {},
  handleUpdate = () => {},
  metadata = {},
  updated = false
}) {
  const [editableContent, setEditableContent] = useState(content)
  const [notes, setNotes] = useState('')
  const [editNotesMode, setEditNotesMode] = useState(false)
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [highlightModalOpen, setHighlightModalOpen] = useState(false)
  const [highlightColor, setHighlightColor] = useState('#D3D3D3')
  const [contentUpdated, setContentUpdated] = useState(false)
  const { currentUser } = useAuth()

  const handleChange = (event) => {
    setContentUpdated(true)
    setEditableContent(event.target.value)
    const overrideMetadata = ({
      ...metadata,
      user: currentUser,
      timestamp: new Date().toLocaleString()
    })
    handleUpdate(parseFloat(event.target.value), overrideMetadata)
  }

  const handleAddNote = () => {
    setEditNotesMode(true)
    setModalOpen(true)
  }

  const handleSaveNote = () => {
    handleUpdate(parseFloat(editableContent), { ...metadata, notes })
    setEditNotesMode(false)
    setModalOpen(false)
  }

  const handleDeleteNote = () => {
    setNotes('')
    handleUpdate(parseFloat(editableContent), { ...metadata, notes: '' })
  }

  const handleEditNote = () => {
    setEditNotesMode(true)
    setNotes(metadata.notes)
    setModalOpen(true)
  }

  const handleModalClose = () => {
    setModalOpen(false)
  }

  const handleTooltipClose = () => {
    setTooltipOpen(false)
  }

  const handleTooltipOpen = () => {
    setTooltipOpen(true)
  }

  const handleLock = () => {
    return handleLockClick()
  }

  const handleHighlight = () => {
    if (highlight.status) {
      handleHighlightClick('')
      return
    }
    setHighlightModalOpen(true)
  }

  const handleHighlightModalClose = () => {
    setHighlightModalOpen(false)
  }

  const saveHighlightColor = () => {
    handleHighlightClick(highlightColor)
    setHighlightModalOpen(false)
    setEditNotesMode(true)
    setModalOpen(true)
  }

  const showTooltip = (contentUpdated || highlight.status) &&
    content !== null
  return (
    <>
      <Tooltip
        onClose={handleTooltipClose}
        open={tooltipOpen}
        disableFocusListener
        disableTouchListener
        title={(showTooltip && !modalOpen && !highlightModalOpen) && (
          <Box p={3}>
            <Box
              position='absolute'
              right={1}
              top={1}
            >
              <IconButton onClick={handleTooltipClose}>
                <CloseOutlined />
              </IconButton>
            </Box>
            {contentUpdated && (
              <>
                <Typography variant='body2' gutterBottom>
                  Edited By: {`${metadata.user?.givenName} 
                          ${metadata.user?.lastName || ''}`}
                </Typography>
                <Typography variant='body2' gutterBottom>
                  At: {metadata.timestamp}
                </Typography>
              </>
            )}
            {metadata.notes && (
              <Box>
                <Typography
                  variant='body2'
                  gutterBottom
                  sx={{
                    whiteSpace: 'pre-wrap'
                  }}
                >
                  Note: {metadata.notes}
                </Typography>
                {!editNotesMode && ['edit', 'highlight'].includes(mode) && (
                  <Box>
                    <IconButton
                      size='small'
                      onClick={handleEditNote}
                    >
                      <EditIcon fontSize='inherit' />
                    </IconButton>
                    <IconButton
                      size='small'
                      onClick={handleDeleteNote}
                    >
                      <DeleteIcon fontSize='inherit' />
                    </IconButton>
                  </Box>
                )}
              </Box>
            )}
            {!metadata.notes && !editNotesMode && ['highlight', 'edit']
              .includes(mode) && (
                <Button
                  size='small'
                  variant='containedSecondaryRounded'
                  onClick={handleAddNote}
                  sx={{
                    borderRadius: '24px'
                  }}
                >
                  Add Note
                </Button>
            )}
          </Box>
        )}
        placement='top'
        arrow
      >
        <StyledTableCell
          sx={{
            backgroundColor: highlight.status
              ? highlight.color
              : locked.status
                ? '#aaa'
                : header
                  ? theme.headerBg
                  : adminstrativeRep
                    ? theme.highlightBg
                    : 'white',
            color: locked.status
              ? '#333'
              : header ? 'white' : 'black',
            border: updated
              ? '.1px solid #4A62D8 !important'
              : '.1px solid #DCE2E5',
            borderWidth: '.5px 0 0 .5px ',
            py: '4px',
            px: '10px',
            lineHeight: '0.5'
          }}
          align={align}
          colSpan={colSpan}
          onClick={handleTooltipOpen}
          className={styles.mainContainer}
        >
          {header
            ? (
              <Typography
                fontSize={bold && header ? '14px' : '12px'}
                lineHeight={1}
                fontWeight={bold ? 600 : 400}
              >
                {content ?? ''}
              </Typography>
              )
            : locked.status
              ? (
                <Box
                  onClick={handleLock}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'default'
                  }}
                >
                  <Typography
                    fontSize='12px'
                    lineHeight={1}
                    fontWeight={bold ? 600 : 400}
                    className={styles.content}
                  >
                    {`${prefix}${editableContent ?? ''}${suffix}`}
                  </Typography>
                  <LockOutlinedIcon sx={{ fontSize: '20px' }} />
                </Box>)
              : mode === 'lock'
                ? (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'default'
                    }}
                    onClick={handleLock}
                  >
                    <Typography
                      fontSize='12px'
                      lineHeight={1}
                      fontWeight={bold ? 600 : 400}
                      className={styles.content}
                    >
                      {`${prefix}${editableContent ?? ''}${suffix}`}
                    </Typography>
                    <Box
                      className={styles.lockView}
                    >
                      <LockOpenOutlinedIcon sx={{ fontSize: '20px' }} />
                    </Box>
                  </Box>
                  )
                : mode === 'highlight'
                  ? (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'default'
                      }}
                      onClick={handleHighlight}
                    >
                      <Typography
                        fontSize='12px'
                        lineHeight={1}
                        fontWeight={bold ? 600 : 400}
                        className={styles.content}
                      >
                        {`${prefix}${editableContent ?? ''}${suffix}`}
                      </Typography>
                      <Box
                        className={styles.lockView}
                      >
                        {highlight.status && (<DoneOutline />)}
                        {!highlight.status && (<PaletteOutlined />)}
                      </Box>
                    </Box>
                    )
                  : mode === 'edit'
                    ? (
                      <TextField
                        value={mode === 'edit'
                          ? editableContent
                          : prefix + editableContent + suffix}
                        onChange={handleChange}
                        type='number'
                        variant='standard'
                        disabled={mode !== 'edit'}
                        fullWidth
                        InputProps={{
                          disableUnderline: true,
                          style: {
                            fontSize: '12px',
                            fontWeight: bold ? 600 : 400
                          },
                          inputProps: {
                            style: {
                              padding: 0
                            }
                          }
                        }}
                        sx={{
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: '#000000'
                          },
                          '& .MuiInputBase-root': {
                            width: 'auto'
                          }
                        }}
                      />
                      )
                    : (
                      <Typography
                        fontSize='12px'
                        lineHeight={1}
                        fontWeight={bold ? 600 : 400}
                      >
                        {`${prefix}${editableContent ?? ''}${suffix}`}
                      </Typography>
                      )}
        </StyledTableCell>
      </Tooltip>

      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={modalStyle}>
          <Typography variant='h6' component='h2'>
            {metadata?.notes ? 'Edit Note' : 'Add Note'}
          </Typography>
          <textarea
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            style={{
              width: '100%'
            }}
            rows={4}
            maxLength={200}
          />
          <Button
            variant='containedSecondaryRounded'
            color='primary'
            startIcon={<SaveIcon />}
            onClick={handleSaveNote}
          >
            Save
          </Button>
        </Box>
      </Modal>
      <Modal
        open={highlightModalOpen}
        onClose={handleHighlightModalClose}
      >
        <Box sx={modalStyle}>
          <Typography variant='h6' component='h2'>
            Highlight Color
          </Typography>
          <Typography variant='body2' gutterBottom>
            Select a color to highlight the cell
          </Typography>
          <Select
            variant='outlined'
            size='small'
            value={highlightColor}
            onChange={(e) => setHighlightColor(e.target.value)}
            fullWidth
          >
            {colorsArray.map((color, index) => (
              <MenuItem key={index} value={color.value}>
                {color.label}
              </MenuItem>
            ))}
          </Select>
          <Button
            variant='containedSecondaryRounded'
            color='primary'
            startIcon={<SaveIcon />}
            onClick={saveHighlightColor}
          >
            Save
          </Button>
        </Box>
      </Modal>
    </>
  )
}
