import React from 'react'
import { Box } from '@mui/material'
import styles from './DragDrop.module.css'
import NavBar from '../NavBar/NavBar'
import Footer from '../Footer/Footer'

export default function DragDrop ({ setDragDropView }) {
  return (
    <Box
      className={styles.dragDropContainer}
    >
      <NavBar dragDrop />
      <Box
        className={styles.dragDropBody}
        onMouseLeave={() => setDragDropView(false)}
      >
        <Box className={styles.headerContainer}>
          <p className={styles.headerText}>Tell more of your story</p>
          <p className={styles.bodyText}>
            Drag and drop files and folders here to upload
          </p>
        </Box>
        <p className={styles.descriptionText}>Upload floor plans, 3D models,
          mood boards, budgets or anything in any file to help build a
          framework for your project
        </p>
      </Box>
      <Box
        className={styles.exitHover}
        onMouseEnter={() => setDragDropView(false)}
      >
        <p>Hover here to exit</p>
      </Box>
      <Footer dragDrop />
    </Box>
  )
}
